export const USER_ROLES = {
  ADMIN: 'Admin',
  PROVIDER: 'Provider',
  SUPER_ADMIN: 'Super Admin',
};

export const USER_ROLES_OPTIONS = [
  {
    text: 'Admin',
    value: 'Admin',
  },
  { text: 'Provider', value: 'Provider' },
];
