export const convertToTitleCase = (phrase: string) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const convertCamelCaseToTitleCase = (camelText: string) => {
  if (camelText.toLowerCase() === 'displaylocation') {
    return 'Location';
  }
  if (camelText === 'last4SSN') {
    return 'Last4SSN';
  }
  let seperated = camelText.replace(/([A-Z])/g, ' $1');
  let titleCased = seperated.charAt(0).toUpperCase() + seperated.slice(1);
  return titleCased;
};

/**
 * Convert the string that comes after '-' to title case and before '-' to lower case
 * If the str doesn't contain '-', convert the entire string to title case
 *
 * @example
 * Input -> E-PRESCRIBED : Output -> e-Prescribed
 * Input -> E-PRESCRIPTION-CREATED -> e-Prescribion-created
 *
 * @param {string} str
 * @returns {string}
 */
export const convertToTitleCaseAfterFirstDash = (str: string) => {
  if (!str) return str;
  if (!str.includes('-')) return convertToTitleCase(str);
  const [beforeDash, ...afterDash] = str.split('-');
  const restOfTheString = afterDash.join('-');
  return `${beforeDash.toLowerCase()}-${convertToTitleCase(restOfTheString)}`;
};

/**
 * Convert the string to title case after replacing `_` with ` `
 *
 * @example
 * Input -> ready_to_fill : Output -> Ready to fill
 *
 * @param {string} str
 * @returns {string}
 */
export const converToTitleCaseAfterUnderscoreRemoval = (str: string) => {
  if (!str) return str;
  let localStr = str.replaceAll('_', ' ');
  return convertToTitleCase(localStr);
};
