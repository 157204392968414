import { AddressType } from 'enums/common';
import { PaymentStatus } from 'enums/payment';
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBTooltip,
} from 'mdb-react-ui-kit';
import { RoleType } from 'types/roles';
import { TransactionType } from 'types/transaction';
import { getFullName } from 'utils/common';
import { formatDate, formatDateTime } from 'utils/date';
import {
  formatCurrency,
  formatPhone,
  getAddressDisplayName,
  hasActionAccess,
} from 'utils/npssUtil';
import './transactionListItem.scss';

interface Props {
  data: TransactionType;
  onRefundTransaction: (transactionId: string) => void;
  currentUserRole: RoleType;
}

const TransactionListItem = ({
  data,
  onRefundTransaction,
  currentUserRole,
}: Props) => {
  const { transactionDetail, patient } = data;

  const drugName = transactionDetail?.drugOffering?.drug?.brandName;
  const patientName = getFullName(
    patient?.firstName,
    patient?.lastName,
    patient?.middleName
  );
  const address = patient?.addresses?.billingAddress?.find(
    (item) => item.addressType === AddressType.BILLING
  );

  const isRefundable = data.status === PaymentStatus.SUCCEEDED;
  const canViewReceipt =
    data.status === PaymentStatus.SUCCEEDED ||
    data.status === PaymentStatus.REFUNDED;

  return (
    <>
      <tr className="font-weight-normal" key={data.id}>
        <td className="transaction-table-small-column">
          {formatDate(data.createdAt)}
          <br />
          {formatDateTime(data.createdAt).split(' ')[1] +
            formatDateTime(data.createdAt).split(' ')[2]}
        </td>
        <td className="transaction-table-medium-column">
          {patientName}
          <br />
          <small className="text-muted d-flex">
            <span className="d-block mr-1">
              {patient?.dob ? (
                <>
                  <i className="text-primary fas fa-birthday-cake opacity-50 mr-2"></i>
                  &nbsp;
                  {formatDate(patient.dob)} &nbsp;
                </>
              ) : (
                ''
              )}
            </span>
          </small>
        </td>
        <td className="email">
          <ul className="fa-ul ml-4 mb-0">
            <li>
              <span className="fa-li">
                <i className="text-primary fas fa-phone-alt opacity-50"></i>
              </span>
              {formatPhone(patient?.phone)}
            </li>
            <li>
              <span className="fa-li">
                <i className="text-primary fas fa-envelope opacity-50"></i>
              </span>
              <span className="table-email-column">
                {patient?.email && (
                  <MDBTooltip
                    tag="a"
                    wrapperProps={{ href: '#' }}
                    title={patient.email}
                  >
                    <span className="text-dark">{patient?.email}</span>
                  </MDBTooltip>
                )}
              </span>
            </li>
          </ul>
        </td>
        <td>
          {' '}
          <ul className="fa-ul ml-4 mb-0">
            <li>
              <span className="fa-li">
                <i className="text-primary fas fa-map-marker-alt opacity-50"></i>
              </span>
              {address ? getAddressDisplayName(address) : '-'}
            </li>
          </ul>
        </td>
        <td>
          {drugName}
          <br />
          <span className="text-xs text-muted">{data?.paymentDescriptor}</span>
          <br />
          {formatCurrency(data.totalAmount)} (
          <span className="text-xs text-muted">{data?.paymentMode}</span>)
        </td>
        <td className="transaction-table-small-column">
          {data.status}
          {/* {data?.note && (
            <MDBTooltip
              tag="a"
              wrapperProps={{ href: '#' }}
              title={data.note}
            >
              <MDBIcon
                far
                icon="sticky-note"
                className="text-muted text-sm ml-1"
              />
            </MDBTooltip>
          )} */}
          <br />
          {data.reason && (
            <span className="text-xs text-muted">{data.reason}</span>
          )}
        </td>

        <td>
          {canViewReceipt && (
            <MDBDropdown>
              <MDBDropdownToggle
                color="link"
                size="sm"
                className="m-0 mdb-dropdown-without-arrow rounded-circle"
              >
                <MDBIcon icon="ellipsis-v text-dark" />
              </MDBDropdownToggle>
              <MDBDropdownMenu right basic className="shadow-4 rounded-2">
                {canViewReceipt && (
                  <MDBDropdownItem>
                    <MDBDropdownLink
                      className="cursor-pointer"
                      href={`/receipt/${data.id}`}
                      target="_blank"
                    >
                      <MDBIcon far icon="eye fa-fw text-muted mr-1" /> View
                      Receipt
                    </MDBDropdownLink>
                  </MDBDropdownItem>
                )}
                {hasActionAccess(currentUserRole) && isRefundable && (
                  <MDBDropdownItem>
                    <MDBDropdownLink
                      className="cursor-pointer"
                      onClick={() => onRefundTransaction(data.id)}
                    >
                      <MDBIcon fas icon="undo-alt fa-fw text-muted mr-1" />{' '}
                      Refund
                    </MDBDropdownLink>
                  </MDBDropdownItem>
                )}
              </MDBDropdownMenu>
            </MDBDropdown>
          )}
        </td>
      </tr>
    </>
  );
};

export default TransactionListItem;
