import config from 'config';
import { PatientStatusType } from 'types/patientStatus';
import http from 'utils/http';

export async function fetchPatients(queryParams: any) {
  const patients = await http({
    url: config.endpoints.patients.getAll,
    method: 'GET',
    params: queryParams,
  });
  return patients.data;
}

export async function fetchPatientDetail(patientId: string | number) {
  const patients = await http({
    url: config.endpoints.patients.get.replace(':id', patientId.toString()),
    method: 'GET',
  });
  return patients.data;
}

export async function changePatientStatus(
  patientId: string,
  newStatus: PatientStatusType
) {
  const patients = await http({
    url: config.endpoints.patients.changeStatus.replace(
      ':id',
      patientId.toString()
    ),
    method: 'PATCH',
    data: newStatus,
  });
  return patients.data;
}
