import PatientInfo from 'components/patient-survey/PatientInfo';
import { uiRoutes } from 'constants/uiRoutes';
import { Tabs } from 'enums/common';
import {
  MDBCard,
  MDBCardBody,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
} from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { fetchPatientDetail } from 'services/patient';
import { useAppDispatch } from 'stores/hooks';
import { setPatientDetail } from 'stores/patient/patient-detail';
import { PatientDetailType } from 'types/patient';
import './patientRoutableTab.scss';

const PatientRoutableTab = ({ children }: { children: any }) => {
  const params: {
    patientId: string;
    userId: string;
    tab: string;
  } = useParams();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const pathname = history?.location?.pathname;
  const lastIndex = pathname?.lastIndexOf('/');
  const activeTab = pathname?.slice(lastIndex + 1);

  const [basicActive, setBasicActive] = useState(params.tab);
  const [detail, setDetail] = useState<PatientDetailType>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getPatientDetail = async () => {
      setIsLoading(true);
      try {
        const response: any = await fetchPatientDetail(
          params.patientId?.toString()
        );
        dispatch(setPatientDetail(response?.data));
        setDetail(response?.data);
        setBasicActive(activeTab);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    };
    getPatientDetail();
  }, [params?.patientId]);

  const onTabItemPress = (newTab: 'SURVEY' | 'MESSAGE' | 'DOCUMENT') => {
    const isFromOrdersMenu = pathname.includes('/orders/patient-detail');

    if (newTab === 'MESSAGE') {
      let route = isFromOrdersMenu
        ? uiRoutes.orders.patientDetailTabs.message
        : uiRoutes.patient.tab.message;
      route = route
        .replace(':patientId', params.patientId)
        .replace(':userId', detail?.userId ?? params.userId);
      history.push(route);
    } else if (newTab === 'DOCUMENT') {
      let route = isFromOrdersMenu
        ? uiRoutes.orders.patientDetailTabs.document
        : uiRoutes.patient.tab.document;
      route = route
        .replace(':patientId', params.patientId)
        .replace(':userId', detail?.userId ?? params.userId);
      history.push(route);
    } else {
      let route = isFromOrdersMenu
        ? uiRoutes.orders.patientDetailTabs.survey
        : uiRoutes.patient.tab.survey;
      route = route.replace(':patientId', params.patientId);
      history.push(route);
    }
  };

  return (
    <>
      <PatientInfo detail={detail} isLoading={isLoading} />
      <MDBCard
        shadow="1"
        background="white"
        className="rounded-1"
        alignment="start"
      >
        <MDBCardBody
          className="patient-message-container"
          style={{
            height: 50,
            padding: '0px 10px 0px 10px',
            margin: 0,
            overflow: 'auto',
            overflowY: 'hidden',
          }}
        >
          <MDBTabs
            className="mb-3"
            style={{
              display: 'flex',
              flexDirection: 'row',
              minWidth: 500,
            }}
          >
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => onTabItemPress('SURVEY')}
                active={basicActive === Tabs.SURVEY}
                style={{ textTransform: 'capitalize', fontSize: 16 }}
              >
                Survey Questionaire
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => onTabItemPress('MESSAGE')}
                active={basicActive === Tabs.MESSAGES}
                style={{ textTransform: 'capitalize', fontSize: 16 }}
              >
                Messages
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => onTabItemPress('DOCUMENT')}
                active={basicActive === Tabs.DOCUMENTS}
                style={{ textTransform: 'capitalize', fontSize: 16 }}
              >
                Documents
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </MDBCardBody>
      </MDBCard>
      <div className="mt-2" />
      <MDBCard shadow="1" background="white" className="rounded-1">
        <MDBCardBody className="w-100">
          <MDBTabsContent>{children}</MDBTabsContent>
        </MDBCardBody>
      </MDBCard>
    </>
  );
};

export default PatientRoutableTab;
