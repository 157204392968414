import init from 'init';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StateProvider } from 'store';
import ConfirmationModalProvider from 'stores/ConfirmationModal';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';

init();

ReactDOM.render(
  <React.StrictMode>
    <StateProvider>
      <ConfirmationModalProvider>
        <App />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          transition={Slide}
        />
      </ConfirmationModalProvider>
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
