interface IProps {
  containerHeight?: string;
  size?: string;
}

const LoadingIndicator = ({ containerHeight, size }: IProps) => (
  <div className="spinner-border align-self-center m-4" role="status"></div>
);

LoadingIndicator.defaultProps = {
  containerHeight: '20rem',
  size: 'xl',
};

export default LoadingIndicator;
