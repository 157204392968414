import defaultAvatar from 'assets/images/jpg/default-avatar.jpg';
import LoadingIndicator from 'components/common/LoadingIndicator';
import { uiRoutes } from 'constants/uiRoutes';
import { MDBCard, MDBCardBody, MDBDropdownItem } from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getNotification } from 'services/notification';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import {
  selectAllNotifications,
  selectAllNotificationsCount,
  selectInitialNotifications,
  selectNotifications,
  setNotification,
} from 'stores/patient/notification';
import { INotification } from 'types/notification';
import { parseHtml } from 'utils/parseHtml';
import './notificationDropdown.scss';
import './notificationMessage.scss';
import NotificationMessageItem from './NotificationMessageItem';

let offset = 0;
const limit = 10;

const NotificationContainer = (props: any) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const notification = useAppSelector(selectNotifications);
  const allNotification = useAppSelector(selectAllNotifications);
  const initialNotification = useAppSelector(selectInitialNotifications);
  const notificationCount = useAppSelector(selectAllNotificationsCount);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [isLoading, setisLoading] = useState<boolean>(false);

  const notifications = props?.notificatioPopup
    ? initialNotification
    : notification;

  useEffect(() => {
    if (!showLoadMore) {
      setShowLoadMore(notificationCount > limit);
    }
  }, [notificationCount, showLoadMore]);

  const fetchNotifications = async () => {
    setisLoading(true);
    offset += limit;
    const response = await getNotification(offset, limit);

    if (response.data.rows?.length) {
      dispatch(
        setNotification({
          rows: [...allNotification, ...response.data.rows],
          count: response.data.count,
        })
      );
    }
    setShowLoadMore(response.data.count > allNotification?.length);
    setisLoading(false);
  };

  const onClickMessage = async (value: INotification) => {
    history.push(
      uiRoutes.patient.tab.message
        .replace(':patientId', value.patientId)
        .replace(':userId', value.userId)
    );
  };

  return (
    <MDBCard
      style={{
        minHeight: props?.notificatioPopup ? '0vh' : '85vh',
        paddingLeft: 20,
        paddingTop: 20,
      }}
    >
      <MDBCardBody
        className="container d-flex flex-column ml-4"
        style={{ padding: 0, margin: 0, minWidth: '449px' }}
      >
        {isLoading ? (
          <p className="notification-loader text-center">
            <LoadingIndicator />
          </p>
        ) : (
          <>
            <h5
              className="font-weight-bold text-primary"
              style={{ justifySelf: 'flex-start' }}
            >
              Messages from Patients
            </h5>
            {/* <p className="text-end cursor-pointer text">Mark as all read</p> */}
            {notificationCount ? (
              <div className="my-3 pb-4">
                {notifications.map((item: any) =>
                  item.data?.length ? (
                    <>
                      <p className="text my-2" key={item.id}>
                        {item.label}
                      </p>
                      {item?.data?.map((value: INotification) => (
                        <MDBDropdownItem
                          key={value.id}
                          onClick={() => onClickMessage(value)}
                          className="dropdown-item"
                          style={{ margin: 0, padding: 0 }}
                        >
                          <NotificationMessageItem
                            fullname={parseHtml(value.name, 40)}
                            time={
                              undefined
                              // item.key === NotificationDurationLabel.Today
                              //   ? 4
                              //   : undefined
                            }
                            src={defaultAvatar}
                            link={'#'}
                            dotDisplay="block"
                          />
                        </MDBDropdownItem>
                      ))}
                    </>
                  ) : null
                )}
                <div
                  className={props.children ? 'view-all' : 'load-messages'}
                  onClick={() => {
                    if (!props.children) {
                      fetchNotifications();
                    }
                  }}
                >
                  {props.children ??
                    (showLoadMore && (
                      <p>{!isLoading ? 'Load more messages' : 'Loading...'}</p>
                    ))}
                </div>
              </div>
            ) : (
              <p style={{ textAlign: 'center' }}>No new messages</p>
            )}
          </>
        )}
      </MDBCardBody>
    </MDBCard>
  );
};

export default NotificationContainer;
