import PatientRoutableTab from 'components/patient-info/PatientRoutableTab';
import React from 'react';
import colors from 'styles/constants/colors';

const PatientTabLayout = ({ children }: { children: any }) => {
  return (
    <div
      style={{
        backgroundColor: colors.background.light,
        padding: 20,
      }}
    >
      <PatientRoutableTab>{children}</PatientRoutableTab>
    </div>
  );
};

export default PatientTabLayout;
