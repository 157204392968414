import ModalWithRef from 'components/common/modal/ModalWithRef';
import {
  MDBBtn,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
} from 'mdb-react-ui-kit';
import React, { RefObject, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { refundTransaction } from 'services/transaction';
import { RefundTransactionFormType, TransactionType } from 'types/transaction';
import { UserFormType } from 'types/user';
import { getFullName } from 'utils/common';
import { formatCurrency } from 'utils/npssUtil';
import RefundTransactionForm from './RefundTransactionForm';

interface Props {
  transactionToRefund: TransactionType | null;
  handleRefundSuccess: (transactionDetailId: string) => void;
}

const defaultValues = {
  note: '',
  iAgree: false,
};

const RefundTransactionModal = React.forwardRef((props: Props, ref) => {
  const { transactionToRefund, handleRefundSuccess } = props;
  
  const patient = transactionToRefund?.patient;
  const patientFullName = getFullName(patient?.firstName, patient?.lastName);
  const amount = transactionToRefund?.totalAmount
    ? formatCurrency(transactionToRefund?.totalAmount)
    : '';

  const methods = useForm<RefundTransactionFormType>({ defaultValues });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggleModal = () => {
    (ref as RefObject<any>)?.current?.toggle?.();
  };

  const onSubmit = async (data: UserFormType) => {
    if (!transactionToRefund) return;
    setIsSubmitting(true);
    try {
      const note = methods.getValues('note');
      const response: any = await refundTransaction(transactionToRefund.id, {
        note,
        source: patient?.source,
      });
      toast.success(response.message);
      handleRefundSuccess(transactionToRefund?.id);
    } catch (error) {
      toast.error((error as any).response?.data?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    methods.reset(defaultValues);
  };

  // Watch values
  const iAgreeValue = methods.watch('iAgree');

  return (
    <ModalWithRef
      ref={ref}
      title="Refund Transaction"
      icon="file-invoice"
      size="lg"
      resetForm={resetForm}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <MDBModalBody>
            You are going to refund the transaction for{' '}
            <span className="font-weight-bold">{patientFullName}</span> for an
            amount of {amount}.
            <br />
            <span className="d-inline-block mt-2 mb-3">
              Please note that this action can't be reversed.
            </span>
            <RefundTransactionForm />
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="link"
              type="button"
              onClick={toggleModal}
              disabled={isSubmitting}
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              type="submit"
              color="secondary"
              className="text-dark"
              disabled={isSubmitting || !iAgreeValue}
            >
              {isSubmitting && <MDBSpinner size="sm" />}
              <span className={`d-inline-block ${isSubmitting ? 'ml-2' : ''}`}>
                Refund
              </span>
            </MDBBtn>
          </MDBModalFooter>
        </form>
      </FormProvider>
    </ModalWithRef>
  );
});

export default RefundTransactionModal;
