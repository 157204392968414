const NotificationAvatar = ({ src }: { src: string }) => {
  return (
    <img
      src={src}
      alt="user-avatar"
      className="rounded-circle shadow-1 cursor-pointer"
      style={{ height: '20px', width: '20px' }}
    />
  );
};

export default NotificationAvatar;
