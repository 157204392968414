import config from 'config';
import http from 'utils/http';

export const getMessages = async (
  userId: string,
  offset?: number,
  limit?: number
) => {
  const response = await http({
    method: 'GET',
    url: config.endpoints.patients.message.getAll,
    params: { userId, offset: offset ?? 0, limit: limit ?? 10 },
  });

  return response?.data;
};

export const sendMessage = async (payload: any) => {
  const response = await http({
    method: 'POST',
    url: config.endpoints.patients.message.sendMessage,
    data: payload,
  });

  return response?.data;
};
