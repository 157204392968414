// import { AxiosRequestConfig } from 'axios';
import { clear, get } from 'utils/storage';

const AUTHORIZATION_HEADER = 'authorization';
const HTTP_STATUS = {
  UNAUTHORIZED: 401,
};

/**
 * Build authorization header
 *
 * @param {string} accessToken
 * @returns {string}
 */
function buildAuthHeader(accessToken: string) {
  return `${accessToken}`;
}

/**
 * Interceptor to add authentication header for all requests.
 *
 * @param {object} request
 * @returns {object}
 */
export function requestInterceptor(request: any) {
  const accessToken = get('local', 'userToken');

  if (accessToken && !request.headers[AUTHORIZATION_HEADER]) {
    request.headers[AUTHORIZATION_HEADER] = buildAuthHeader(accessToken);
  }

  return request;
}

/**
 * Interceptor to refresh access token.
 *
 * @param {object} error
 * @returns {object}
 */
export async function responseInterceptor(error: any) {
  if (!error.response) {
    return Promise.reject(error);
  }

  const { statusCode } = error.response.data;

  if (statusCode === HTTP_STATUS.UNAUTHORIZED) {
    // Clear the local storage. Log out the user.
    clear('local');
    window.location.reload();
  }

  return Promise.reject(error);
}
