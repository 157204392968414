import FullPageSpinnner from 'components/common/full-page-spinner';
import PageLimitSelect from 'components/common/page-limit-select';
import Pagination from 'components/common/pagination';
import Search from 'components/common/search';
import TableSkeletonLoader from 'components/common/skeleton-loader/TableSkeletonLoader';
import RefundTransactionModal from 'components/transactions/RefundTransactionModal';
import TransactionListItem from 'components/transactions/TransactionListItem';
import { PaymentStatus } from 'enums/payment';
import {
  MDBBtn,
  MDBIcon,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdb-react-ui-kit';
import { RefObject, useContext, useEffect, useRef, useState } from 'react';
import { fetchTransactions } from 'services/transaction';
import { store } from 'store';
import { AppContext } from 'types/appContext';
import { RoleType } from 'types/roles';
import { TransactionType } from 'types/transaction';

interface Props {}

const Transactions = (props: Props) => {
  const appContext = useContext(store);
  const { user } = appContext as AppContext;

  /** Handles loading state for the component */
  const [isLoading, setIsLoading] = useState<boolean>(true);

  /** List of transactionDetails fetched */
  const [transactionDetails, setTransactionDetails] = useState<
    TransactionType[]
  >([]);

  /** Stores the search keyword (for generic search) */
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  /** Pagination */
  const [pagination, setPagination] = useState<any>({
    currentPage: 1,
    pageNeighbours: 2,
    limit: 10,
    total: 0,
    offset: 0,
  });

  /** Toggle variable for refresh. The fetch is performed when the value is toggled */
  const [refreshFlagToggle, setRefreshFlagToggle] = useState<boolean>(false);

  // for showing 11-20 of 40 (11 -> firstCount, 20-> -> lastCount)
  const firstCount = (pagination.currentPage - 1) * pagination.limit + 1;
  const lastCount =
    (pagination.currentPage - 1) * pagination.limit +
    transactionDetails?.length;

  /**
   * Reference to the modal
   * Used to toggle on/off the modal
   */
  const refundTransactionModalRef = useRef<any>();
  const [transactionToRefund, setTransactionToRefund] =
    useState<TransactionType | null>(null);

  /**
   * Side effect responsible for fetching order details with required query params
   */
  useEffect(() => {
    const getTransactions = async () => {
      setIsLoading(true);
      let queryParams: any = {
        offset: pagination.offset,
        limit: pagination.limit,
      };
      if (user?.patientId) {
        queryParams.patientId = user.patientId;
      }
      if (searchKeyword) queryParams.keyword = searchKeyword;
      const response: any = await fetchTransactions(queryParams);
      setTransactionDetails(
        response?.data?.rows?.map((item: any) => ({
          ...item,
        }))
      );
      setPagination((prevState: any) => ({
        ...prevState,
        total: response.data.count,
      }));
      setIsLoading(false);
    };
    getTransactions();
  }, [
    pagination.currentPage,
    pagination.offset,
    pagination.limit,
    searchKeyword,
    refreshFlagToggle,
    user?.patientId,
  ]);

  /**
   * Page change handler. Updates the offset according to new page.
   *
   * @param {number} currentPage
   */
  const handlePageChange = (currentPage: number) => {
    const pageOffset = (currentPage - 1) * pagination.limit;
    setPagination((prevState: any) => ({
      ...prevState,
      offset: pageOffset,
      currentPage: currentPage,
    }));
  };

  /**
   * Limit change handler. Updates the limit, offset and currentPage according to the new limit
   *
   * @param {number} newLimit
   */
  const handleLimitChange = (newLimit: number) => {
    if (pagination.limit === newLimit) return;
    setPagination((prevState: any) => ({
      ...prevState,
      offset: 0,
      currentPage: 1,
      limit: newLimit,
    }));
  };

  /**
   * Handles search by keyword
   *
   * @param {string} keyword
   */
  const performGenericSearch = (keyword: string) => {
    setSearchKeyword(keyword);
    handlePageChange(1); // need to start from page 1 when there is a search
  };

  /**
   * Handles refresh. Toggles the `refreshFlagToggle` variable.
   */
  const handleRefresh = () => {
    setRefreshFlagToggle((prevState) => !prevState);
  };

  /**
   * Set transaction to refund and open the modal
   *
   * @param {string} transactionId
   */
  const onRefundTransaction = (transactionId: string) => {
    setTransactionToRefund(
      transactionDetails.find((item) => item.id === transactionId) ?? null
    );
    refundTransactionModalRef.current.toggle();
  };

  /**
   * Transaction refund success handler
   *
   * @param {string} transactionDetailId
   */
  const handleRefundSuccess = (transactionDetailId: string) => {
    const updatedTransactionDetails = transactionDetails?.map((td) => ({
      ...td,
      status:
        td.status === transactionDetailId ? PaymentStatus.REFUNDED : td.status,
    }));
    setTransactionDetails(updatedTransactionDetails);
    // TODO: Update the transaction detail without a refresh
    handleRefresh();
    refundTransactionModalRef.current.toggle();
  };

  if (!user) {
    return <FullPageSpinnner />;
  }

  const currentUserRole = user?.role;

  return (
    <div>
      {/* Search */}
      <div className="mb-3 w-100 d-flex justify-content-between align-items-center">
        <Search performGenericSearch={performGenericSearch}></Search>
        <div className="d-flex align-items-center">
          <MDBBtn
            color="link"
            className="refresh-button"
            onClick={handleRefresh}
          >
            <MDBIcon icon="redo-alt" className="text-muted fs-5" />
          </MDBBtn>
          {transactionDetails?.length > 0 && (
            <PageLimitSelect
              limit={pagination.limit}
              handleLimitChange={handleLimitChange}
            ></PageLimitSelect>
          )}
        </div>
      </div>

      {/* Result Count */}
      {transactionDetails?.length > 0 && (
        <small className="text-muted d-inline-block mb-3">
          Showing {firstCount} - {lastCount} of {pagination.total}
        </small>
      )}

      {/* Table */}
      {!isLoading && (
        <div className="table-wrapper">
          <MDBTable className="table-min-width-unset">
            <MDBTableHead>
              <tr className="table-header">
                <th scope="col" className="font-weight-bold ">
                  Transaction
                </th>
                <th scope="col" className="font-weight-bold ">
                  Patient
                </th>
                <th scope="col" className="font-weight-bold">
                  Contact
                </th>
                <th scope="col" className="font-weight-bold">
                  Address
                </th>
                <th scope="col" className="font-weight-bold">
                  Description
                </th>
                <th scope="col" className="font-weight-bold">
                  Status
                </th>
                <th scope="col" className="font-weight-bold">
                  Action
                </th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {!isLoading && transactionDetails?.length === 0 && (
                <p className="text-muted mt-3">No transactions found.</p>
              )}
              {transactionDetails?.map((transaction: TransactionType) => (
                <TransactionListItem
                  data={transaction}
                  key={transaction.id}
                  onRefundTransaction={onRefundTransaction}
                  currentUserRole={currentUserRole as RoleType}
                />
              ))}
            </MDBTableBody>
          </MDBTable>
        </div>
      )}

      {/* Loader */}
      {isLoading && <TableSkeletonLoader rows={3} cols={3} />}

      {/* Pagination */}
      {!isLoading && (
        <div className="d-flex justify-content-center mt-4">
          <Pagination
            pageLimit={pagination.limit}
            totalCount={pagination.total}
            currentPage={pagination.currentPage}
            pageNeighbours={pagination.pageNeighbours}
            onPageChangedHandler={handlePageChange}
          />
        </div>
      )}

      {/* Modal for refunding transaction */}
      <RefundTransactionModal
        ref={refundTransactionModalRef as RefObject<HTMLElement>}
        transactionToRefund={transactionToRefund}
        handleRefundSuccess={handleRefundSuccess}
      />
    </div>
  );
};

export default Transactions;
