import jwtDecode from 'jwt-decode';
import { IJWTDecode } from 'types/jwt';

export const decodeJwtToken = (token: string) => jwtDecode(token);

export const isTokenExpired = (token: string) => {
  const { exp }: IJWTDecode = jwtDecode(token);
  const expirationTime = exp * 1000 - 60000;
  if (Date.now() >= expirationTime) return true;
  return false;
};
