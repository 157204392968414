import {
  MDBAlert,
  MDBBtn,
  MDBCheckbox,
  MDBInput,
  MDBSpinner,
} from 'mdb-react-ui-kit';
import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { login } from 'services/auth';
import { store } from 'store';
import { AppContext } from 'types/appContext';
import { LoginType } from 'types/auth';
import { validateEmail } from 'utils/validation';
import TwoFactorAuthenticationModal from './TwoFactorAuthenticationModal';

const EMAIL_REQUIRED_MSG = 'Please enter your email address';
const EMAIL_VALIDATION_MSG = 'Please enter a valid email address';
const PASSWORD_REQUIRED_MSG = 'Please enter your password';

interface Props {}

type ErrorObj = {
  response: {
    data: {
      message: string;
    };
  };
};

const Login = (props: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm<LoginType>({
    defaultValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
  });

  const location = useLocation();

  const appContext = useContext(store);
  const { setUserToken } = appContext as AppContext;
  const searchParams = new URLSearchParams(location.search);

  const history = useHistory();
  const email = getValues('email');

  const [error, setError] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const onSubmit = async (data: LoginType) => {
    try {
      setIsSubmitting(true);
      const response: any = await login(data);
      // await login(data);
      if (!response?.data?.token) {
        toggleModal();
      } else {
        setUserToken(response?.data?.token);

        const redirectPath = searchParams.get('redirect');

        if (redirectPath) {
          history.push(redirectPath);
        } else {
          history.push('/');
        }
      }
    } catch (err) {
      setError((err as ErrorObj).response?.data?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onForgotPasswordClick = () => {
    history.push('/forget-password');
  };

  return (
    <div className="login">
      <h3 className="mb-4pt5">Login</h3>

      <MDBAlert
        show={!!error}
        className="w-100 p-2 rounded-1 mb-4"
        color="danger"
      >
        {error}
      </MDBAlert>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          rules={{
            required: EMAIL_REQUIRED_MSG,
            validate: (value) => validateEmail(value) || EMAIL_VALIDATION_MSG,
          }}
          render={({ field }) => (
            <MDBInput
              {...field}
              label="Email"
              id="email"
              size="lg"
              wrapperClass="mb-4pt5"
              className={`bg-white ${errors.email ? 'is-invalid' : ''}`}
              validation={errors.email ? errors.email?.message : ''}
              invalid={!!errors.email}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          rules={{
            required: PASSWORD_REQUIRED_MSG,
          }}
          render={({ field }) => (
            <MDBInput
              {...field}
              label="Password"
              id="password"
              type="password"
              size="lg"
              wrapperClass="mb-4"
              className={`bg-white ${errors.password ? 'is-invalid' : ''}`}
              validation={errors.password ? errors.password?.message : ''}
              invalid={!!errors.password}
            />
          )}
        />

        <div className="d-flex justify-content-between align-itens-center mt-0 mb-4">
          <MDBCheckbox
            name="flexCheck"
            value=""
            filled
            id="remember-me"
            label="Remember me"
          />
          <p
            className="mb-0 cursor-pointer muted-link"
            onClick={onForgotPasswordClick}
          >
            Forgot Password?
          </p>
        </div>

        <MDBBtn
          color="secondary"
          size="lg"
          className="shadow-none w-100 text-dark"
          disabled={isSubmitting}
        >
          {isSubmitting && (
            <MDBSpinner size="sm" role="status" tag="span" className="me-2" />
          )}
          Login
        </MDBBtn>
      </form>
      <TwoFactorAuthenticationModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        email={email}
      />

      <div className="d-flex flex-column align-items-center text-muted mt-4">
        <small className="mb-1">
          Copyright &copy; {new Date().getFullYear()}. MAT Rx.
        </small>
        <small>
          <a href="/terms-of-use" target="_blank" className="muted-link">
            Terms of Use
          </a>{' '}
          |{' '}
          <a href="/privacy-policy" target="_blank" className="muted-link">
            Privacy Policy
          </a>
        </small>
      </div>
    </div>
  );
};

export default Login;
