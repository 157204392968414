import LoadingIndicator from 'components/common/LoadingIndicator';
import SurveyList from 'components/patient-survey/SurveyList';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchPatientDetail } from 'services/patient';
import { PatientDetailType } from 'types/patient';

const PatientDetail = () => {
  const params: { patientId: string } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [detail, setDetail] = useState<PatientDetailType>();
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const getPatientDetail = async () => {
      setIsLoading(true);
      try {
        const response: any = await fetchPatientDetail(
          params.patientId?.toString()
        );
        setDetail(response?.data);
      } catch (e) {
        setError('Patient not found');
      } finally {
        setIsLoading(false);
      }
    };
    getPatientDetail();
  }, [params.patientId]);

  if (isLoading)
    return (
      <div className="d-flex justify-content-center">
        <LoadingIndicator />
      </div>
    );
  if (error) return <p>{error}</p>;

  return <SurveyList detail={detail} />;
};

export default PatientDetail;
