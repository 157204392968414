export const uiRoutes = {
  activityLog: '/activity-log',
  billings: '/billings',
  forgotPassword: '/forget-password',
  main: '/',
  myProfile: '/my-profile',
  login: '/login',
  logout: '/logout',
  patient: {
    patients: '/patients',
    tab: {
      survey: '/patients/:patientId/survey',
      message: '/patients/:patientId/:userId/messages',
      document: '/patients/:patientId/:userId/documents',
    },
  },
  privacyPolicy: '/privacy-policy',
  receipt: '/receipt/:transactionId',
  resetPassword: '/reset-password',
  orders: {
    orders: '/orders',
    patientDetailTabs: {
      survey: '/orders/patient-detail/:patientId/survey',
      message: '/orders/patient-detail/:patientId/:userId/messages',
      document: '/orders/patient-detail/:patientId/:userId/documents',
    }
  },

  setPassword: '/set-password',
  termsOfUse: '/terms-of-use',
  user: { users: '/users', verify: '/users/verify' },
  notifications: '/notifications',
};
