/**
 * Application wide configuration.
 */
const config = {
  env: process.env.NODE_ENV,
  baseURI: process.env.REACT_APP_API_BASE_URI,
  INPUT_DATE_FORMAT: 'MM/dd/yyyy',
  DOSESPOT: {
    clinicId: process.env.REACT_APP_CLINIC_ID,
    clinicianId: process.env.REACT_APP_CLINICIAN_ID,
  },
  endpoints: {
    activityLogs: {
      getAll: '/activity-logs',
      save: '/activity-logs',
    },
    application: {
      getAllIds: '/application/ids',
      getShippingMethods: '/application/shipping-methods',
    },
    auth: {
      login: '/admin/auth/login',
      resetPassword: '/admin/auth/reset-password',
      forgetPassword: '/admin/auth/forget-password',
      verifyTwoFactorCode: '/admin/auth/verify-code',
      resendTwoFactorCode: '/admin/auth/resend-code',
    },
    dosespot: {
      fetchEncryptedData: '/dosespot/encrypted-data',
    },
    notification: {
      getAll: '/messages/new?userType=Patient',
      getCount: '/messages/unread-count',
      changeStatus: '/messages/change-status',
    },
    orders: {
      approve: '/admin/orders/:id/approve',
      getAll: '/admin/orders',
    },
    patients: {
      changeStatus: '/patients/:id/change-status',
      get: '/admin/patients/:id',
      getAll: '/admin/patients',
      message: {
        sendMessage: '/messages',
        getAll: '/messages',
      },
      document: {
        getAll: '/admin/patients/:patientUserId/documents',
        uploadDocumentToPatient: '/admin/patients/:patientUserId/documents',
        shareDocumentToPatient:
          '/admin/patients/:patientUserId/documents/share',
        deleteDocument: '/admin/patients/:patientUserId/documents/:id',
        downloadDocument:
          '/admin/patients/:patientUserId/documents/:id/download',
      },
    },
    // patientStatus: {
    //   getAll: '/patient-status',
    // },
    roles: {
      getAll: '/admin/roles',
    },
    transactions: {
      getAll: '/admin/transactions',
      getOne: '/admin/transactions/:id',
      refund: '/admin/transactions/:id/refund',
    },
    transactionDetails: {
      getAll: '/admin/transaction-details',
      get: '/admin/transaction-details/:id',
    },
    users: {
      changeStatus: '/admin/users/:id/activation',
      create: '/admin/users',
      delete: '/admin/users/:id',
      getAll: '/admin/users',
      getOne: '/admin/users/:id',
      invite: '/admin/users/:id/invite',
      update: '/admin/users/:id',
    },
  },
};

export default config;
