import FullPageSpinnner from 'components/common/full-page-spinner';
import PageLimitSelect from 'components/common/page-limit-select';
import Pagination from 'components/common/pagination';
import Search from 'components/common/search';
import TableSkeletonLoader from 'components/common/skeleton-loader/TableSkeletonLoader';
import OrderDetailListItem from 'components/order-details/OrderDetailListItem';
import PrescriptionModal from 'components/order-details/PrescriptionModal';
import PrescriptionNotAvailableModal from 'components/order-details/PrescriptionNotAvailableModal';
import { PaymentStatus } from 'enums/payment';
import {
  MDBBtn,
  MDBIcon,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdb-react-ui-kit';
import { RefObject, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { fetchEncryptedData } from 'services/dosespot';
import { approveOrder, fetchOrders } from 'services/order';
import { store } from 'store';
import { useConfirmationModal } from 'stores/ConfirmationModal';
import { AppContext } from 'types/appContext';
import { EncryptedIdsType } from 'types/dosespot';
import { IOrderTableRow } from 'types/order';
import { RoleType } from 'types/roles';
import { getFullName } from 'utils/common';
import { hasActionAccess } from 'utils/npssUtil';
import { URIEncoder } from 'utils/uri';

interface Props {}

const Orders = (props: Props) => {
  const appContext = useContext(store);
  const { user } = appContext as AppContext;

  const createShippingLabelConfirmationModal = useConfirmationModal();

  /** Handles loading state for the component */
  const [isLoading, setIsLoading] = useState<boolean>(true);

  /** List of order details fetched */
  const [orderDetails, setOrderDetails] = useState<IOrderTableRow[]>([]);

  /** Stores the search keyword (for generic search) */
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  /** Pagination */
  const [pagination, setPagination] = useState<any>({
    currentPage: 1,
    pageNeighbours: 2,
    limit: 10,
    total: 0,
    offset: 0,
  });

  /** Toggle variable for refresh. The fetch is performed when the value is toggled */
  const [refreshFlagToggle, setRefreshFlagToggle] = useState<boolean>(false);

  /** Encrypted Ids required for DoseSpot */
  const [encryptedIds, setEncryptedIds] = useState<EncryptedIdsType>({
    encryptedClinicId: '',
    encryptedUserId: '',
  });

  /** DoseSpot patientId */
  const [patientIdToPrescribe, setPatientIdToPrescribe] = useState<
    number | null
  >(null);

  /** Order needs to be approved for creating CaryRx Order. This object holds the order which the user is going to approve. */
  // const [orderToApprove, setOrderToApprove] = useState<any>(null);

  /**
   * Reference to the DoseSpot prescription modal
   * Used to toggle on/off the modal
   */
  const prescriptionModalRef = useRef<any>();
  /**
   * Reference to the modal showing DoseSpot prescription not available
   * Used to toggle on/off the modal
   */
  const prescriptionNotAvailableModalRef = useRef<any>();
  /**
   * Reference to the modal for adding fulfillment order
   * Used to toggle on/off the modal
   */
  // const addFulfillmentOrderModalRef = useRef<any>();

  // for showing 11-20 of 40 (11 -> firstCount, 20-> -> lastCount)
  const firstCount = (pagination.currentPage - 1) * pagination.limit + 1;
  const lastCount =
    (pagination.currentPage - 1) * pagination.limit + orderDetails?.length;

  /**
   * Side effect responsible for fetching order details with required query params
   */
  useEffect(() => {
    const getOrderDetails = async () => {
      setIsLoading(true);
      let queryParams: any = {
        offset: pagination.offset,
        limit: pagination.limit,
      };
      if (user?.patientId) {
        queryParams.patientId = user.patientId;
      }
      if (searchKeyword) queryParams.keyword = searchKeyword;
      const response: any = await fetchOrders(queryParams);
      setOrderDetails(response?.data?.rows);
      setPagination((prevState: any) => ({
        ...prevState,
        total: response.data.count,
      }));
      setIsLoading(false);
    };
    getOrderDetails();
  }, [
    pagination.currentPage,
    pagination.offset,
    pagination.limit,
    searchKeyword,
    refreshFlagToggle,
    user?.patientId,
  ]);

  /**
   * Side effect responsible for fetching encrypted clinicId and userId needed for DoseSpot
   */
  useEffect(() => {
    const getEncryptedDataForDoseSpot = async () => {
      const response: any = await fetchEncryptedData();
      setEncryptedIds({
        encryptedClinicId: URIEncoder(response?.data?.encryptedClinicId),
        encryptedUserId: URIEncoder(response?.data?.encrptedUserId),
      });
    };

    getEncryptedDataForDoseSpot();
  }, []);

  /**
   * Page change handler. Updates the offset according to new page.
   *
   * @param {number} currentPage
   */
  const handlePageChange = (currentPage: number) => {
    const pageOffset = (currentPage - 1) * pagination.limit;
    setPagination((prevState: any) => ({
      ...prevState,
      offset: pageOffset,
      currentPage: currentPage,
    }));
  };

  /**
   * Limit change handler. Updates the limit, offset and currentPage according to the new limit
   *
   * @param {number} newLimit
   */
  const handleLimitChange = (newLimit: number) => {
    if (pagination.limit === newLimit) return;
    setPagination((prevState: any) => ({
      ...prevState,
      offset: 0,
      currentPage: 1,
      limit: newLimit,
    }));
  };

  /**
   * Handles search by keyword
   *
   * @param {string} keyword
   */
  const performGenericSearch = (keyword: string) => {
    setSearchKeyword(keyword);
    handlePageChange(1); // need to start from page 1 when there is a search
  };

  /**
   * Handles refresh. Toggles the `refreshFlagToggle` variable.
   */
  const handleRefresh = () => {
    setRefreshFlagToggle((prevState) => !prevState);
  };

  /**
   * Show prescription modal if dSpotPatientId exists and the payment has succeeded
   * Gets triggered when the user clicks on `Add Prescription` in the ellipse's menu
   *
   * @param dSpotPatientId
   * @param paymentStatus
   */
  const openPrescriptionModal = (
    dSpotPatientId: number,
    paymentStatus: string
  ) => {
    if (dSpotPatientId && paymentStatus === PaymentStatus.SUCCEEDED) {
      setPatientIdToPrescribe(dSpotPatientId);
      prescriptionModalRef.current.toggle();
      return;
    }

    prescriptionNotAvailableModalRef.current.toggle();
  };

  const onCreateShippingLabelClick = async (order: any) => {
    if (!order) {
      return;
    }

    const drug = order.orderDetails?.[0]?.drug?.brandName;
    const subscriptionPack = order.orderDetails?.[0]?.drugOffering?.name;
    const patientName = getFullName(
      order.patient?.firstName,
      order.patient?.lastName,
      order.patient?.middleName
    );

    const result =
      await createShippingLabelConfirmationModal?.openConfirmationModal({
        title: `Create Shipping Label`,
        content:
          'Are you sure you want to create shipping label for the following order?',
        contentExtra: `
          <div class="mt-4">
            <p> Order Id: ${order.orderNumber}</p>
            <p> Drug: ${drug} (${subscriptionPack})</p>
            <p> Patient: ${patientName}</p>
          </div>
          `,
      });

    if (result) {
      createShippingLabelConfirmationModal?.changeSubmittingStatus(true);
      try {
        const res: any = await approveOrder(order.id);
        createShippingLabelConfirmationModal?.changeSubmittingStatus(false);
        handleRefresh();
        toast.success(res.message);
      } catch (error) {
        toast.error((error as any).response?.data?.message);
      }
    }
  };

  // const handleOrderApproveSuccess = () => {
  //   setOrderToApprove(null);
  //   handleRefresh();
  // };

  if (!user) {
    return <FullPageSpinnner />;
  }

  const currentUserRole = user?.role;

  return (
    <div className="user-list">
      {/* Search */}
      <div className="mb-3 w-100 d-flex justify-content-between align-items-center">
        <Search performGenericSearch={performGenericSearch}></Search>
        <div className="d-flex align-items-center">
          <MDBBtn
            color="link"
            className="refresh-button"
            onClick={handleRefresh}
          >
            <MDBIcon icon="redo-alt" className="text-muted fs-5" />
          </MDBBtn>
          {orderDetails?.length > 0 && (
            <PageLimitSelect
              limit={pagination.limit}
              handleLimitChange={handleLimitChange}
            ></PageLimitSelect>
          )}
        </div>
      </div>

      {/* Result Count */}
      {orderDetails?.length > 0 && (
        <small className="text-muted d-inline-block mb-3">
          Showing {firstCount} - {lastCount} of {pagination.total}
        </small>
      )}

      {/* Table */}
      {!isLoading && (
        <div className="table-wrapper">
          <MDBTable className="table-min-width-unset">
            <MDBTableHead>
              <tr className="table-header">
                <th scope="col" className="font-weight-bold"></th>
                <th scope="col" className="font-weight-bold">
                  Order
                </th>
                <th scope="col" className="font-weight-bold">
                  Patient
                </th>
                <th scope="col" className="font-weight-bold">
                  Drug
                </th>
                <th scope="col" className="font-weight-bold">
                  Payment Status
                </th>
                <th scope="col" className="font-weight-bold">
                  Order Status
                </th>

                {hasActionAccess(currentUserRole as RoleType) && (
                  <th scope="col" className="font-weight-bold">
                    Action
                  </th>
                )}
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {!isLoading && orderDetails?.length === 0 && (
                <p className="text-muted mt-3">No orders found.</p>
              )}
              {orderDetails?.map((orderDetail: IOrderTableRow) => (
                <>
                  <OrderDetailListItem
                    key={orderDetail.id}
                    data={orderDetail}
                    openPrescriptionModal={openPrescriptionModal}
                    onCreateShippingLabelClick={onCreateShippingLabelClick}
                    currentUserRole={currentUserRole as RoleType}
                  />
                </>
              ))}
            </MDBTableBody>
          </MDBTable>
        </div>
      )}

      {/* Loader */}
      {isLoading && <TableSkeletonLoader rows={3} cols={3} />}

      {/* Pagination */}
      {!isLoading && (
        <div className="d-flex justify-content-center mt-4">
          <Pagination
            pageLimit={pagination.limit}
            totalCount={pagination.total}
            currentPage={pagination.currentPage}
            pageNeighbours={pagination.pageNeighbours}
            onPageChangedHandler={handlePageChange}
          />
        </div>
      )}

      {/* Modal for prescribing via DoseSpot */}
      <PrescriptionModal
        ref={prescriptionModalRef as RefObject<HTMLElement>}
        encryptedIds={encryptedIds}
        patientId={patientIdToPrescribe}
      />

      {/* Modal showing DoseSpot prescription cannot be added due to unsucceded payment status */}
      <PrescriptionNotAvailableModal
        ref={prescriptionNotAvailableModalRef as RefObject<HTMLElement>}
      />

      {/* Modal for adding fulfillment order */}
      {/* <AddFulfillmentOrderModal
        ref={addFulfillmentOrderModalRef as RefObject<HTMLElement>}
        order={orderToApprove}
        handleOrderApproveSuccess={handleOrderApproveSuccess}
      /> */}
    </div>
  );
};

export default Orders;
