import { configureStore } from '@reduxjs/toolkit';
import messagesReducer from './patient/messages';
import notificationReducer from './patient/notification';
import patientReducer from './patient/patient-detail';

export const store = configureStore({
  reducer: {
    messages: messagesReducer,
    patient: patientReducer,
    notification: notificationReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
