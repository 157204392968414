export function get(type: string, key: string) {
  if (type === 'local') {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem(key);
    }
    return '';
  }
  if (typeof sessionStorage !== 'undefined') {
    return sessionStorage.getItem(key);
  }
  return '';
}

export function set(type: string, key: string, value: string) {
  if (type === 'local') {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(key, value);
    }
  }
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.setItem(key, value);
  }
}

export function clear(type: string) {
  if (type === 'local') {
    if (typeof localStorage !== 'undefined') {
      localStorage.clear();
    }
  }
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.clear();
  }
}

export function remove(type: string, key: string) {
  if (type === 'local') {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(key);
    }
  }
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.removeItem(key);
  }
}
