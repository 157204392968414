import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle,
} from 'mdb-react-ui-kit';
import React, { useImperativeHandle, useState } from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  children: React.ReactChild;
  icon?: string;
  title: string;
  size?: 'sm' | 'lg' | 'xl' | 'fullscreen' | '';
  resetForm?: () => void;
}

const ModalWithRef = React.forwardRef((props: Props, ref) => {
  const { children, title, icon, size = '', resetForm } = props;

  const [showModal, setShowModal] = useState<boolean>(false);
  const history = useHistory();

  const toggle = () => {
    setShowModal((prevState) => !prevState);
    typeof resetForm === 'function' && resetForm();
    if (showModal === true) {
      setTimeout(() => {
        history.go(0);
      }, 2000);
    }
  };

  useImperativeHandle(ref, () => ({
    toggle,
  }));

  return (
    <MDBModal
      show={showModal}
      getOpenState={(e: any) => setShowModal(e)}
      tabIndex="-1"
      ref={ref}
      staticBackdrop
    >
      <MDBModalDialog size={size}>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle className="text-dark font-weight-bold">
              {icon && <MDBIcon icon={icon} className="text-primary" />}
              <span className={`d-inline-block ${icon ? 'ml-3' : ''}`}>
                {title}
              </span>
            </MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={toggle}
              type="button"
            ></MDBBtn>
          </MDBModalHeader>
          {children}
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
});

export default ModalWithRef;
