/** extend additional color here */
export const colors = {
  primary: {
    default: '#0D47A1',
    light: '#E5F0FF',
    lighter: '#F0F6FF',
  },
  secondary: {
    default: '#FFB200',
  },
  neutral: {
    darker: '#2d2d2d',
    dark: '#525252',
    default: '#848484',
    light: '#B3B3B3',
    lighter: '#F8F9FA',
  },
  green: { dark: '#005B3D', lime: '#7DD4B540', medium: '#319795' },
  error: {
    default: '#B3251E',
  },
  background: {
    default: '#F9F9F9',
    light: '#B3B3B366',
  },
  text: {
    default: '#2d2d2d',
    faded: '#737373',
  },
  white: {
    default: '#fff',
  },
  blue: { light: '#116A9F' },
  // Not present in the style guide, remove these
  accent: {
    default: '#4E5DD9',
    hovered: '#4a57c2',
  },
  accentText: {
    default: '#fff',
  },
  light: {
    default: '#FEF8F3',
    defaultLight: '#F4D9C3',
    blue: '#E5F5FD',
    purple: '#EEEFFF',
  },
  //--------------------------------------------
};

export default colors;
