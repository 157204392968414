import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { URIEncoder } from 'utils/uri';

interface Props {}

const TokenVerification = (props: Props) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    if (!token) {
      history.push('/');
      return;
    }

    // Replacing the ' ' with '+' is necessary because the token contains '+' which is encoded to ' ' in query string
    // '+' sign in the query string is URL-decoded to a space. '%2B' in the query string is URL-decoded to a '+' sign.
    // So, in order to preserve the '+' in the token, we are converting ' ' into '+' and encoding it
    // Visit: https://stackoverflow.com/questions/6855624/plus-sign-in-query-string/6855723 for more details
    const actualToken = token.toString().replaceAll(' ', '+');

    history.push(`/set-password?token=${URIEncoder(actualToken)}`);
  }, [history, location.search]);

  return (
    <div>
      <p>Verifying token...</p>
    </div>
  );
};

export default TokenVerification;
