import Chip from 'components/common/chip';
import FullPageSpinnner from 'components/common/full-page-spinner';
import PageLimitSelect from 'components/common/page-limit-select';
import Pagination from 'components/common/pagination';
import Search from 'components/common/search';
import TableSkeletonLoader from 'components/common/skeleton-loader/TableSkeletonLoader';
import StatusSwitchConfirmationModal from 'components/users/StatusSwitchConfirmationModal';
import UserAddEditModal from 'components/users/UserAddEditModal';
import UserListItem from 'components/users/UserListItem';
import { MENUS } from 'constants/menus';
import { UserStatus } from 'enums/user';
import {
  MDBBtn,
  MDBIcon,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdb-react-ui-kit';
import { useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { fetchUsers } from 'services/user';
import { store } from 'store';
import { AppContext } from 'types/appContext';
import { RoleType } from 'types/roles';
import { UserFilterType, UserType } from 'types/user';
import { convertCamelCaseToTitleCase } from 'utils/string';

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
};

type UserSetToChangeStatusType = {
  user: UserType | null;
  userId: string;
  newStatus: boolean;
};

interface Props {}

const UserList = (props: Props) => {
  const appContext = useContext(store);
  const { user } = appContext as AppContext;

  const methods = useForm<UserFilterType>({ defaultValues });

  /** Reference to the advanced search popover toggler button.
   *  Used to close the advanced search popover when the `Apply` button is clicked.
   */
  const advancedSearchRef = useRef<HTMLButtonElement>();

  /**
   * Reference to the confirmation modal that opens up when we try to switch status of a user
   * Used to toggle on/off the modal
   */
  const statusSwitchConfirmationModalRef = useRef<any>();

  /** Handles loading state for the component */
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  /** List of users fetched */
  const [users, setUsers] = useState<UserType[]>([]);

  /** Stores the search keyword (for generic search) */
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  /** Stores the filters (for advanced search) */
  const [filters, setFilters] = useState<UserFilterType>(defaultValues);

  /** Pagination */
  const [pagination, setPagination] = useState<any>({
    currentPage: 1,
    pageNeighbours: 2,
    limit: 10,
    total: 0,
    offset: 0,
  });

  /** Stores the user set to change status */
  const [userSetToChangeStatus, setUserSetToChangeStatus] =
    useState<UserSetToChangeStatusType>({
      user: null,
      userId: '',
      newStatus: false,
    });

  /** Stores the user set to edit */
  const [userIdSetToEdit, setUserIdSetToEdit] = useState<string>('');

  /** Toggle variable for refresh. The fetch is performed when the value is toggled */
  const [refreshFlagToggle, setRefreshFlagToggle] = useState<boolean>(false);

  // for showing 11-20 of 40 (11 -> firstCount, 20-> -> lastCount)
  const firstCount = (pagination.currentPage - 1) * pagination.limit + 1;
  const lastCount =
    (pagination.currentPage - 1) * pagination.limit + users?.length;

  /**
   * Side effect responsible for fetching patients with required query params
   */
  useEffect(() => {
    const chooseFilterParams = () => {
      let filterParams: any = {};
      if (filters.firstName) filterParams.firstName = filters.firstName;
      if (filters.lastName) filterParams.lastName = filters.lastName;
      if (filters.email) filterParams.email = filters.email;
      return filterParams;
    };

    const getUsers = async () => {
      setIsLoading(true);
      let queryParams: any = {
        offset: pagination.offset,
        limit: pagination.limit,
      };
      if (searchKeyword) queryParams.keyword = searchKeyword;
      const filterParams = chooseFilterParams();
      queryParams = { ...queryParams, ...filterParams };
      const response: any = await fetchUsers(queryParams);
      setUsers(response?.data?.rows);
      setPagination((prevState: any) => ({
        ...prevState,
        total: response.data.count,
      }));
      setIsLoading(false);
    };
    getUsers();
  }, [
    pagination.currentPage,
    pagination.offset,
    pagination.limit,
    searchKeyword,
    filters,
    refreshFlagToggle,
  ]);

  /**
   * Page change handler. Updates the offset according to new page.
   *
   * @param {number} currentPage
   */
  const handlePageChange = (currentPage: number) => {
    const pageOffset = (currentPage - 1) * pagination.limit;
    setPagination((prevState: any) => ({
      ...prevState,
      offset: pageOffset,
      currentPage: currentPage,
    }));
  };

  /**
   * Limit change handler. Updates the limit, offset and currentPage according to the new limit
   *
   * @param {number} newLimit
   */
  const handleLimitChange = (newLimit: number) => {
    if (pagination.limit === newLimit) return;
    setPagination((prevState: any) => ({
      ...prevState,
      offset: 0,
      currentPage: 1,
      limit: newLimit,
    }));
  };

  /**
   * Handles search by keyword
   *
   * @param {string} keyword
   */
  const performGenericSearch = (keyword: string) => {
    setSearchKeyword(keyword);
    handlePageChange(1); // need to start from page 1 when there is a search
  };

  /**
   * Handles refresh. Toggles the `refreshFlagToggle` variable.
   */
  const handleRefresh = () => {
    setRefreshFlagToggle((prevState) => !prevState);
  };

  /**
   * Closes the popover used for advanced search
   * Achieves the action by triggering the click event of popover toggler btn with ref
   */
  const closeAdvancedSearchPopover = (): void => {
    typeof advancedSearchRef.current?.click === 'function' &&
      advancedSearchRef.current.click();
  };

  /**
   * Resets the filters. Also, the RHF form values are reset.
   */
  // const handleResetFilters = () => {
  //   methods.reset(defaultValues);
  //   setFilters(defaultValues);
  // };

  /**
   * Form submit handler for Advanced search form.
   * Sets the filters with new filters and the side effect fetches the new user list
   *
   * @param {UserFilterType} data
   */
  const applyFilters = (data: UserFilterType) => {
    setFilters(data);
    handlePageChange(1); // need to start from page 1 when there is a search
    closeAdvancedSearchPopover();
  };

  /**
   * Handles removal of applied filter
   * RHF form value of the filter is also reset (set to '')
   *
   * @param {keyof UserFilterType} filterKey
   */
  const removeFilter = (filterKey: keyof UserFilterType) => {
    methods.setValue(filterKey, '');
    setFilters((prevState) => ({ ...prevState, [filterKey]: '' }));
  };

  /**
   * Variable to check if any advanced filters is applied or not.
   * The applied filters section is shown based on this variable.
   *
   */
  const isFilterApplied = Object.keys(filters)?.some(
    (filterKey) => filters[filterKey as keyof UserFilterType] !== ''
  );

  /**
   * Gets triggered when the user clicks on add user button
   */
  const onAddUserClick = () => {
    setIsAddUserModalOpen(true);
  };

  /**
   * Prepend added user to the user list
   *
   * @param {UserType} addedUser
   */
  const handleUserAddSuccess = (addedUser: UserType) => {
    setUsers((prevState) => [
      {
        ...addedUser,
        role: addedUser?.roles?.length ? addedUser.roles[0].name : '',
      },
      ...prevState,
    ]);
  };

  /**
   * Gets triggered when the user attempts to change user's status
   * Stores the user whose status is to be changed
   * The side effect opens the confirmation modal for the action
   *
   * @param {string} userId
   * @param {boolean} newStatus
   */
  const onChangeStatus = (userId: string, newStatus: boolean) => {
    const selectedUser = users.find((user) => user.id === userId);
    if (!selectedUser) return;

    setUserSetToChangeStatus({
      user: selectedUser,
      userId,
      newStatus,
    });
  };

  /**
   * Side effect to show confirmation modal when the user attempts to change the status
   */
  useEffect(() => {
    if (userSetToChangeStatus?.userId) {
      statusSwitchConfirmationModalRef.current.toggle();
    }
  }, [userSetToChangeStatus]);

  /**
   * Update the user in the list with the new status
   *
   * @param {string} userId
   * @param {boolean} newStatus
   */
  const handleUpdateUserStatus = (userId: string, newStatus: boolean) => {
    const updatedUserList = users.map((user) => {
      if (user.id !== userId) return user;
      return {
        ...user,
        status: newStatus ? UserStatus.ACTIVE : UserStatus.INACTIVE,
      };
    });
    setUsers(updatedUserList);
  };

  const handleUserUpdateSuccess = (userId: string, updatedData: UserType) => {
    const updatedUserList = users.map((user) => {
      if (user.id !== userId) return user;
      return {
        ...updatedData,
        role: updatedData?.roles?.length ? updatedData?.roles[0].name : '',
      };
    });
    setUsers(updatedUserList);
  };

  const handleUserDeleteSuccess = (userId: string) => {
    const updatedUserList = users.filter((user) => user.id !== userId);
    setUsers(updatedUserList);
  };

  /**
   * Close the user status change confirmation modal
   */
  const handleCloseStatusSwitchConfirmationModal = () => {
    statusSwitchConfirmationModalRef.current.toggle();
  };

  const onEditUserClick = (userId: string) => {
    setUserIdSetToEdit(userId);
  };

  useEffect(() => {
    if (userIdSetToEdit) {
      setIsAddUserModalOpen(true);
    }
  }, [userIdSetToEdit]);

  const onCloseAddUserModal = () => {
    setIsAddUserModalOpen(false);
    setUserIdSetToEdit('');
  };

  if (!user) {
    return <FullPageSpinnner />;
  }

  const isAccessible = MENUS.find(
    (item: any) => item.name === 'Users'
  )?.access.includes(user.role as RoleType);

  if (!isAccessible) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="user-list">
      {/* Search */}
      <div className="mb-3 w-100 d-flex justify-content-between align-items-center">
        <Search performGenericSearch={performGenericSearch}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(applyFilters)}>
              {/* <AdvancedSearch
                togglerRef={
                  advancedSearchRef as React.MutableRefObject<HTMLButtonElement>
                }
                handleResetFilters={handleResetFilters}
              /> */}
            </form>
          </FormProvider>
        </Search>
        <div className="d-flex align-items-center">
          <MDBBtn
            color="link"
            className="refresh-button"
            onClick={handleRefresh}
          >
            <MDBIcon icon="redo-alt" className="text-muted fs-5" />
          </MDBBtn>
          {users?.length > 0 && (
            <PageLimitSelect
              limit={pagination.limit}
              handleLimitChange={handleLimitChange}
            ></PageLimitSelect>
          )}
          <MDBBtn
            color="secondary"
            type="button"
            className="text-dark ml-3"
            onClick={onAddUserClick}
          >
            <MDBIcon icon="plus" className="d-inline-block" />
            <span className="d-inline-block ml-2">Add</span>
          </MDBBtn>
        </div>
      </div>

      {/* Applied Filters */}
      {isFilterApplied && (
        <div className="mb-3 d-flex align-items-center">
          <p className="mb-0 text-muted">Filters: </p>
          {Object.keys(filters).map((filterKey: string) => {
            const filterValue = filters[filterKey as keyof UserFilterType];
            if (filterValue) {
              return (
                <div className="ml-2">
                  <Chip
                    text={`${convertCamelCaseToTitleCase(
                      filterKey
                    )}: ${filterValue}`}
                    onCloseHandler={() =>
                      removeFilter(filterKey as keyof UserFilterType)
                    }
                  />
                </div>
              );
            }
            return null;
          })}
        </div>
      )}

      {/* Result Count */}
      {users?.length > 0 && (
        <small className="text-muted d-inline-block mb-3">
          Showing {firstCount} - {lastCount} of {pagination.total}
        </small>
      )}

      {/* Table */}
      {!isLoading && (
        <MDBTable>
          <MDBTableHead>
            <tr className="table-header">
              <th scope="col" className="font-weight-bold">
                Name
              </th>
              <th scope="col" className="font-weight-bold">
                Address
              </th>
              <th scope="col" className="font-weight-bold">
                Contact
              </th>
              <th scope="col" className="font-weight-bold">
                Last Login Date
              </th>
              <th scope="col" className="font-weight-bold">
                Status
              </th>
              <th scope="col" className="font-weight-bold">
                Action
              </th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {!isLoading && users?.length === 0 && (
              <p className="text-muted mt-3">No users found.</p>
            )}
            {users?.map((user: UserType) => (
              <UserListItem
                key={user.id}
                user={user}
                onChangeStatus={onChangeStatus}
                onEditUserClick={onEditUserClick}
                handleUserDeleteSuccess={handleUserDeleteSuccess}
              />
            ))}
          </MDBTableBody>
        </MDBTable>
      )}

      {/* Loader */}
      {isLoading && <TableSkeletonLoader rows={3} cols={3} />}

      {/* Pagination */}
      {!isLoading && (
        <div className="d-flex justify-content-center mt-4">
          <Pagination
            pageLimit={pagination.limit}
            totalCount={pagination.total}
            currentPage={pagination.currentPage}
            pageNeighbours={pagination.pageNeighbours}
            onPageChangedHandler={handlePageChange}
          />
        </div>
      )}

      {/* Modal for adding/editing user */}
      <UserAddEditModal
        isOpen={isAddUserModalOpen}
        onClose={onCloseAddUserModal}
        userId={userIdSetToEdit}
        handleUserAddSuccess={handleUserAddSuccess}
        handleUserUpdateSuccess={handleUserUpdateSuccess}
      />

      {/* Confirmation Modal for user status change  */}
      <StatusSwitchConfirmationModal
        ref={statusSwitchConfirmationModalRef}
        user={userSetToChangeStatus?.user}
        newStatus={userSetToChangeStatus?.newStatus}
        handleUpdateUserStatus={handleUpdateUserStatus}
        handleCloseModal={handleCloseStatusSwitchConfirmationModal}
      />
    </div>
  );
};

export default UserList;
