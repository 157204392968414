import { uiRoutes } from 'constants/uiRoutes';
import { OrderStatus } from 'enums/order';
import { UserRoles } from 'enums/user';
import {
  MDBBtn,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBTooltip,
} from 'mdb-react-ui-kit';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IOrderTableRow } from 'types/order';
import { RoleType } from 'types/roles';
import { formatDate } from 'utils/date';
import { getFullName, hasActionAccess } from 'utils/npssUtil';
import {
  convertToTitleCase,
  convertToTitleCaseAfterFirstDash,
} from 'utils/string';
import OrdersCollapseRow from './OrdersCollapse';
import './ordersListItem.scss';

interface Props {
  data: IOrderTableRow;
  openPrescriptionModal: (
    dSpotPatientId: number,
    paymentStatus: string
  ) => void;
  onCreateShippingLabelClick: (orderDetail: any) => void;
  currentUserRole: RoleType;
}

const OrderDetailListItem = ({
  data,
  openPrescriptionModal,
  onCreateShippingLabelClick,
  currentUserRole,
}: Props) => {
  const history = useHistory();

  const patient = data?.patient;
  const patientId = data?.patient.id;
  const dSpotPatientId = patient?.dSpotPatientId;
  const orderId = data?.orderNumber;
  const orderDate = formatDate(data?.orderDate);

  const [showCollapse, setShowCollapse] = useState<boolean>(false);

  const drugOffering = data?.orderDetails?.[0].drugOffering;
  const drug = data?.orderDetails?.[0].drug;
  const paymentStatus = data?.paymentStatus;
  const orderStatus = data?.status;

  const isAddFulfillmentEnabled = orderStatus === OrderStatus.E_PRESCRIBED;
  const isViewShippingLabelEnabled =
    orderStatus === OrderStatus.READY_FOR_SHIPPING;
  const showShippingStatus = [
    OrderStatus.READY_FOR_SHIPPING,
    OrderStatus.IN_PROGRESS,
    OrderStatus.COMPLETED,
  ].includes(orderStatus as OrderStatus);

  const showCollapseToggle = () => setShowCollapse(!showCollapse);

  const onViewShippingLabelClick = () => {
    const url = data.orderShipment.shippoLabelUrl;
    if (url) {
      window.open(url, '_blank')?.focus();
    }
  };

  const viewPatientDetail = () => {
    history.push(
      uiRoutes.orders.patientDetailTabs.survey.replace(':patientId', patientId)
    );
  };

  return (
    <>
      <tr className="font-weight-normal" key={data.id}>
        <td className="orders-table-tiny-column">
          {showCollapse ? (
            <MDBBtn
              color="link"
              className="refresh-button rounded-circle"
              onClick={showCollapseToggle}
            >
              <MDBIcon icon="minus text-muted cursor-pointer" />
            </MDBBtn>
          ) : (
            <MDBBtn
              color="link"
              className="refresh-button rounded-circle"
              onClick={showCollapseToggle}
            >
              <MDBIcon icon="plus cursor-pointer" />
            </MDBBtn>
          )}
        </td>
        <td className="orders-table-small-column">
          {orderId}
          <br />
          <span className="text-sm text-muted">{orderDate}</span>
        </td>
        <td className="orders-table-big-column">
          <span className="font-weight-bold">
            {getFullName(
              patient?.firstName,
              patient?.lastName,
              patient?.middleName
            )}
          </span>
          <ul className="fa-ul ml-3 mb-0 font-size-caption text-muted">
            <li>
              <span className="fa-li">
                <i className="text-primary fas fa-birthday-cake opacity-50 "></i>
              </span>
              <span className="text-sm text-muted">
                {formatDate(patient?.dob)}
              </span>
              {patient?.gender && ` (${patient.gender})`}
            </li>
          </ul>
        </td>
        <td className="orders-table-small-column">
          {drug?.brandName}
          <br />
          <span className="text-sm text-muted">
            {drugOffering?.complaintType} (
            {data?.orderDetails[0]?.drugOffering?.name})
          </span>
        </td>
        <td className="orders-table-small-column">
          {data?.paymentStatus ? convertToTitleCase(data.paymentStatus) : '-'}
        </td>
        <td className="orders-table-small-column">
          {data?.status ? convertToTitleCaseAfterFirstDash(data.status) : '-'}
          {showShippingStatus &&
            data.orderShipment?.shippoTrackingStatus?.toUpperCase() !==
              'UNKNOWN' && (
              <span className="text-muted mr-1">
                {convertToTitleCase(data.orderShipment?.shippoTrackingStatus)}
              </span>
            )}
          {showShippingStatus && data.orderShipment?.shippoStatusDetail && (
            <MDBTooltip
              tag="a"
              wrapperProps={{ href: '#' }}
              title={data.orderShipment.shippoStatusDetail}
            >
              <MDBIcon fas icon="info-circle fa-fw text-muted cursor-pointer" />
            </MDBTooltip>
          )}
        </td>

        {hasActionAccess(currentUserRole) && (
          <td className="orders-table-tiny-column">
            <MDBDropdown>
              <MDBDropdownToggle
                color="link"
                size="sm"
                className="m-0 mdb-dropdown-without-arrow rounded-circle"
              >
                <MDBIcon icon="ellipsis-v text-dark" />
              </MDBDropdownToggle>
              <MDBDropdownMenu right basic className="shadow-4 rounded-2">
                <MDBDropdownItem onClick={viewPatientDetail}>
                  <MDBDropdownLink className="cursor-pointer">
                    <MDBIcon fas icon="eye fas text-muted" /> View patient
                    detail
                  </MDBDropdownLink>
                </MDBDropdownItem>
                {currentUserRole === UserRoles.PROVIDER && (
                  <MDBDropdownItem
                    onClick={() =>
                      openPrescriptionModal(dSpotPatientId, paymentStatus)
                    }
                  >
                    <MDBDropdownLink className="cursor-pointer">
                      <MDBIcon fas icon="prescription fa-fw text-muted" /> Add
                      prescription
                    </MDBDropdownLink>
                  </MDBDropdownItem>
                )}

                {isAddFulfillmentEnabled && (
                  <MDBDropdownItem
                    onClick={() => onCreateShippingLabelClick(data)}
                  >
                    <MDBDropdownLink className="cursor-pointer">
                      <MDBIcon fas icon="plus fa-fw text-muted" /> Create
                      Shipping Label
                    </MDBDropdownLink>
                  </MDBDropdownItem>
                )}
                {isViewShippingLabelEnabled && (
                  <MDBDropdownItem onClick={() => onViewShippingLabelClick()}>
                    <MDBDropdownLink className="cursor-pointer">
                      <MDBIcon fas icon="eye fa-fw text-muted" /> View Shipping
                      Label
                    </MDBDropdownLink>
                  </MDBDropdownItem>
                )}
              </MDBDropdownMenu>
            </MDBDropdown>
          </td>
        )}
      </tr>
      {showCollapse ? (
        <OrdersCollapseRow orderDetail={data} showCollapse={showCollapse} />
      ) : null}
    </>
  );
};

export default OrderDetailListItem;
