import { uiRoutes } from 'constants/uiRoutes';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { getAccessToken } from 'utils/auth';
import { isTokenExpired } from 'utils/jwt';
import { clear } from 'utils/storage';

interface IProps {
  children: JSX.Element;
}

const AppWrapper = ({ children }: IProps) => {
  const token: string = getAccessToken() || '';
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      return;
    }

    if (isTokenExpired(token)) {
      toast.error('Your token has expired !!!', {
        position: 'top-center',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      clear('local');
      history.push(uiRoutes.logout);
      return;
    }
  }, [history, token]);
  return <div>{children}</div>;
};

export default AppWrapper;
