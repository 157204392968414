import _ from 'lodash';
import debounce from 'lodash/debounce';

export const _debounce = (func: (...args: any) => any, wait: number) => {
  return debounce(func, wait);
};

const isSupported = (value: any) => {
  const typeIsNotSupported =
    // use this condition if you want to remove null values
    // !_.isNull(value) &&
    !_.isString(value) && !_.isArray(value) && !_.isPlainObject(value);
  // use this return statement if you want to remove empty string ('') as well along with null value
  // return typeIsNotSupported || !_.isEmpty(value);
  return typeIsNotSupported || !(!_.isString(value) && _.isEmpty(value));
};

export const sanitizeString = (string: string) =>
  _.isEmpty(string) ? '' : string.trim();

/**
 * Trim strings in plain string, plain object, nested object, array, array of objects
 *
 * @param {any} object
 * @returns
 */
export const sanitize = (object: any) => {
  if (_.isString(object)) return sanitizeString(object);
  // Note: Disabled this rule because `sanitize` is also used inside `sanitizeArray`
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  if (_.isArray(object)) return sanitizeArray(object);
  // Note: Disabled this rule because `sanitize` is also used inside `sanitizeObject`
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  if (_.isPlainObject(object)) return sanitizeObject(object);
  return object;
};

export const sanitizeArray = (array: any): any =>
  _.filter(_.map(array, sanitize), isSupported);
export const sanitizeObject = (object: any): any =>
  _.pickBy(_.mapValues(object, sanitize), isSupported);
