export enum AddressType {
  SHIPPING = 'Shipping',
  BILLING = 'Billing',
}

export enum Tabs {
  DOCUMENTS = 'documents',
  MESSAGES = 'messages',
  SURVEY = 'survey',
}

export enum NotificationDurationLabel {
  Today = 'today',
  YESTERDAY = 'yesterday',
  OLDER = 'older',
}
