// import Logo from 'assets/images/png/matrx-logo.png';
import Logo from 'assets/images/svg/matrxlogo.svg';
import React from 'react';
import './authLayout.scss';

interface Props {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: Props) => {
  return (
    <div className="auth-layout pb-5">
      <img src={Logo} alt="logo" />
      <div className="auth-layout__card shadow-2 p-5 rounded-1">{children}</div>
    </div>
  );
};

export default AuthLayout;
