export enum PatientStatusType {
  //New Stage
  NEW = 'New',
  //Incomplete Stage
  UNVERIFIED = 'Unverified',
  VERIFIED = 'Verified',
  IN_PROGRESS = 'In Progress',
  //Complete Stage
  HIGH_RISK = 'High Risk',
  REGISTERED = 'Registered',
}
