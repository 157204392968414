/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatNotificationResponse } from 'services/notification';
import { RootState } from 'stores';
import { IListResponse } from 'types/http';
import { INotification, INotificationData } from 'types/notification';

interface NotificationState {
  loadMore: boolean;
  count: number;
  allNotifications: INotification[] | [];
  notifications: INotificationData[] | [];
  initialNotification: INotificationData[] | [];
}

const initialState: NotificationState = {
  loadMore: false,
  count: 0,
  allNotifications: [],
  notifications: [],
  initialNotification: [],
};

export const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    setNotification: (
      state,
      action: PayloadAction<IListResponse<INotification> | any> // TODO: will change any later
    ) => {
      if (!state.initialNotification.length) {
        state.initialNotification = formatNotificationResponse(
          action.payload.rows ?? []
        );
      }
      state.notifications = formatNotificationResponse(
        action.payload.rows ?? []
      );
      state.allNotifications = action.payload.rows ?? [];
      state.count = action.payload.count;
    },
    subtractNotificationCount: (state) => {
      state.count = state.count > 0 ? state.count - 1 : state.count;
    },
  },
});

// Actions
export const { setNotification, subtractNotificationCount } = slice.actions;

// Selectors
export const selectNotifications = (state: RootState) =>
  state.notification.notifications;
export const selectAllNotifications = (state: RootState) =>
  state.notification.allNotifications;
export const selectAllNotificationsCount = (state: RootState) =>
  state.notification.count;
export const selectInitialNotifications = (state: RootState) =>
  state.notification.initialNotification;

// Reducer
export default slice.reducer;
