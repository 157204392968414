import { uiRoutes } from 'constants/uiRoutes';
import { PatientStatusType } from 'enums/patient';
import { useHistory } from 'react-router';
import { PatientListItemType } from 'types/patient';
import { getFullName } from 'utils/common';
import { formatDate } from 'utils/date';
import { formatPhone } from 'utils/npssUtil';
import './patientListItem.scss';

interface Props {
  patient: PatientListItemType;
  // handleChangePatientStatus: (
  //   patientId: string,
  //   newStatus: MDBSelectEvent,
  //   oldStatusId: string
  // ) => void;
  // statusUpdatingId: string;
}

const { UNVERIFIED, VERIFIED, IN_PROGRESS } = PatientStatusType;

const PatientListItem = ({
  patient,
}: // handleChangePatientStatus,
// statusUpdatingId,
Props) => {
  const history = useHistory();
  const patientFullName = getFullName(
    patient?.firstName,
    patient?.lastName,
    patient?.middleName
  );

  const onClick = () => {
    history.push(uiRoutes.patient.tab.survey.replace(':patientId', patient.id));
  };

  return (
    <tr className="patient-list-item font-weight-normal" key={patient.id}>
      <td className="patients-table-big-column">
        <span className="font-weight-bold">{patientFullName}</span>
        <ul className="fa-ul ml-3 mb-0 font-size-caption text-muted">
          <li>
            <span className="fa-li">
              <i className="text-primary fas fa-birthday-cake opacity-50 "></i>
            </span>
            <span className="text-sm text-muted">
              {formatDate(patient.dob)}
            </span>
            <span className="text-sm text-muted ml-1">
              {patient.gender ? `(${patient.gender})` : ''}
            </span>
          </li>
        </ul>
      </td>
      <td className="patients-table-small-column">
        <ul className="fa-ul ml-4 mb-0 ">
          <li>
            <span className="fa-li">
              <i className="text-primary fas fa-envelope opacity-50"></i>
            </span>
            {patient.email}
          </li>
          <li>
            <span className="fa-li">
              <i className="text-primary fas fa-phone-alt opacity-50"></i>
            </span>
            <span className="text-sm text-muted">
              {formatPhone(patient.phone)}
            </span>
          </li>
        </ul>
      </td>
      <td className="patients-table-tiny-column">
        {/* <MDBSelect
          className="patient-list-item__status-select"
          key={`${patient.id}-${patientStatusOptions.length}`}
          data={patientStatusOptions.map((status) => ({
            ...status,
            selected: status.value === patient?.status?.id,
          }))}
          getValue={(e: { text: string; value: string }) =>
            e && handleChangePatientStatus(patient.id, e, patient.status.id)
          }
          disabled={statusUpdatingId === patient.id}
        /> */}
        {/* {patient.status} */}
        {/* <MDBBtn style={{ backgroundColor: 'red' }}> */}

        {[UNVERIFIED, VERIFIED, IN_PROGRESS].includes(
          patient?.status as PatientStatusType
        )
          ? 'Incomplete'
          : patient?.status}
        {/* </MDBBtn> */}
      </td>

      <td className="patients-table-tiny-column">
        {patient.source ? patient.source?.toUpperCase() : '-'}
        <br />
        <span className="text-sm text-muted">
          {patient.createdAt ? formatDate(patient.createdAt) : '-'}
        </span>
      </td>
      <td className="patients-table-tiny-column">
        <div className="view-detail" onClick={onClick}>
          View Detail
        </div>
      </td>
    </tr>
  );
};

export default PatientListItem;
