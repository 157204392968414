import { USER_ROLES } from 'constants/userRoles';
import { UserStatus } from 'enums/user';
import {
  MDBBadge,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBSwitch,
} from 'mdb-react-ui-kit';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { deleteUser, inviteUser } from 'services/user';
import { useConfirmationModal } from 'stores/ConfirmationModal';
import { UserType } from 'types/user';
import { formatDate, formatDateTime } from 'utils/date';
import {
  formatPhone,
  getAddressDisplayName,
  getFullName,
} from 'utils/npssUtil';

interface Props {
  user: UserType;
  onChangeStatus: (userId: string, newStatus: boolean) => void;
  onEditUserClick: (userId: string) => void;
  handleUserDeleteSuccess: (userId: string) => void;
}

const UserListItem = ({
  user,
  onChangeStatus,
  onEditUserClick,
  handleUserDeleteSuccess,
}: Props) => {
  const deleteConfirmationModal = useConfirmationModal();

  const userFullName = getFullName(
    user.firstName,
    user.lastName,
    user.middleName
  );
  const userAddress = {
    addressLine1: user?.addressLine1,
    addressLine2: user?.addressLine2,
    city: user?.city,
    state: user?.state,
    zipCode: user?.zipCode,
  };

  const [isSubmitting, setIsSubmitting] = useState({
    changeStatus: false,
    inviteUser: false,
  });

  /**
   * Send invitation to the user
   */
  const onInviteUser = async () => {
    setIsSubmitting((prevState) => ({ ...prevState, inviteUser: true }));
    try {
      const res: any = await inviteUser(user.id);
      toast.success(res.message);
    } catch (error) {
      toast.error((error as any).response?.data?.message);
    } finally {
      setIsSubmitting((prevState) => ({ ...prevState, inviteUser: false }));
    }
  };

  const onDeleteUserClick = async () => {
    const result = await deleteConfirmationModal?.openConfirmationModal({
      title: `Delete User: ${userFullName}`,
      content: 'Are you sure you want to delete this user?',
    });

    if (result) {
      deleteConfirmationModal?.changeSubmittingStatus(true);
      try {
        const res: any = await deleteUser(user.id);
        handleUserDeleteSuccess(user.id);
        deleteConfirmationModal?.changeSubmittingStatus(false);
        toast.success(res.message);
      } catch (error) {
        toast.error((error as any).response?.data?.message);
      } finally {
        setIsSubmitting((prevState) => ({ ...prevState, inviteUser: false }));
      }
    }
  };

  return (
    <tr className="font-weight-normal" key={user.id}>
      <td>
        <span className="font-weight-bold">{userFullName}</span>
        {user.dob && (
          <ul className="fa-ul ml-3 mb-0 font-size-caption text-muted">
            <li>
              <span className="fa-li">
                <i className="text-primary fas fa-birthday-cake opacity-50 "></i>
              </span>
              {formatDate(user.dob)}
            </li>
          </ul>
        )}
        {user.role && (
          <div>
            <MDBBadge color="primary">{user.role}</MDBBadge>
          </div>
        )}
      </td>
      <td>
        <ul className="fa-ul ml-4 mb-0">
          <li>
            <span className="fa-li">
              <i className="text-primary fas fa-map-marker-alt opacity-50"></i>
            </span>
            {getAddressDisplayName(userAddress)}
          </li>
        </ul>
      </td>
      <td>
        <ul className="fa-ul ml-4 mb-0">
          <li>
            <span className="fa-li">
              <i className="text-primary fas fa-envelope opacity-50"></i>
            </span>
            {user.email}
          </li>
          <li>
            <span className="fa-li">
              <i className="text-primary fas fa-phone-alt opacity-50"></i>
            </span>
            {formatPhone(user.phone)}
          </li>
        </ul>
      </td>
      <td> {formatDateTime(user.lastLoginDate)}</td>
      <td>
        <MDBSwitch
          id={`user-status-switch-${user.id}`}
          label=""
          checked={user.status === UserStatus.ACTIVE}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChangeStatus(user.id, e.target.checked)
          }
          disabled={user.status !== UserStatus.ACTIVE ? true : false}
        />
      </td>
      <td>
        <MDBDropdown>
          <MDBDropdownToggle
            color="link"
            size="sm"
            className="m-0 mdb-dropdown-without-arrow rounded-circle"
            disabled={isSubmitting.changeStatus || isSubmitting.inviteUser}
          >
            <MDBIcon icon="ellipsis-v text-dark" />
          </MDBDropdownToggle>
          <MDBDropdownMenu right basic className="shadow-4 rounded-2">
            <MDBDropdownItem onClick={() => onEditUserClick(user.id)}>
              <MDBDropdownLink className="cursor-pointer">
                <MDBIcon far icon="edit fa-fs text-muted" /> Edit
              </MDBDropdownLink>
            </MDBDropdownItem>
            {user.status !== UserStatus.ACTIVE && (
              <MDBDropdownItem onClick={onInviteUser}>
                <MDBDropdownLink className="cursor-pointer">
                  <MDBIcon far icon="envelope fa-fw text-muted" />
                  {!user.isUserActivated
                    ? ' Send Invitation'
                    : ' Resend Invitation'}
                </MDBDropdownLink>
              </MDBDropdownItem>
            )}
            {user.role !== USER_ROLES.SUPER_ADMIN && (
              <MDBDropdownItem onClick={onDeleteUserClick}>
                <MDBDropdownLink className="cursor-pointer">
                  <MDBIcon far icon="trash-alt fa-fw text-muted" /> Delete
                </MDBDropdownLink>
              </MDBDropdownItem>
            )}
          </MDBDropdownMenu>
        </MDBDropdown>
      </td>
    </tr>
  );
};

export default UserListItem;
