import AlwaysScrollToBottom from 'components/common/AlwaysScrollToBottom';
import { CustomAvatar } from 'components/common/avatar';
import { memo, useContext, useEffect, useState } from 'react';
import { store } from 'store';
import colors from 'styles/constants/colors';
import { AppContext } from 'types/appContext';
import { IMessage } from 'types/message';
import { getFullDateFormat } from 'utils/date';
import { getFirstLastName, getFullName } from 'utils/npssUtil';
import LoadingIndicator from '../../common/LoadingIndicator';

interface IProps {
  message: IMessage;
  userId: string;
  showCreatedDate: boolean;
  enableBottomScroll?: boolean;
}

const MessageCard = ({
  message,
  userId,
  showCreatedDate,
  enableBottomScroll,
}: IProps) => {
  const appContext = useContext(store);
  const { user } = appContext as AppContext;
  const [type, setType] = useState<string>('to');

  useEffect(() => {
    if (userId) {
      setType(userId === message?.createdBy ? 'to' : 'from');
    }
  }, [userId, message]);

  if (!userId) return <LoadingIndicator />;

  return (
    <div className="message-card">
      <div className="text-center">
        {showCreatedDate && (
          <p style={{ color: colors.neutral.default, fontSize: 13 }}>
            {getFullDateFormat(message.createdAt)}
          </p>
        )}
      </div>
      <div
        className={`d-flex ${
          type === 'to' ? 'justify-content-start' : 'justify-content-end'
        }`}
      >
        {type === 'to' && (
          <CustomAvatar
            firstName={message?.createdByUser?.firstName}
            lastName={message?.createdByUser?.lastName}
          />
        )}
        <div className="d-flex flex-row align-items-start">
          <div className="ms-3 me-3">
            <div
              style={{
                backgroundColor:
                  type === 'from'
                    ? colors.neutral.lighter
                    : colors.primary.lighter,
                border: `1px solid ${colors.primary.light}`,
                overflow: 'auto',
                borderRadius: 4,
                whiteSpace: 'pre-wrap',
              }}
              className="p-2 px-2 d-flex align-items-center text-align-center"
            >
              <p
                className="p-0 m-0"
                style={{
                  color: colors.neutral.default,
                  fontSize: 15,
                }}
              >
                {message.message}
              </p>
            </div>
            <p
              className="mt-1"
              style={{
                color: colors.neutral.default,
                fontSize: 13,
                textAlign: type === 'from' ? 'end' : 'start',
              }}
            >
              Sent by{' '}
              {getFullName(
                message?.createdByUser?.firstName,
                message?.createdByUser?.lastName,
                message?.createdByUser?.middleName ?? ''
              )}
            </p>
          </div>
          {type === 'from' && <CustomAvatar {...getFirstLastName(user.name)} />}
        </div>
        {enableBottomScroll && <AlwaysScrollToBottom />}
      </div>
    </div>
  );
};

export default memo(MessageCard);
