import config from 'config';
import http from 'utils/http';

export async function approveOrder(orderId: string) {
  const users = await http({
    url: config.endpoints.orders.approve.replace(':id', orderId),
    method: 'POST',
  });
  return users.data;
}

export async function fetchOrders(queryParams?: any) {
  const users = await http({
    url: config.endpoints.orders.getAll,
    method: 'GET',
    params: queryParams,
  });
  return users.data;
}
