import { LOG_EVENT_TYPE, LOG_SEVERITY_TYPE, LOG_TYPE } from 'constants/index';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { saveActivityLogs } from 'services/activityLog';
import { store } from 'store';
import { AppContext } from 'types/appContext';
import { clear } from 'utils/storage';

const LOGOUT_ACTIVITY_LOG_SOURCE = 'ADMIN';

interface Props {}

const Logout = (props: Props) => {
  const history = useHistory();

  const appContext = useContext(store);
  const { user } = appContext as AppContext;

  useEffect(() => {
    const data = {
      user: {
        id: user?.id,
        name: user?.name,
      },
      event: {
        type: LOG_EVENT_TYPE.LOGOUT,
      },
      log: {
        module: 'User',
        note: `Logged out from the system.`,
        type: LOG_TYPE.INFO,
      },
      additionalInfo: {},
      severity: LOG_SEVERITY_TYPE.MEDIUM,
      status: 'Active',
    };

    const saveLogoutActivityLog = async () => {
      await saveActivityLogs(data, { source: LOGOUT_ACTIVITY_LOG_SOURCE });
    };

    saveLogoutActivityLog();
    clear('local');
    history.push('/login');
  }, [history, user?.id, user?.name]);

  return (
    <div>
      <p>Logging out...</p>
    </div>
  );
};

export default Logout;
