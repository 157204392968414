import http from 'utils/http';
import * as interceptors from 'utils/interceptors';

/**
 * Initialize interceptors for the application.
 */
function initInterceptors() {
  http.interceptors.request.use(interceptors.requestInterceptor);
  http.interceptors.response.use(
    (response) => response,
    interceptors.responseInterceptor
  );
}

export default function init() {
  initInterceptors();
}
