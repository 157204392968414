import { uiRoutes } from 'constants/uiRoutes';
import useAPIService from 'hooks/useApiService';
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from 'mdb-react-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getNotification,
  getUnreadNotificationCount,
} from 'services/notification';
import { useAppDispatch } from 'stores/hooks';
import {
  // selectAllNotificationsCount,
  setNotification,
} from 'stores/patient/notification';
import { IListResponse } from 'types/http';
import { INotification } from 'types/notification';
import NotificationContainer from './NotificationContainer';
import './notificationDropdown.scss';

const NotificationMessages = () => {
  const { loading, data } = useAPIService<IListResponse<INotification>>({
    api: useCallback(() => getNotification(), []),
  });
  const [unreadNotificationCount, setUnreadNotificationCount] = useState();
  const history = useHistory();
  const dispatch = useAppDispatch();
  // const notificationsCount = useAppSelector(selectAllNotificationsCount);

  // TODO:: this method will change later

  useEffect(() => {
    if (data?.data.count) {
      dispatch(setNotification(data.data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    getUnreadNotificationCount().then((res) =>
      setUnreadNotificationCount(res.data.count)
    );
  }, []);

  return (
    <MDBDropdown
      group
      className="notification-messages-dropdown shadow-0"
      options={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-450, 10],
            },
          },
        ],
      }}
    >
      <MDBDropdownToggle
        color="link"
        className="notificationToggle d-flex mdb-dropdown-without-arrow rounded-circle"
      >
        {loading ? null : (
          <>
            <i className="fas fa-comment-alt fa-lg"></i>
            <span className="badge rounded-pill badge-notification bg-danger">
              {/* {notificationsCount} */}
              {unreadNotificationCount}
            </span>
          </>
        )}
      </MDBDropdownToggle>
      <MDBDropdownMenu className="shadow-4">
        <NotificationContainer notificatioPopup>
          <div onClick={() => history.push(uiRoutes.notifications)}>
            <p className="text-center pb-2 cursor-pointer mt-2 mb-1 text-muted">
              View all
            </p>
          </div>
        </NotificationContainer>
      </MDBDropdownMenu>
    </MDBDropdown>
  );
};

export default NotificationMessages;
