import GoogleAutocomplete from 'components/common/google-autocomplete';
import { MDBCol, MDBInput, MDBRow, MDBSelect } from 'mdb-react-ui-kit';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { store } from 'store';
import { AppContext } from 'types/appContext';
import { MDBSelectEvent } from 'types/mdb';
import { calculateAge } from 'utils/date';
import { validateDate, validateEmail } from 'utils/validation';

interface Props {}

const UserForm = (props: Props) => {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();

  const appContext = useContext(store);
  const { userRoleOptions } = appContext as AppContext;

  return (
    <>
      <MDBRow>
        <MDBCol size="5">
          <Controller
            control={control}
            name="firstName"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <MDBInput
                value={value}
                onChange={onChange}
                label="First Name *"
                id="first-name"
                type="text"
                className={`mb-3 ${errors.firstName ? 'is-invalid' : ''}`}
                invalid={!!errors.firstName}
              />
            )}
          />
        </MDBCol>
        <MDBCol size="2">
          <Controller
            control={control}
            name="middleName"
            render={({ field: { value, onChange } }) => (
              <MDBInput
                value={value}
                onChange={onChange}
                label="Middle"
                id="middle-name"
                type="text"
                className="mb-3"
              />
            )}
          />
        </MDBCol>
        <MDBCol size="5">
          <Controller
            control={control}
            name="lastName"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <MDBInput
                value={value}
                onChange={onChange}
                label="Last Name *"
                id="last-name"
                type="text"
                className={`mb-3 ${errors.lastName ? 'is-invalid' : ''}`}
                invalid={!!errors.lastName}
              />
            )}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="6">
          <Controller
            control={control}
            name="email"
            rules={{
              required: true,
              validate: (value) => {
                if (validateEmail(value)) return true;
                return false;
              },
            }}
            render={({ field: { value, onChange } }) => (
              <MDBInput
                value={value}
                onChange={onChange}
                label="Email *"
                id="email"
                type="text"
                className={`mb-3 ${errors.email ? 'is-invalid' : ''}`}
                invalid={!!errors.email}
              />
            )}
          />
        </MDBCol>
        <MDBCol size="6">
          <Controller
            control={control}
            name="role"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <MDBSelect
                // A unique key so that the new select is mounted when the form is reset
                key={`${getValues('role')}-${userRoleOptions?.length}` || ''}
                data={userRoleOptions?.map((option) => ({
                  ...option,
                  selected: option.value === value,
                }))}
                getValue={(e: MDBSelectEvent) => e && onChange(e.value)}
                label="Role *"
                className={`mb-3 ${errors.role ? 'is-invalid' : ''}`}
                invalid={!!errors.role}
                validation
                invalidFeedback="Invalid value"
              />
            )}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="6">
          <Controller
            rules={{
              required: false,
              validate: (value) => {
                if (!value) return true;
                if (value.includes('_')) return false;
                return true;
              },
            }}
            render={({ field }) => (
              <NumberFormat
                {...field}
                format="(###) ###-####"
                mask="_"
                placeholder="(XXX) XXX-XXXX"
                label="Phone"
                customInput={MDBInput}
                className={`mb-3 ${errors.phone ? 'is-invalid' : ''}`}
                invalid={!!errors.phone}
              />
            )}
            name="phone"
            control={control}
          />
        </MDBCol>
        <MDBCol size="6">
          <Controller
            rules={{
              required: false,
              validate: (value) => {
                if (!value) return true;
                if (!validateDate(value)) return false;
                const age = calculateAge(value);
                if (age < 1) return false;
                if (age > 100) return false;
                return true;
              },
            }}
            render={({ field }) => (
              <NumberFormat
                {...field}
                format="##/##/####"
                mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
                placeholder="MM/DD/YYYY"
                label="Date of Birth"
                customInput={MDBInput}
                className={`mb-3 ${errors.dob ? 'is-invalid' : ''}`}
                invalid={!!errors.dob}
              />
            )}
            name="dob"
            control={control}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="6">
          <GoogleAutocomplete />
        </MDBCol>
        <MDBCol size="6">
          <Controller
            control={control}
            name="addressLine2"
            render={({ field: { value, onChange } }) => (
              <MDBInput
                value={value}
                onChange={onChange}
                label="Address Line 2"
                id="address-line-2"
                type="text"
                className="mb-3"
              />
            )}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="6">
          <Controller
            control={control}
            name="city"
            render={({ field: { value, onChange } }) => (
              <MDBInput
                value={value}
                onChange={onChange}
                label="City"
                id="city"
                type="text"
                className="mb-3"
              />
            )}
          />
        </MDBCol>
        <MDBCol size="3">
          <Controller
            control={control}
            name="state"
            render={({ field: { value, onChange } }) => (
              <MDBInput
                value={value}
                onChange={onChange}
                label="State"
                id="state"
                type="text"
                className="mb-3"
              />
            )}
          />
        </MDBCol>
        <MDBCol size="3">
          <Controller
            control={control}
            name="zipCode"
            rules={{
              required: false,
              validate: (value) => {
                if (!value) return true;
                if (value.includes('_')) return false;
                return true;
              },
            }}
            render={({ field }) => (
              <NumberFormat
                {...field}
                format="#####"
                mask="_"
                placeholder="_____"
                label="Zip"
                customInput={MDBInput}
                className={`mb-3 ${errors.zipCode ? 'is-invalid' : ''}`}
                invalid={!!errors.zipCode}
              />
            )}
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default UserForm;
