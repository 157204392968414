import ModalWithRef from 'components/common/modal/ModalWithRef';
import config from 'config';
import { MDBModalBody, MDBSpinner } from 'mdb-react-ui-kit';
import React, { useState } from 'react';
import { EncryptedIdsType } from 'types/dosespot';

interface Props {
  encryptedIds: EncryptedIdsType;
  patientId: number | null;
}

const PrescriptionModal = React.forwardRef((props: Props, ref) => {
  const { encryptedIds, patientId } = props;
  const { encryptedClinicId, encryptedUserId } = encryptedIds;

  const [isIFrameLoaded, setIsIFrameLoaded] = useState(false);

  // Clinicid and ClinicianId (UserId) from env
  const { clinicId } = config.DOSESPOT;
  const { clinicianId } = config.DOSESPOT;

  const iframeSrcWithPatientId = `https://my.staging.dosespot.com/LoginSingleSignOn.aspx?SingleSignOnClinicId=${clinicId}&SingleSignOnUserId=${clinicianId}&SingleSignOnPhraseLength=32&SingleSignOnCode=${encryptedClinicId}&SingleSignOnUserIdVerify=${encryptedUserId}&PatientID=${patientId?.toString()}`;

  const onIFrameLoad = () => {
    setIsIFrameLoaded(true);
  };

  const renderedLoader = (
    <div className="d-flex h-100 justify-content-center align-items-center text-muted">
      <MDBSpinner color="primary">
        <span className="visually-hidden">Loading...</span>
      </MDBSpinner>
    </div>
  );

  return (
    <ModalWithRef
      ref={ref}
      title="Add Prescription"
      icon="prescription"
      size="fullscreen"
    >
      <MDBModalBody>
        {patientId ? (
          <>
            {!isIFrameLoaded && renderedLoader}
            <iframe
              src={iframeSrcWithPatientId}
              onLoad={onIFrameLoad}
              width="100%"
              height="1400px"
              title="DoseSpot iframe"
            />
          </>
        ) : (
          renderedLoader
        )}
      </MDBModalBody>
    </ModalWithRef>
  );
});

export default PrescriptionModal;
