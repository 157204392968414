import React from 'react';
import './avatar.scss';

const CustomAvatar = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}) => {
  const firstChar = firstName?.charAt(0);
  const lastChar = lastName?.charAt(0);
  return (
    <span className="d-flex justify-content-center align-items-center avatar">
      {firstChar}
      {lastChar}
    </span>
  );
};

export { CustomAvatar };
