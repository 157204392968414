import Chip from 'components/common/chip';
import FullPageSpinnner from 'components/common/full-page-spinner';
import PageLimitSelect from 'components/common/page-limit-select';
import Pagination from 'components/common/pagination';
import Search from 'components/common/search';
import TableSkeletonLoader from 'components/common/skeleton-loader/TableSkeletonLoader';
import PatientListItem from 'components/patients/PatientListItem';
import { MENUS } from 'constants/menus';
import {
  MDBBtn,
  MDBIcon,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdb-react-ui-kit';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { fetchPatients } from 'services/patient';
import { store } from 'store';
import { AppContext } from 'types/appContext';
import { PatientFilterType, PatientListItemType } from 'types/patient';
import { RoleType } from 'types/roles';
import { convertCamelCaseToTitleCase } from 'utils/string';
// import './patientTable.scss';

const defaultValues = {
  source: '',
  firstName: '',
  lastName: '',
  email: '',
  dob: '',
};

interface Props {}

const PatientList = (props: Props) => {
  const appContext = useContext(store);
  // const { patientStatus, user } = appContext as AppContext;
  const { user } = appContext as AppContext;

  const methods = useForm<PatientFilterType>({ defaultValues });

  /** Reference to the advanced search popover toggler button.
   *  Used to close the advanced search popover when the `Apply` button is clicked.
   */
  const advancedSearchRef = useRef<HTMLButtonElement>();

  /** Handles loading state for the component */
  const [isLoading, setIsLoading] = useState<boolean>(true);

  /** List of patients fetched */
  const [patients, setPatients] = useState<PatientListItemType[]>([]);

  /** Stores the search keyword (for generic search) */
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  /** Stores the filters (for advanced search) */
  const [filters, setFilters] = useState<PatientFilterType>(defaultValues);

  /** Pagination */
  const [pagination, setPagination] = useState<any>({
    currentPage: 1,
    pageNeighbours: 2,
    limit: 10,
    total: 0,
    offset: 0,
  });

  /** Toggle variable for refresh. The fetch is performed when the value is toggled */
  const [refreshFlagToggle, setRefreshFlagToggle] = useState<boolean>(false);

  /** Whether the patient status update is in progress or not.
   *  Holds the id of patient whose update is in progress
   */
  // const [statusUpdatingId, setStatusUpdatingId] = useState('');

  // for showing 11-20 of 40 (11 -> firstCount, 20-> -> lastCount)
  const firstCount = (pagination.currentPage - 1) * pagination.limit + 1;
  const lastCount =
    (pagination.currentPage - 1) * pagination.limit + patients?.length;

  /**
   * Side effect responsible for fetching patients with required query params
   */
  useEffect(() => {
    const chooseFilterParams = () => {
      let filterParams: any = {};
      if (filters.source) filterParams.source = filters.source;
      if (filters.firstName) filterParams.firstName = filters.firstName;
      if (filters.lastName) filterParams.lastName = filters.lastName;
      if (filters.email) filterParams.email = filters.email;
      if (filters.dob) filterParams.dob = filters.dob;
      return filterParams;
    };

    const getPatients = async () => {
      setIsLoading(true);
      let queryParams: any = {
        offset: pagination.offset,
        limit: pagination.limit,
      };
      if (searchKeyword) queryParams.keyword = searchKeyword;
      const filterParams = chooseFilterParams();
      queryParams = { ...queryParams, ...filterParams };
      const response: any = await fetchPatients(queryParams);
      setPatients(response?.data?.rows);
      setPagination((prevState: any) => ({
        ...prevState,
        total: response.data.count,
      }));
      setIsLoading(false);
    };
    getPatients();
  }, [
    pagination.currentPage,
    pagination.offset,
    pagination.limit,
    searchKeyword,
    filters,
    refreshFlagToggle,
  ]);

  /**
   * Page change handler. Updates the offset according to new page.
   *
   * @param {number} currentPage
   */
  const handlePageChange = (currentPage: number) => {
    const pageOffset = (currentPage - 1) * pagination.limit;
    setPagination((prevState: any) => ({
      ...prevState,
      offset: pageOffset,
      currentPage: currentPage,
    }));
  };

  /**
   * Limit change handler. Updates the limit, offset and currentPage according to the new limit
   *
   * @param {number} newLimit
   */
  const handleLimitChange = (newLimit: number) => {
    if (pagination.limit === newLimit) return;
    setPagination((prevState: any) => ({
      ...prevState,
      offset: 0,
      currentPage: 1,
      limit: newLimit,
    }));
  };

  /**
   * Handles search by keyword
   *
   * @param {string} keyword
   */
  const performGenericSearch = (keyword: string) => {
    setSearchKeyword(keyword);
    handlePageChange(1); // need to start from page 1 when there is a search
  };

  /**
   * Handles refresh. Toggles the `refreshFlagToggle` variable.
   */
  const handleRefresh = () => {
    setRefreshFlagToggle((prevState) => !prevState);
  };

  /**
   * Closes the popover used for advanced search
   * Achieves the action by triggering the click event of popover toggler btn with ref
   */
  const closeAdvancedSearchPopover = (): void => {
    typeof advancedSearchRef.current?.click === 'function' &&
      advancedSearchRef.current.click();
  };

  /**
   * Resets the filters. Also, the RHF form values are reset.
   */
  // const handleResetFilters = () => {
  //   methods.reset(defaultValues);
  //   setFilters(defaultValues);
  // };

  /**
   * Form submit handler for Advanced search form.
   * Sets the filters with new filters and the side effect fetches the new patient list
   *
   * @param {PatientFilterType} data
   */
  const applyFilters = (data: PatientFilterType) => {
    setFilters(data);
    handlePageChange(1); // need to start from page 1 when there is a search
    closeAdvancedSearchPopover();
  };

  /**
   * Handles removal of applied filter
   * RHF form value of the filter is also reset (set to '')
   *
   * @param {keyof PatientFilterType} filterKey
   */
  const removeFilter = (filterKey: keyof PatientFilterType) => {
    methods.setValue(filterKey, '');
    setFilters((prevState) => ({ ...prevState, [filterKey]: '' }));
  };

  /**
   * Variable to check if any advanced filters is applied or not.
   * The applied filters section is shown based on this variable.
   *
   */
  const isFilterApplied = Object.keys(filters)?.some(
    (filterKey) => filters[filterKey as keyof PatientFilterType] !== ''
  );

  // /**
  //  * Change patient status
  //  *
  //  * @param {string} patientId
  //  * @param {string} newStatusId
  //  * @param {string} oldStatusId
  //  */
  // const handleChangePatientStatus = async (
  //   patientId: string,
  //   newStatus: MDBSelectEvent,
  //   oldStatusId: string
  // ) => {
  //   if (newStatus.value === oldStatusId) return;
  //   try {
  //     const newStatusObj = {
  //       id: newStatus.value,
  //       name: newStatus.text?.toUpperCase(),
  //     };
  //     setStatusUpdatingId(patientId);
  //     const response: any = await changePatientStatus(patientId, newStatusObj);
  //     const updatedStatusId = response?.data?.updatedData?.statusId;
  //     const updatedStatus = patientStatus.find(
  //       (status) => status.id === updatedStatusId
  //     );
  //     if (!updatedStatus) return;
  //     let patientName = '';
  //     const updatedPatientList = patients.map(
  //       (patient: PatientListItemType) => {
  //         if (patient.id === patientId) {
  //           patientName = `${patient.firstName} ${patient.lastName}`;
  //           return {
  //             ...patient,
  //             status: updatedStatus,
  //           };
  //         }
  //         return patient;
  //       }
  //     );
  //     setPatients(updatedPatientList);
  //     toast.success(`Status of patient: ${patientName} updated successfully.`);
  //   } catch (e) {
  //     toast.error('Error updating status of patient.');
  //   } finally {
  //     setStatusUpdatingId('');
  //   }
  // };

  if (!user) {
    return <FullPageSpinnner />;
  }

  const isAccessible = MENUS.find(
    (item: any) => item.name === 'Patients'
  )?.access.includes(user.role as RoleType);

  if (!isAccessible) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="patient-list">
      {/* Search */}
      <div className="mb-3 w-100 d-flex justify-content-between align-items-center">
        <Search performGenericSearch={performGenericSearch}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(applyFilters)}>
              {/* <AdvancedSearch
                togglerRef={
                  advancedSearchRef as React.MutableRefObject<HTMLButtonElement>
                }
                handleResetFilters={handleResetFilters}
              /> */}
            </form>
          </FormProvider>
        </Search>
        <div className="d-flex align-items-center">
          <MDBBtn
            color="link"
            className="refresh-button"
            onClick={handleRefresh}
          >
            <MDBIcon icon="redo-alt" className="text-muted fs-5" />
          </MDBBtn>
          {patients?.length > 0 && (
            <PageLimitSelect
              limit={pagination.limit}
              handleLimitChange={handleLimitChange}
            ></PageLimitSelect>
          )}
        </div>
      </div>

      {/* Applied Filters */}
      {isFilterApplied && (
        <div className="mb-3 d-flex align-items-center">
          <p className="mb-0 text-muted">Filters: </p>
          {Object.keys(filters).map((filterKey: string) => {
            const filterValue = filters[filterKey as keyof PatientFilterType];
            if (filterValue) {
              return (
                <div className="ml-2">
                  <Chip
                    text={`${convertCamelCaseToTitleCase(
                      filterKey
                    )}: ${filterValue}`}
                    onCloseHandler={() =>
                      removeFilter(filterKey as keyof PatientFilterType)
                    }
                  />
                </div>
              );
            }
            return null;
          })}
        </div>
      )}

      {/* Result Count */}
      {patients.length > 0 && (
        <small className="text-muted d-inline-block mb-3">
          Showing {firstCount} - {lastCount} of {pagination.total}
        </small>
      )}

      {/* Table */}
      {!isLoading && (
        <MDBTable className="table-min-width-unset">
          <MDBTableHead>
            <tr className="table-header">
              <th scope="col" className="font-weight-bold">
                Patient
              </th>
              <th scope="col" className="font-weight-bold">
                Contact
              </th>
              <th scope="col" className="font-weight-bold">
                Status
              </th>

              <th scope="col" className="font-weight-bold">
                Source
              </th>
              <th scope="col" className="font-weight-bold">
                Action
              </th>
            </tr>
          </MDBTableHead>
          <MDBTableBody className="patients-table-body">
            {!isLoading && patients?.length === 0 && (
              <p className="text-muted mt-3">No patients found.</p>
            )}
            {patients?.map((patient: PatientListItemType) => (
              <PatientListItem
                key={patient.id}
                patient={patient}
                // handleChangePatientStatus={handleChangePatientStatus}
                // statusUpdatingId={statusUpdatingId}
              />
            ))}
          </MDBTableBody>
        </MDBTable>
      )}

      {/* Loader */}
      {isLoading && <TableSkeletonLoader rows={3} cols={6} />}

      {/* Pagination */}
      {!isLoading && (
        <div className="d-flex justify-content-center mt-4">
          <Pagination
            pageLimit={pagination.limit}
            totalCount={pagination.total}
            currentPage={pagination.currentPage}
            pageNeighbours={pagination.pageNeighbours}
            onPageChangedHandler={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

export default PatientList;
