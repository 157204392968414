import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getMessages } from 'services/message';
import { notificationStatusUpdate } from 'services/notification';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { selectMessages, setMessages } from 'stores/patient/messages';
import { subtractNotificationCount } from 'stores/patient/notification';
import { IListResponse } from 'types/http';
import { IMessage } from 'types/message';
import { formatDate } from 'utils/date';
import useAPIService from '../../../hooks/useApiService';
import LoadingIndicator from '../../common/LoadingIndicator';
import '../patientRoutableTab.scss';
import MessageCard from './MessageCard';
import MessageInput from './MessageInput';

let offset = 0;
const limit = 10;
let enableBottomScroll = true;
let showLoadMore = false;
let totalCount = 0;

const Messages = () => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const messages = useAppSelector(selectMessages);
  const params: { userId: string } = useParams();
  const [isLoadingMore, setLoadingMore] = useState<boolean>(false);

  const { loading, data } = useAPIService<IListResponse<IMessage>>({
    api: useCallback(() => getMessages(params?.userId), [params?.userId]),
  });

  useEffect(() => {
    if (data?.data) {
      showLoadMore = data.data.count > limit;
      totalCount = data.data.count;
      dispatch(setMessages([...data?.data?.rows?.reverse()]));
    }
  }, [data, dispatch]);

  useEffect(() => {
    const updateNotificationUpdate = async (id: string) => {
      await notificationStatusUpdate(id);
      dispatch(subtractNotificationCount());
    };

    if (params?.userId) {
      updateNotificationUpdate(params.userId);
    }
  }, [params?.userId, dispatch]);

  const showCreatedDate = (index: number) => {
    const initialMessageDate = messages[index]?.createdAt;
    const prevMessageDate = messages[index - 1]?.createdAt;
    const nextMessageDate = messages[index + 1]?.createdAt;

    const iMD = formatDate(initialMessageDate);
    const pID = formatDate(prevMessageDate);
    const nID = formatDate(nextMessageDate);

    if (iMD === pID) return false;

    if (iMD === pID && iMD === nID) {
      return false;
    }
    return true;
  };

  const onPressLoading = async () => {
    enableBottomScroll = false;
    setLoadingMore(true);
    offset += limit;
    const data: any = await getMessages(params?.userId, offset, limit);
    totalCount = data.data.count;
    if (totalCount === data.data.count) {
      showLoadMore = false;
    }
    dispatch(setMessages([...data.data.rows, ...messages]));
    setLoadingMore(false);
    setTimeout(
      () => ref?.current?.scrollTo({ top: 0, behavior: 'smooth' }),
      500
    );
  };

  const setEnableBottomScroll = () => (enableBottomScroll = true);

  if (loading)
    return (
      <div className="d-flex justify-content-center">
        <LoadingIndicator containerHeight="30rem" />
      </div>
    );

  return (
    <div>
      <div className="d-flex flex-column justify-content-between overflow-auto">
        {showLoadMore && (
          <h6
            className="align-self-center cursor-pointer"
            onClick={onPressLoading}
          >
            {isLoadingMore ? 'Loading...' : 'Load previous messages'}
          </h6>
        )}
        <div
          ref={ref}
          className="w-100 patient-message-container"
          style={{ height: 'calc(100vh - 550px)', overflow: 'auto' }}
        >
          {messages?.length ? (
            messages.map((message: any, index: number) => (
              <MessageCard
                key={message.id}
                message={message}
                userId={params?.userId}
                showCreatedDate={showCreatedDate(index)}
                enableBottomScroll={enableBottomScroll}
              />
            ))
          ) : (
            <div className="d-flex align-items-center justify-content-center">
              <p className="text-align-center">
                There are no messages to display
              </p>
            </div>
          )}
        </div>
        <div className="w-100 py-2">
          <MessageInput
            userId={params?.userId}
            setEnableBottomScroll={setEnableBottomScroll}
          />
        </div>
      </div>
    </div>
  );
};

export default Messages;
