import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';
import Skeleton from 'react-loading-skeleton';

interface Props {
  rows: number;
  cols: number;
}

const TableSkeletonLoader = ({ rows, cols }: Props) => {
  return (
    <MDBTable>
      <MDBTableHead>
        <tr>
          {Array(cols)
            .fill(0)
            .map((_, index) => (
              <th key={index}>
                <Skeleton />
              </th>
            ))}
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        {Array(rows)
          .fill(0)
          .map((_, rowIndex) => (
            <tr key={rowIndex}>
              {Array(cols)
                .fill(0)
                .map((_, colIndex) => (
                  <td key={colIndex}>
                    <Skeleton />
                  </td>
                ))}
            </tr>
          ))}
      </MDBTableBody>
    </MDBTable>
  );
};

export default TableSkeletonLoader;
