export const LOG_EVENT_TYPE = {
  SEARCH: 'Search',
  CREATE: 'Create',
  UPDATE: 'Update',
  REMOVE: 'Remove',
  LOGIN: 'Login',
  LOGOUT: 'Log out',
  MODIFY_PASSWORD: 'Modify Password',
  SYSTEM_ERROR: 'Error',
  SYSTEM_WARN: 'Warning',
  START_SURVEY: 'Start Survey',
  VALIDATE_ZIP_CODE: 'Validate Zipcode',
  COMPLETE_SURVEY: 'Complete Survey',
};
