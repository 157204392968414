import ModalWithRef from 'components/common/modal/ModalWithRef';
import { UserStatus } from 'enums/user';
import {
  MDBBtn,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
} from 'mdb-react-ui-kit';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { changeUserStatus } from 'services/user';
import { UserType } from 'types/user';
import { getFullName } from 'utils/npssUtil';

interface Props {
  /** Info of user whose status is to be changed */
  user: UserType | null;
  /** New status for the user */
  newStatus: boolean;
  handleUpdateUserStatus: (userId: string, newStatus: boolean) => void;
  handleCloseModal: () => void;
}

const StatusSwitchConfirmationModal = React.forwardRef((props: Props, ref) => {
  const { user, newStatus, handleUpdateUserStatus, handleCloseModal } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggleModal = () => {
    handleCloseModal();
  };

  const handleConfirmStatusChange = async () => {
    if (!user) return;

    // Update the user status in the list instantly so that user doesn't notice a delay while updating the status
    handleUpdateUserStatus(user.id, newStatus);
    setIsSubmitting(true);
    try {
      const res: any = await changeUserStatus(user?.id, {
        status: newStatus ? UserStatus.ACTIVE : UserStatus.INACTIVE,
      });
      handleCloseModal();
      toast.success(res.message);
    } catch (error) {
      // If in case, the API call fails, revert the user status back to its original value
      handleUpdateUserStatus(user.id, !newStatus);
      toast.error((error as any).response?.data?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const userFullName = getFullName(
    user?.firstName,
    user?.lastName,
    user?.middleName
  );

  return (
    <ModalWithRef ref={ref} title="Change user status" icon="user-edit">
      <>
        <MDBModalBody>
          <p>
            Are you sure you want to change status of{' '}
            <strong>{userFullName}</strong>?
          </p>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color="link"
            type="button"
            onClick={toggleModal}
            disabled={isSubmitting}
          >
            Cancel
          </MDBBtn>
          <MDBBtn
            type="button"
            color="secondary"
            className="text-dark"
            disabled={isSubmitting}
            onClick={handleConfirmStatusChange}
          >
            {isSubmitting && <MDBSpinner size="sm" />}
            <span className={`d-inline-block ${isSubmitting ? 'ml-2' : ''}`}>
              Confirm
            </span>
          </MDBBtn>
        </MDBModalFooter>
      </>
    </ModalWithRef>
  );
});

export default StatusSwitchConfirmationModal;
