import config from 'config';
import { NotificationDurationLabel } from 'enums/common';
import { INotification } from 'types/notification';
import http from 'utils/http';
import { isDayBefore, isSameDate } from './../utils/date';

export const getNotification = async (offset?: number, limit?: number) => {
  const response: any = await http({
    method: 'GET',
    url: config.endpoints.notification.getAll,
    params: { offset: offset ?? 0, limit: limit ?? 10 },
  });

  return response.data;
};

export const getUnreadNotificationCount = async (
  offset?: number,
  limit?: number
) => {
  const response: any = await http({
    method: 'GET',
    url: config.endpoints.notification.getAll,
    params: { offset: offset ?? 0, limit: limit ?? 10, isRead: false },
  });
  return response.data;
};

export const formatNotificationResponse = (data: INotification[]) => {
  const notificationData: any = [
    { key: NotificationDurationLabel.Today, label: 'Today', data: [] },
    { key: NotificationDurationLabel.YESTERDAY, label: 'Yesterday', data: [] },
    { key: NotificationDurationLabel.OLDER, label: 'Older', data: [] },
  ];
  const formattedData = data.reduce((map: any, item: INotification) => {
    if (isSameDate(item.createdAt)) {
      notificationData[0]['data'] = [...notificationData[0]['data'], item];
    } else if (isDayBefore(item.createdAt)) {
      notificationData[1]['data'] = [...notificationData[1]['data'], item];
    } else {
      notificationData[2]['data'] = [...notificationData[2]['data'], item];
    }
    return map;
  }, notificationData);
  return formattedData;
};

export const notificationStatusUpdate = async (userId: string) => {
  await http({
    method: 'PATCH',
    url: config.endpoints.notification.changeStatus,
    params: { userId },
    data: { isRead: true },
  });
};

export const getNotificationCount = async () => {
  const response = await http({
    method: 'GET',
    url: config.endpoints.notification.getCount,
  });
  return response?.data;
};
