import 'react-datepicker/dist/react-datepicker.css';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import RouterRoute from 'routes';
import { store } from 'stores';

function App() {
  return (
    <Provider store={store}>
      <RouterRoute />
    </Provider>
  );
}

export default App;
