import { useContext, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { sendMessage } from 'services/message';
import { store } from 'store';
import { useAppDispatch } from 'stores/hooks';
import { setMessage } from 'stores/patient/messages';
import colors from 'styles/constants/colors';
import { AppContext } from 'types/appContext';
import { getFirstLastName } from 'utils/npssUtil';

export interface IMessageForm {
  message: string;
}

const defaultValues: IMessageForm = {
  message: '',
};

const MESSAGE_REQUIRED_MSG = 'Please enter your email address';

const MessageInput = ({
  userId,
  setEnableBottomScroll,
}: {
  userId: string;
  setEnableBottomScroll: () => void;
}) => {
  const dispatch = useAppDispatch();
  const appContext = useContext(store);
  const { user } = appContext as AppContext;

  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<IMessageForm>({
    defaultValues,
  });
  const { control, watch } = methods;
  const isMessageEmpty = watch('message');

  const onSubmit = async (data: IMessageForm) => {
    if (!userId || !data.message?.trim()) return;

    setIsLoading(true);
    const res: any = await sendMessage({
      message: data?.message?.trim(),
      userId: userId,
      userType: 'Patient',
    });
    setEnableBottomScroll();
    if (res.success) {
      dispatch(
        setMessage({
          ...res.data,
          createdByUser: {
            ...getFirstLastName(user.name),
            id: userId,
          },
        })
      );
      methods.reset({
        message: '',
      });
    }
    setIsLoading(false);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Controller
          rules={{
            required: MESSAGE_REQUIRED_MSG,
            validate: () => false,
          }}
          control={control}
          name="message"
          render={({ field }) => (
            <div className="form-outline">
              <textarea
                {...field}
                id="textAreaExample"
                placeholder="Write your message here"
                rows={4}
                style={{
                  padding: 10,
                  borderWidth: 1,
                  borderColor: colors.neutral.light,
                  borderRadius: 4,
                  width: '100%',
                }}
                onKeyPress={(key) => {
                  if (!key.shiftKey && key.key === 'Enter') {
                    onSubmit(methods.getValues());
                  } else {
                    return key;
                  }
                }}
              />
            </div>
          )}
        />
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-primary btn-rounded"
            type="button"
            disabled={isMessageEmpty?.trim() === '' || isLoading}
            style={{ borderWidth: 1, borderRadius: 20 }}
            onClick={() => onSubmit(methods.getValues())}
          >
            Send
            {isLoading && (
              <div
                className="spinner-border spinner-border-sm light ml-1"
                role="status"
              />
            )}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default MessageInput;
