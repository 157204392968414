import config from 'config';
import http from 'utils/http';

export async function fetchEncryptedData() {
  const encryptedData = await http({
    url: config.endpoints.dosespot.fetchEncryptedData,
    method: 'GET',
  });
  return encryptedData.data;
}
