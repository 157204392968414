/**
 * Encode a URI by replacing each instance of certain characters by one, two, three,
 * or four escape sequences representing the UTF-8 encoding of the character
 *
 * @example
 * Input: 'test?' -> Output: '?x=test%3F'
 *
 * @param {string} uri :: uri to be encoded
 * @returns {string} :: encoded uri
 */

export const URIEncoder = (uri: string) => {
  return uri ? encodeURIComponent(uri) : '';
};
