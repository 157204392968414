import Modal from 'components/common/modal/Modal';
import { MDBBtn, MDBSpinner } from 'mdb-react-ui-kit';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchDocumentBufferData } from 'services/document';
import { formatDate } from 'utils/date';
import downloadBinaryObject from 'utils/fileSaver';
import { parseHtml } from 'utils/parseHtml';
import './style.scss';

interface IProps {
  documentName: string;
  documentDate: string;
  documentId: string;
}

const DocumentListItem = ({
  documentName,
  documentDate,
  documentId,
}: IProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [
    openConfirmDownloadDocumentDialog,
    setOpenConfirmDownloadDocumentDialog,
  ] = useState<boolean>(false);
  const [bufferData, setBufferData] = useState<[]>([]);
  const [contentType, setContentType] = useState<string>('');
  const params: any = useParams();

  const toggleShowConfirmDownloadDocumentDialog = () => {
    setOpenConfirmDownloadDocumentDialog((prev) => !prev);
  };

  const downloadDocument = async () => {
    setIsSubmitting(true);
    try {
      const response: any = await fetchDocumentBufferData(
        documentId,
        params?.userId
      );
      setBufferData(response?.data?.bufferData);
      setContentType(response?.data?.contentType);

      downloadBinaryObject(bufferData, contentType, documentName);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
      toggleShowConfirmDownloadDocumentDialog();
    }
  };

  const ConfirmDownloadDocumentBody = () => {
    return (
      <div className="p-4">
        <p>Are you sure you want to download {documentName}?</p>
        <div className="d-flex justify-content-end">
          <MDBBtn
            color="white"
            onClick={toggleShowConfirmDownloadDocumentDialog}
          >
            Cancel
          </MDBBtn>
          <MDBBtn
            className="ml-2"
            onClick={() => {
              downloadDocument();
            }}
          >
            {isSubmitting && <MDBSpinner size="sm" />}
            <span className={`d-inline-block ${isSubmitting ? 'ml-2' : ''}`}>
              Download
            </span>
          </MDBBtn>
        </div>
      </div>
    );
  };

  return (
    <>
      <tr>
        <td>{parseHtml(documentName, 60)}</td>
        <td>{formatDate(documentDate)}</td>
        <td>
          <span
            className="document-action-button"
            onClick={toggleShowConfirmDownloadDocumentDialog}
          >
            Download
          </span>
        </td>
      </tr>
      <Modal
        isOpen={openConfirmDownloadDocumentDialog}
        title="Download Document"
        onClose={() => toggleShowConfirmDownloadDocumentDialog()}
        children={<ConfirmDownloadDocumentBody />}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default DocumentListItem;
