import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle,
} from 'mdb-react-ui-kit';
import React from 'react';

interface IProps {
  isOpen: boolean;
  children: React.ReactChild;
  icon?: string;
  title: string;
  size?: 'sm' | 'lg' | 'xl' | 'fullscreen' | '';
  onClose: VoidFunction;
  isSubmitting?: boolean;
}

const Modal = ({
  isOpen,
  onClose,
  children,
  title,
  icon,
  size = '',
  isSubmitting,
}: IProps) => {
  return (
    <MDBModal show={isOpen} tabIndex="-1" staticBackdrop>
      <MDBModalDialog size={size} centered>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle className="text-dark font-weight-bold">
              {icon && <MDBIcon icon={icon} className="text-primary" />}
              <span className={`d-inline-block ${icon ? 'ml-3' : ''}`}>
                {title}
              </span>
            </MDBModalTitle>
            <MDBBtn
              disabled={isSubmitting}
              className="btn-close"
              color="none"
              onClick={onClose}
              type="button"
            ></MDBBtn>
          </MDBModalHeader>
          {children}
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default Modal;
