import NotificationAvatar from 'components/common/avatar/NotificationAvatar';
import { MDBDropdownLink } from 'mdb-react-ui-kit';
import colors from 'styles/constants/colors';
import './notificationMessage.scss';

interface IProps {
  fullname: string;
  time?: number;
  src: string;
  link: string;
  dotDisplay: string;
}

const NotificationMessageItem = ({
  fullname,
  time,
  src,
  link,
  dotDisplay,
}: IProps) => {
  return (
    <MDBDropdownLink
      href={link}
      style={{ padding: 0 }}
      className="notification-messsage-item"
    >
      <div className="container" style={{ padding: '10px' }}>
        <div className="avatar">
          <NotificationAvatar src={src} />
        </div>
        <div className="d-flex flex-row justify-space-between w-100">
          <div className="content ml-2">
            <div className="d-flex">
              <p className="m-0 font-weight-bold full-name">{fullname}</p>
              <span className="message-sent">&nbsp;sent a message</span>
            </div>
            {time && <p className="m-0 time text-muted">{time} hours ago</p>}
          </div>
          <div className="icon">
            <div
              className={`dot d-${dotDisplay}`}
              style={{ backgroundColor: colors.blue.light }}
            />
          </div>
        </div>
      </div>
    </MDBDropdownLink>
  );
};

export default NotificationMessageItem;
