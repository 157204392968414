import jwtDecode from 'jwt-decode';
import { JWTDecode } from 'types/auth';
import { get } from './storage';

export const getAccessToken = () => {
  return get('local', 'userToken');
};

export const decodeToken = (token: string): JWTDecode => {
  return jwtDecode(token);
};
