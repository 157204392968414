import { MENUS } from 'constants/index';
import useLocalStorage from 'hooks/useLocalStorage';
import React, { createContext, useEffect, useState } from 'react';
// import { fetchPatientStatus } from 'services/patientStatus';
import { fetchRoles } from 'services/role';
import { AppContext } from 'types/appContext';
import { JWTDecode } from 'types/auth';
import { MDBSelectEvent } from 'types/mdb';
import { UserRoleType } from 'types/roles';
import { decodeToken } from 'utils/auth';
import { generateUserRolesOptions } from 'utils/npssUtil';

type Props = {
  children: React.ReactNode;
};

const initialState = {};
const store = createContext<AppContext | {}>(initialState);
const { Provider } = store;

const StateProvider = ({ children }: Props) => {
  // Auth
  const [userToken, setUserToken] = useLocalStorage('userToken');
  const [user, setUser] = useState<JWTDecode>();

  // Menus
  const menus = MENUS;

  // Patient status
  // const [patientStatus, setPatientStatus] = useState<PatientStatusType[]>([]);
  // const [patientStatusOptions, setPatientStatusOptions] = useState<
  //   MDBSelectEvent[]
  // >([]);

  // User roles
  const [userRoles, setUserRoles] = useState<UserRoleType[]>([]);
  const [userRoleOptions, setUserRoleOptions] = useState<MDBSelectEvent[]>([]);

  useEffect(() => {
    if (!userToken) return;
    const loggedInUser = decodeToken(userToken as string);

    setUser(loggedInUser);
  }, [userToken]);

  useEffect(() => {
    const getPatientStatus = async () => {
      // const response: any = await fetchPatientStatus();
      // setPatientStatus(response?.data?.rows);
      // setPatientStatusOptions(
      //   generatePatientStatusOptions(response?.data?.rows)
      // );
    };

    const getUserRoles = async () => {
      const response: any = await fetchRoles();
      setUserRoles(response?.data?.roles);
      setUserRoleOptions(generateUserRolesOptions(response?.data?.roles));
    };

    if (user?.id) {
      getPatientStatus();
      getUserRoles();
    }
  }, [user?.id]);

  return (
    <Provider
      value={{
        user,
        userToken,
        setUserToken,
        menus,
        // patientStatus,
        // patientStatusOptions,
        userRoles,
        userRoleOptions,
      }}
    >
      {children}
    </Provider>
  );
};

export { store, StateProvider };
