import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBSpinner,
} from 'mdb-react-ui-kit';
import { parseHtml } from 'utils/parseHtml';

interface IProps {
  cancelButtonLabel?: string;
  isOpen: boolean;
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
  modalTitle: string;
  modalContent: string | JSX.Element;
  modalContentExtra?: string;
  isSubmitting: boolean;
  submitButtonLabel?: string;
}

const ConfirmationModal = ({
  cancelButtonLabel,
  isOpen,
  onCancel,
  onConfirm,
  modalTitle,
  modalContent,
  modalContentExtra,
  isSubmitting,
  submitButtonLabel,
}: IProps) => {
  const onCloseModal = () => {
    onCancel();
  };

  return (
    <MDBModal show={isOpen} tabIndex="-1" staticBackdrop>
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle className="text-dark font-weight-bold">
              {modalTitle}
            </MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCloseModal}
              type="button"
            />
          </MDBModalHeader>
          <MDBModalBody>
            {modalContent}
            {modalContentExtra && (
              <div
                dangerouslySetInnerHTML={{
                  __html: parseHtml(modalContentExtra),
                }}
              />
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="link"
              type="button"
              onClick={onCloseModal}
              disabled={isSubmitting}
            >
              {cancelButtonLabel}
            </MDBBtn>
            <MDBBtn
              type="button"
              color="secondary"
              className="text-dark"
              disabled={isSubmitting}
              onClick={onConfirm}
            >
              {isSubmitting && <MDBSpinner size="sm" />}
              <span className={`d-inline-block ${isSubmitting ? 'ml-2' : ''}`}>
                {submitButtonLabel}
              </span>
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

ConfirmationModal.defaultProps = {
  cancelButtonLabel: 'Cancel',
  submitButtonLabel: 'Confirm',
  modalContentExtra: undefined,
};

export default ConfirmationModal;
