import { MDBCheckbox, MDBInput } from 'mdb-react-ui-kit';
import { Controller, useFormContext } from 'react-hook-form';

const RefundTransactionForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name="note"
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <MDBInput
            value={value}
            onChange={onChange}
            label="Reason you are refunding the transaction"
            id="refund-note"
            type="text"
            className={`mb-3 ${errors.note ? 'is-invalid' : ''}`}
            invalid={!!errors.note}
            textarea
            rows={4}
          />
        )}
      />
      <Controller
        control={control}
        name="iAgree"
        render={({ field: { value, onChange } }) => (
          <MDBCheckbox
            checked={value}
            onChange={(e: any) => onChange(e.target.checked)}
            id="iAgree"
            label="I understand that this process is not reversible."
          />
        )}
      />
    </>
  );
};

export default RefundTransactionForm;
