import Logo from 'assets/images/png/matrx-logo.png';
import { AddressType } from 'enums/common';
import { PaymentStatus } from 'enums/payment';
import { MDBBadge, MDBSpinner } from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as transactionAPI from 'services/transaction';
import { TransactionType } from 'types/transaction';
import { formatDate } from 'utils/date';
import {
  formatCurrency,
  formatPhone,
  getAddressDisplayName,
  getFullName,
} from 'utils/npssUtil';
import './receipt.scss';

const Receipt = () => {
  const params: { transactionId: string } = useParams();
  const { transactionId } = params;

  const [data, setData] = useState<TransactionType>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getTransactionDetail = async () => {
      setIsLoading(true);
      try {
        const response: any = await transactionAPI.fetchTransaction(
          transactionId
        );
        setData(response.data);
      } catch (error) {
        toast.error((error as any).response?.data?.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (transactionId) {
      getTransactionDetail();
    }
  }, [transactionId]);

  const patient = data?.patient;
  const drugOffering = data?.transactionDetail?.drugOffering;
  const paymentStatus = data?.status;

  const receiptInfo = {
    receiptId: data?.receiptId,
    receiptDate: formatDate(data?.transactionDate),
  };

  const patientInfo = {
    fullName:
      patient?.firstName &&
      patient?.lastName &&
      getFullName(patient?.firstName, patient?.lastName, patient?.middleName),
    address: patient?.addresses?.billingAddress?.find(
      (item) => item.addressType === AddressType.BILLING
    ),
    phone: formatPhone(patient?.phone),
    email: patient?.email,
  };

  const particulars = [
    {
      drugName: drugOffering?.drug?.brandName,
      dose: `${drugOffering?.refillInterval} days`,
      amount: data?.totalAmount ? formatCurrency(data.totalAmount) : '',
    },
  ];

  return (
    <div className="receipt">
      {isLoading && (
        <div className="loading-container d-flex justify-content-center align-items-center">
          <MDBSpinner color="primary">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        </div>
      )}

      {!isLoading && (
        <table className="container" cellPadding="0" cellSpacing="0">
          <thead className="receipt__table-header-logo">
            <tr>
              <th className="receipt__logo-wrapper">
                <img src={Logo} alt="logo" width="100" />
              </th>
              <th>
                <table className="table_small">
                  <tbody>
                    <tr>
                      <td colSpan={2} className="text-left">
                        <b>RECEIPT</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100 text-left">Receipt ID:</td>
                      <td className="text-left">{receiptInfo?.receiptId}</td>
                    </tr>
                    <tr>
                      <td className="w-100 text-left">Receipt Date:</td>
                      <td className="text-left">{receiptInfo?.receiptDate}</td>
                    </tr>
                  </tbody>
                </table>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr className="mb-0">
              <td></td>
              <td className="pb-0">
                {paymentStatus === PaymentStatus.SUCCEEDED && (
                  <MDBBadge pill color="success">
                    {paymentStatus}
                  </MDBBadge>
                )}
                {paymentStatus === PaymentStatus.REFUNDED && (
                  <MDBBadge pill color="warning">
                    {paymentStatus}
                  </MDBBadge>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <table className="table_small">
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        <h6>MatRx LLC</h6>
                      </td>
                    </tr>
                    <tr>
                      <td width="10px" valign="top">
                        <i className="fas fa-map-marker-alt"></i>{' '}
                      </td>
                      <td>
                        1259 West Street
                        <br />
                        Grand Rapids, Michigan 48438
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fas fa-mobile-alt"></i>{' '}
                      </td>
                      <td>123 456-5555</td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fas fa-envelope"></i>{' '}
                      </td>
                      <td> info@mat-rx.com</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <table className="table_small">
                  <tbody>
                    <tr>
                      <td colSpan={2} className="receipt__patient-info">
                        Patient Info
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <h6>{patientInfo?.fullName} </h6>
                      </td>
                    </tr>
                    <tr>
                      <td width="10px" valign="top">
                        <i className="fas fa-map-marker-alt"></i>{' '}
                      </td>
                      <td>
                        {patientInfo?.address
                          ? getAddressDisplayName(patientInfo.address)
                          : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fas fa-mobile-alt"></i>{' '}
                      </td>
                      <td>{patientInfo?.phone}</td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fas fa-envelope"></i>{' '}
                      </td>
                      <td>{patientInfo?.email}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className="content">
              <td colSpan={2}>
                <table width="100%" className="receipt__particulars-table">
                  <thead>
                    <tr>
                      <th className="text-left">#</th>
                      <th className="text-left">Medication</th>
                      <th className="text-left"> Dose</th>
                      <th className="text-right"> Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {particulars?.map((item, index: number) => (
                      <tr className="border-bottom" key={index + 1}>
                        <td width="20%">{index + 1}</td>
                        <td width="40%">{item.drugName}</td>
                        <td width="20%">{item.dose}</td>
                        <td className="text-right">{item.amount}</td>
                      </tr>
                    ))}

                    <tr>
                      <td colSpan={4} className="text-right">
                        <p>
                          <small>Please note that all charges are final.</small>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        <p>
                          Thank you for choosing <b>MatRx</b>.{' '}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Receipt;
