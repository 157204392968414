import React from 'react';
import './receiptLayout.scss';

interface Props {
  children: React.ReactNode;
}

const ReceiptLayout = ({ children }: Props) => {
  return (
    <div className="receipt-layout">
      <div className="d-flex justify-content-center receipt-layout__card">
        {children}
      </div>
    </div>
  );
};

export default ReceiptLayout;
