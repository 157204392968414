import colors from 'styles/constants/colors';
import { AnswerType, PatientDetailType } from 'types/patient';
import './surveyList.scss';

interface Props {
  detail: PatientDetailType | undefined;
}

const SurveyListItem = ({
  sn,
  question,
  answers,
  followUpQuestion,
  followUpAnswer,
}: {
  sn: number;
  question: string;
  answers: [AnswerType];
  followUpQuestion: string;
  followUpAnswer: string;
}) => {
  return (
    <div className="survey-list-item rounded-2 p-2 mb-1">
      <div className="d-flex align-items-center mb-2">
        <p className="mb-0 text-muted survey-list-item__sn">Question {sn}:</p>
        <p className="mb-0 text-dark ml-2">{question}</p>
      </div>

      <div className="d-flex align-items-center">
        <p
          className="mb-0 survey-list-item__sn"
          style={{ color: colors.primary.default, fontWeight: '600' }}
        >
          Answer:
        </p>
        <p
          className="mb-0 ml-1"
          style={{ color: colors.primary.default, fontWeight: '600' }}
        >
          {answers.map((item) => item.value).join(', ')}
        </p>
      </div>

      {followUpQuestion ? (
        <div className=" align-items-center mb-2">
          <div className="d-flex align-items-center mb-2">
            <p className="mb-0 text-muted survey-list-item__sn mt-3">
              Follow up question:{' '}
            </p>
            <p className="mb-0 text-dark ml-2 mt-3">{followUpQuestion}</p>
          </div>

          <p
            className="mb-0 survey-list-item__sn"
            style={{ color: colors.primary.default, fontWeight: '600' }}
          >
            Follow up answer: {followUpAnswer}
          </p>
        </div>
      ) : null}
    </div>
  );
};

const SurveyList = (props: Props) => {
  const { detail } = props;

  const surveys = detail?.surveys;

  return (
    <div>
      <h5 className="font-weight-bold mb-3">Survey Questionaire</h5>
      {surveys?.map((survey, index: number) => (
        <SurveyListItem
          key={survey.id}
          sn={index + 1}
          question={survey.question}
          answers={survey.answers}
          followUpQuestion={survey.followUpQuestion}
          followUpAnswer={survey.followUpAnswer}
        />
      ))}
    </div>
  );
};

export default SurveyList;
