import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import { useContext, useEffect, useState } from 'react';
import * as userService from 'services/user';
import { store } from 'store';
import { AddressType } from 'types/address';
import { AppContext } from 'types/appContext';
import { UserType } from 'types/user';
import { formatDate } from 'utils/date';
import { getAddressDisplayName } from 'utils/npssUtil';
import '../../components/patient-survey/patientInfo.scss';

const DetailItem = ({
  label,
  content,
  isDate = false,
  isAddress = false,
}: {
  label: string;
  content: string | AddressType | undefined;
  isDate?: boolean;
  isAddress?: boolean;
}) => {
  return (
    <MDBRow>
      <MDBCol size="3">
        <p className="mb-0 fs-6 text-muted">{label}</p>
      </MDBCol>
      <MDBCol size="8">
        {isDate && (
          <p className="mb-0 fs-6">{content ? formatDate(content) : 'N/A'}</p>
        )}
        {isAddress && content && (
          <p className="mb-0 fs-6">
            {getAddressDisplayName(content as AddressType)}
          </p>
        )}
        {!isDate && !isAddress && <p className="mb-0 fs-6">{content}</p>}
      </MDBCol>
    </MDBRow>
  );
};

const MyProfile = () => {
  const [data, setData] = useState<UserType | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const appContext = useContext(store);
  const { user } = appContext as AppContext;

  useEffect(() => {
    const fetchUserDetail = async (id: string) => {
      const response: any = await userService.fetchUser(id);
      setData(response.data);
      setIsLoading(false);
    };
    if (user?.id) {
      fetchUserDetail(user.id);
    }
  }, [user?.id]);

  const address = {
    addressLine1: data?.addressLine1,
    addressLine2: data?.addressLine2,
    city: data?.city,
    state: data?.state,
    zipCode: data?.zipCode,
  };

  if (isLoading) return <p>...</p>;

  return (
    <div className="patient-info rounded-2 mb-4">
      <div className="patient-info__name">
        <h5>
          {data?.firstName} {data?.middleName ? `${data?.middleName}` : ''}{' '}
          {data?.lastName}
        </h5>
      </div>
      <div className="patient-info__details">
        <MDBRow className="mb-2">
          <MDBCol size="md" className="patient-info__details-column">
            <DetailItem
              label="Phone"
              content={data?.phone ? data.phone : 'N/A'}
            />
          </MDBCol>
          <MDBCol size="md" className="patient-info__details-column">
            <DetailItem
              label="Email"
              content={data?.email ? data.email : 'N/A'}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="md" className="patient-info__details-column">
            <DetailItem label="DOB" content={data?.dob} isDate />
          </MDBCol>
          <MDBCol size="md" className="patient-info__details-column">
            <DetailItem
              label="Address"
              content={address as AddressType}
              isAddress
            />
          </MDBCol>
        </MDBRow>
      </div>
    </div>
  );
};

export default MyProfile;
