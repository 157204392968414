export const MENUS = [
  {
    name: 'Patients',
    icon: 'user',
    link: '/patients',
    isVisibleOnSidebar: true,
    access: ['Super Admin', 'Admin', 'Provider'],
    submenus: [
      {
        name: 'Survey Quiz',
        icon: '',
        link: '/patients/:id',
        isVisibleOnSidebar: false,
      },
    ],
  },
  {
    name: 'Orders',
    icon: 'file-invoice',
    link: '/orders',
    isVisibleOnSidebar: true,
    access: ['Super Admin', 'Admin', 'Provider', 'Patient'],
    subMenus: [],
  },
  {
    name: 'Billings',
    icon: 'dollar-sign',
    link: '/billings',
    isVisibleOnSidebar: true,
    access: ['Super Admin', 'Admin', 'Provider', 'Patient'],
    subMenus: [],
  },
  {
    name: 'Users',
    icon: 'users',
    link: '/users',
    isVisibleOnSidebar: true,
    access: ['Super Admin', 'Admin', 'Provider'],
    subMenus: [],
  },
  {
    name: 'Activities',
    icon: 'warehouse',
    link: '/activity-log',
    isVisibleOnSidebar: true,
    access: ['Super Admin', 'Admin', 'Provider'],
    subMenus: [],
  },
];
