import defaultAvatar from 'assets/images/jpg/default-avatar.jpg';
import NotificationMessages from 'components/notification-messages/NotificationMessages';
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
} from 'mdb-react-ui-kit';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { store } from 'store';
import { getPatientDetail } from 'stores/patient/patient-detail';
import { AppContext } from 'types/appContext';
import { MenuType } from 'types/menu';
import { convertToTitleCase } from 'utils/string';
import './header.scss';

interface Props {}

const Header = (props: Props) => {
  const location = useLocation();
  const history = useHistory();
  const patientDetail = useSelector(getPatientDetail);

  const params: { patientId: string } = useParams();

  const appContext = useContext(store);
  const { user, menus } = appContext as AppContext;

  if (!user) return <p></p>;

  const activeMenu = menus.find((menu: MenuType) =>
    location.pathname.includes(menu.link)
  );

  // hardcoded
  const isMyProfilePage = location.pathname === '/my-profile';

  // TODO: Submenu is kind of hardcoded here. Rework this piece to account for all submenus
  // need to show params in submenu so a common field slug is used which will show the data passed in slug field of path, ex: path/:slug

  const subMenu =
    params?.patientId || history.location.pathname.match('/notifications')
      ? { name: patientDetail.fullName }
      : activeMenu?.link === '/patients'
      ? activeMenu?.submenus[0]
      : null;

  const isParentMenuActive = activeMenu?.link === location.pathname;
  const notificationMenu = history.location.pathname.match('/notifications')
    ? 'Notifications'
    : '';

  return (
    <div className="header fixed-top square border-bottom bg-white">
      <div className="header__content some-content">
        {isParentMenuActive || isMyProfilePage ? (
          <h5 className="m-0">
            {isMyProfilePage ? 'My Profile' : activeMenu?.name}
          </h5>
        ) : (
          <div className="d-flex align-items-center">
            <h5
              className="m-0 d-inline-block text-primary cursor-pointer"
              onClick={() => history.push(activeMenu?.link || '/')}
            >
              {activeMenu?.name ?? notificationMenu}
            </h5>
            <MDBIcon icon="chevron-right" className="mx-3" />
            <h5 className="patient-submenu-fullname ">{subMenu?.name}</h5>
          </div>
        )}
        <div className="d-flex  align-items-center">
          <NotificationMessages />
          <MDBDropdown
            group
            className="shadow-0"
            options={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [55, 0],
                  },
                },
              ],
            }}
          >
            <MDBDropdownToggle
              color="link"
              className="d-flex justify-content-between align-items-center mdb-dropdown-without-arrow text-dark header__user"
            >
              {' '}
              <img
                src={defaultAvatar}
                alt="user-avatar"
                className="rounded-circle shadow-1 cursor-pointer"
              />
              <div>
                <h6 className="mb-0 font-weight-bold">{user?.name}</h6>
                <p className="m-0 text-muted">
                  {convertToTitleCase(user?.role)}
                </p>
              </div>
            </MDBDropdownToggle>
            <MDBDropdownMenu className="mt-2 shadow-4">
              <MDBDropdownItem onClick={() => history.push('/my-profile')}>
                <MDBDropdownLink>My Profile</MDBDropdownLink>
              </MDBDropdownItem>
              <MDBDropdownItem>
                <MDBDropdownLink href="/logout">Logout</MDBDropdownLink>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;
