import { MDBCollapse } from 'mdb-react-ui-kit';
import { IOrderTableRow } from 'types/order';

interface IProps {
  orderDetail: IOrderTableRow;
  showCollapse: boolean;
}

const OrdersCollapseRow = ({ orderDetail, showCollapse }: IProps) => {
  return (
    <tr key={orderDetail.id}>
      <td colSpan={7}>
        <MDBCollapse show={showCollapse}>
          <div className="d-flex gap-5">
            <div className="orders-table-collapse-big-column d-flex">
              <p className="font-weight-bold"> Prescribed Drug: </p>
              <p className="ml-2">
                {orderDetail.prescription?.medicationName
                  ? orderDetail.prescription?.medicationName
                  : 'N/A'}
              </p>
            </div>

            <div className="d-flex">
              <p className="font-weight-bold">Quantity: </p>
              <p className="ml-2">
                {orderDetail.prescription?.quantityWritten
                  ? orderDetail.prescription?.quantityWritten
                  : 'N/A'}
              </p>
            </div>
          </div>
        </MDBCollapse>
      </td>
    </tr>
  );
};

export default OrdersCollapseRow;
