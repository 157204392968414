import { MAX_FILE_SIZE } from 'constants/index';
import {
  MDBBtn,
  MDBIcon,
  MDBSpinner,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getDocument, uploadDocumentToPatient } from 'services/document';
import { parseHtml } from 'utils/parseHtml';
import Modal from '../../common/modal/Modal';
import DocumentListItem from './DocumentListItem';
import './style.scss';
import UploadedDocumentList from './UploadedDocumentList';

const types = [
  // Images
  'png',
  'jpeg',
  'jpg',
  'svg',
  'gif',
  // Excel
  'xls',
  'xlsx',
  'xlt',
  'xlsm',
  'xlb',
  'xlc',
  'xlsb',
  // Word
  'doc',
  'docm',
  'dotx',
  'dotm',
  'dot',
  'docx',
  // PDF
  'pdf',
  // Text
  'txt',
  'rtf',
  'csv',
  'tsv',
  'xml',
  'json',
];

const DropZoneCss = () => {
  return (
    <div>
      <div className="cursor-pointer">
        <MDBIcon icon="upload" />
        <p className="p-0 m-0">Drag & Drop File</p>
        <p className="p-0 m-0">or</p>
        <p className="text-primary">
          <u>Browse File</u>
        </p>
      </div>
      <p>[Max: {MAX_FILE_SIZE}MB]</p>
    </div>
  );
};

const DropZoneFileUploadedCss = ({ file }: { file: any }) => {
  return (
    <div className="cursor-pointer">
      <MDBIcon icon="upload" />
      <p className="text-primary">
        <u>{parseHtml(file?.name, 40)}</u>
      </p>
    </div>
  );
};

const PatientDocument = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [documentDetails, setDocumentDetails] = useState<any>([]);
  const [uploadedDocument, setUploadedDocument] = useState<any>([]);
  const [toggleFetchUploadedDocument, setToggleFetchUploadedDocument] =
    useState<boolean>(false);
  const [toggleFetchDocumentFromPatient, setToggleFetchDocumentFromPatient] =
    useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const params: any = useParams();
  const userId = params?.userId;

  const [basicActive, setBasicActive] = useState('tab1');

  const handleBasicClick = (value: string) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  useEffect(() => {
    const getAllDocuments = async () => {
      const response: any = await getDocument(false, userId);
      setDocumentDetails(response?.data?.rows);
    };
    getAllDocuments();
  }, [userId, toggleFetchDocumentFromPatient]);

  useEffect(() => {
    const getAllDocuments = async () => {
      const response: any = await getDocument(true, userId);
      setUploadedDocument(response?.data?.rows);
    };
    getAllDocuments();
  }, [userId, toggleFetchUploadedDocument]);

  const toggleShow = () => {
    console.log('Clicked');

    setOpen((prev) => !prev);
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    console.log(file);
    const formData = new FormData();

    formData.append('documents', file);

    try {
      const response: any = await uploadDocumentToPatient(formData, userId);

      setToggleFetchUploadedDocument((prev) => !prev);
      toast.success(
        response?.message ||
          response?.response?.statusText ||
          'Document was uploaded'
      );
    } catch (error: any) {
      toast.error((error as any).response?.data?.message);

      console.log(error);
    } finally {
      setIsSubmitting(false);
      setFile([]);
      setOpen((prev) => !prev);
    }
  };

  const UploadDocumentBody = () => {
    const handleChange = (handleChangeFile: any) => {
      setFile(handleChangeFile);
    };
    return (
      <div className="pt-2 px-3 file-wrapper">
        <FileUploader
          children={
            !file || file?.length < 1 ? (
              <DropZoneCss />
            ) : (
              <DropZoneFileUploadedCss file={file} />
            )
          }
          handleChange={handleChange}
          maxSize={MAX_FILE_SIZE}
          onDrop={(item: any) => {
            setFile(item);
          }}
          types={types}
        />
        <div className="d-flex justify-content-center pt-2 pb-2">
          <MDBBtn
            disabled={file === null || file?.length < 1}
            onClick={() => {
              onSubmit();
            }}
          >
            {isSubmitting && <MDBSpinner size="sm" />}
            <span className={`d-inline-block ${isSubmitting ? 'ml-2' : ''}`}>
              Upload
            </span>
          </MDBBtn>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-end">
        <MDBBtn onClick={() => setOpen((prev) => !prev)}>Upload</MDBBtn>
      </div>
      <Modal
        isOpen={open}
        title="Upload Document"
        onClose={() => {
          toggleShow();
          setFile([]);
        }}
        children={<UploadDocumentBody />}
        isSubmitting={isSubmitting}
      />
      <div>
        <MDBTabs className="flex-column">
          <MDBTabs className="mb-3">
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => {
                  handleBasicClick('tab1');
                  setToggleFetchUploadedDocument((prev) => !prev);
                }}
                active={basicActive === 'tab1'}
                style={{ textTransform: 'capitalize', fontSize: '14px' }}
              >
                Shared by patient
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => {
                  handleBasicClick('tab2');
                  setToggleFetchDocumentFromPatient((prev) => !prev);
                }}
                active={basicActive === 'tab2'}
                style={{ textTransform: 'capitalize', fontSize: '14px' }}
              >
                Uploaded Documents
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>

          <MDBTabsContent>
            <MDBTabsPane show={basicActive === 'tab1'}>
              {documentDetails.length < 1 ? (
                'No Documents'
              ) : (
                <MDBTable>
                  <MDBTableHead>
                    <tr>
                      <th scope="col">Document Name</th>
                      <th scope="col" colSpan={2}>
                        Date
                      </th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {documentDetails?.map((item: any) => (
                      <DocumentListItem
                        documentName={item?.originalName}
                        documentDate={item?.createdAt}
                        documentId={item?.id}
                      />
                    ))}
                  </MDBTableBody>
                </MDBTable>
              )}
            </MDBTabsPane>
            <MDBTabsPane show={basicActive === 'tab2'}>
              {uploadedDocument.length < 1 ? (
                'No Documents'
              ) : (
                <MDBTable>
                  <MDBTableHead>
                    <tr>
                      <th scope="col">Document Name</th>
                      <th scope="col" colSpan={3}>
                        Date
                      </th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {uploadedDocument?.map((item: any) => (
                      <UploadedDocumentList
                        documentName={item?.originalName}
                        documentDate={item?.createdAt}
                        documentId={item?.id}
                        setToggleFetchUploadedDocument={
                          setToggleFetchUploadedDocument
                        }
                        isShared={item?.isShared}
                      />
                    ))}
                  </MDBTableBody>
                </MDBTable>
              )}
            </MDBTabsPane>
          </MDBTabsContent>
        </MDBTabs>
      </div>
    </div>
  );
};

export default PatientDocument;
