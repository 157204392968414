import { VALIDATION_MESSAGE } from 'constants/index';
import {
  MDBAlert,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBSpinner,
} from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { forgetPassword } from 'services/auth';
import { ForgetPasswordType } from 'types/auth';
import { validateEmail } from 'utils/validation';

const { EMAIL, REQUIRED } = VALIDATION_MESSAGE;

interface Props {}

const ForgetPassword = (props: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset,
  } = useForm<ForgetPasswordType>({
    defaultValues: {
      email: '',
    },
  });

  /** Stores the error message from server */
  const [error, setError] = useState<string>('');
  /** Whether the email with the instruction to reset password is sent or not */
  const [isEmailSentSuccess, setIsEmailSentSuccess] = useState<boolean>(false);
  /** Loading state for submit */
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  /**
   * Reset state variables on mount
   */
  useEffect(() => {
    reset();
    setIsEmailSentSuccess(false);
  }, [reset]);

  const onSubmit = async (data: ForgetPasswordType) => {
    try {
      setIsSubmitting(true);
      await forgetPassword({ email: data.email });
      setIsEmailSentSuccess(true);
    } catch (err) {
      setError((err as any).response?.data?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isEmailSentSuccess) {
    const email = getValues('email');
    return (
      <div className="d-flex flex-column align-items-center">
        <MDBIcon
          icon="envelope-open-text"
          className="text-muted text-4xl mb-3"
        />
        <p className="text-center">
          Email has been sent to <strong>{email}</strong> with instruction to
          reset the password.
        </p>
      </div>
    );
  }

  return (
    <div>
      <h3 className="mb-4pt5">Forgot Password?</h3>
      <MDBAlert
        show={!!error}
        className="w-100 p-2 rounded-1 mb-4"
        color="danger"
      >
        {error}
      </MDBAlert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          rules={{
            required: REQUIRED,
            validate: (value) => validateEmail(value) || EMAIL,
          }}
          render={({ field }) => (
            <MDBInput
              {...field}
              label="Email"
              id="email"
              size="lg"
              wrapperClass="mb-4pt5"
              className={`bg-white ${errors.email ? 'is-invalid' : ''}`}
              validation={errors.email ? errors.email?.message : ''}
              invalid={!!errors.email}
            />
          )}
        />
        <MDBBtn
          color="secondary"
          size="lg"
          className="shadow-none w-100 text-dark"
          disabled={isSubmitting}
        >
          {isSubmitting && (
            <MDBSpinner size="sm" role="status" tag="span" className="me-2" />
          )}
          Send Reset Link
        </MDBBtn>
      </form>
      <div className="d-flex flex-column align-items-center text-muted mt-4">
        <small className="mb-1">
          Copyright &copy; {new Date().getFullYear()}. MAT Rx.
        </small>
        <small>
          <a href="/terms-of-use" target="_blank" className="muted-link">
            Terms of Use
          </a>{' '}
          |{' '}
          <a href="/privacy-policy" target="_blank" className="muted-link">
            Privacy Policy
          </a>
        </small>
      </div>
    </div>
  );
};

export default ForgetPassword;
