import { REGEX } from 'constants/regex';
import {
  MDBAlert,
  MDBBtn,
  MDBCheckbox,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle,
} from 'mdb-react-ui-kit';
import { useContext, useEffect, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  handleResendTwoFactorAuthentication,
  handleTwoFactorAuthentication,
} from 'services/auth';
import { store } from 'store';
import { AppContext } from 'types/appContext';
import {
  ITwoFactorAuthenticationForm,
  ITwoFactorAuthenticationSchema,
} from 'types/auth';
import { IErrorResponse } from 'types/http';
import './twoFactorAuthentication.scss';

interface IProps {
  email: string;
  isOpen: boolean;
  onClose: VoidFunction;
}

const TwoFactorAuthenticationModal = ({ isOpen, onClose, email }: IProps) => {
  const { control } = useForm<ITwoFactorAuthenticationForm>({
    defaultValues: { rememberFor7Days: false },
  });

  const [otpCode, setOtpCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [check, setCheck] = useState<boolean>(false);
  const [rememberForDaysPayload, setRememberForDaysPayload] = useState<
    number | undefined
  >(undefined);
  const [isResendingCode, setIsResendingCode] = useState(false);
  const [key, setKey] = useState(1);
  const history = useHistory();
  const location = useLocation();

  const inputStyles = {
    fontFamily: 'monospace',
    margin: '4px',
    width: '70px',
    height: '70px',
    borderRadius: '3px',
    fontSize: '32px',
    borderSpacing: '16px',
    backgroundColor: '#E5F0FF',
    textAlign: 'center',
    border: `1px solid #E5F0FF`,
  } as const;

  const inputStyleInvalid = {
    fontFamily: 'monospace',
    margin: '4px',
    width: '70px',
    height: '70px',
    borderRadius: '3px',
    fontSize: '32px',
    backgroundColor: '#E5F0FF',
    color: '#0D47A1',
    border: `1px solid #E5F0FF`,
    textAlign: 'center',
  } as const;

  const appContext = useContext(store);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchParams = new URLSearchParams(location.search);
  const { setUserToken } = appContext as AppContext;

  const handlePinChange = (data: string) => {
    setOtpCode(data);
  };

  useEffect(() => {
    if (otpCode === 'reset') {
      setKey(key + 1);
      setOtpCode('');
    }
  }, [key, otpCode]);

  const onModalClose = () => {
    setOtpCode('reset');
    setErrorMessage('');
    onClose();
  };

  useEffect(() => {
    const test = REGEX.OTP.test(otpCode);
    if (!test) {
      return;
    }

    const onSubmit = async () => {
      if (check === true) {
        setRememberForDaysPayload(7);
      } else {
        setRememberForDaysPayload(undefined);
      }

      const payload = {
        code: otpCode,
        rememberForDays: rememberForDaysPayload,
        email: email,
      };

      try {
        setIsSubmitting(true);

        const response: any = await handleTwoFactorAuthentication(
          payload as ITwoFactorAuthenticationSchema
        );

        setUserToken(response.data.token);

        const redirectPath = searchParams.get('redirect');

        if (redirectPath) {
          history.push(redirectPath);
        } else {
          history.push('/');
        }
      } catch (err) {
        setErrorMessage((err as IErrorResponse)?.response.data.message);
        setOtpCode('');
        setKey(key + 1);
      } finally {
        setIsSubmitting(false);
      }
    };
    onSubmit();
  }, [
    check,
    email,
    history,
    key,
    otpCode,
    rememberForDaysPayload,
    searchParams,
    setUserToken,
  ]);

  const onResendCodeClick = async () => {
    setErrorMessage('');
    const payload = {
      email: email,
    };
    setIsResendingCode(true);
    const response = await handleResendTwoFactorAuthentication(payload);
    setIsResendingCode(false);
    console.log(response);

    toast.success((response as any).message);
  };

  return (
    <MDBModal show={isOpen} staticBackdrop>
      <MDBModalDialog centered size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Two Factor Authentication</MDBModalTitle>
            <MDBBtn
              onClick={onModalClose}
              color="none"
              style={{ border: 'none' }}
            >
              <MDBIcon icon="times" />
            </MDBBtn>
          </MDBModalHeader>
          <MDBModalBody className="react-code-input-body">
            {!!errorMessage && (
              <MDBAlert
                show={!!errorMessage}
                className="w-100 p-2 rounded-1 mb-4 text-center"
                color="danger"
              >
                {errorMessage}
              </MDBAlert>
            )}
            <h5 className="font-weight-bold">
              A 4 digit code has been sent to {email}
            </h5>

            <ReactCodeInput
              disabled={isSubmitting}
              fields={4}
              inputMode="numeric"
              inputStyle={inputStyles}
              inputStyleInvalid={inputStyleInvalid}
              isValid={!errorMessage}
              name="sms"
              onChange={handlePinChange}
              type="number"
              value={otpCode}
              key={key}
            />

            <div className="two-factor-authentication-text">
              <p className="font-weight-semibold">
                • The code is valid for 5 minutes
              </p>
              <p className="two-factor-authentication-info-text">
                • Didn't receive the code?{' '}
                {isResendingCode ? (
                  <p className="resend-text">Sending...</p>
                ) : (
                  <p
                    className="resend-text"
                    onClick={onResendCodeClick}
                    tabIndex={0}
                  >
                    Resend
                  </p>
                )}
              </p>
            </div>
            <form>
              <Controller
                name="rememberFor7Days"
                control={control}
                render={({ field }) => (
                  <MDBCheckbox
                    {...field}
                    id="rememberForDays"
                    label="Remember me for 7 days"
                    onChange={(e: any) => {
                      field.onChange(e.target.checked);
                      if (e.target.checked) {
                        setCheck(true);
                      } else {
                        setCheck(false);
                      }
                    }}
                  />
                )}
              />
            </form>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default TwoFactorAuthenticationModal;
