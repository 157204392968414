import config from 'config';
import {
  ForgetPasswordType,
  IResendTwoFactorAuthenticationSchema,
  ITwoFactorAuthenticationSchema,
  LoginType,
  PasswordResetType,
} from 'types/auth';
import http from 'utils/http';

export async function login(data: LoginType) {
  const patients = await http({
    url: config.endpoints.auth.login,
    method: 'POST',
    data: data,
  });
  return patients.data;
}

export async function handleTwoFactorAuthentication(
  data: ITwoFactorAuthenticationSchema
) {
  const patients = await http({
    url: config.endpoints.auth.verifyTwoFactorCode,
    method: 'POST',
    data: data,
  });
  return patients.data;
}

export async function handleResendTwoFactorAuthentication(
  queryParams: IResendTwoFactorAuthenticationSchema
) {
  const response = await http({
    url: config.endpoints.auth.resendTwoFactorCode,
    method: 'GET',
    params: queryParams,
  });
  return response.data;
}

export async function resetPassword(data: PasswordResetType) {
  const response = await http({
    url: config.endpoints.auth.resetPassword,
    method: 'POST',
    data: data,
  });
  return response.data;
}

export async function forgetPassword(queryParams: ForgetPasswordType) {
  const response = await http({
    url: config.endpoints.auth.forgetPassword,
    method: 'GET',
    params: queryParams,
  });
  return response.data;
}
