import AppWrapper from 'components/common/AppWrapper';
import NotificationContainer from 'components/notification-messages/NotificationContainer';
import PatientDocument from 'components/patient-info/document';
import Messages from 'components/patient-info/messages';
import RouteRedirect from 'components/RouteRedirect';
import { uiRoutes } from 'constants/uiRoutes';
import withAuth from 'hocs/withAuth';
import withoutAuth from 'hocs/withoutAuth';
import AuthLayout from 'layouts/auth-layout';
import MainLayout from 'layouts/main-layout';
import PoliciesLayout from 'layouts/policies-layout';
import ReceiptLayout from 'layouts/receipt-layout';
import TabLayout from 'layouts/tab-layout';
import ActivityLog from 'pages/activity-log';
import ForgetPassword from 'pages/forget-password';
import Login from 'pages/login';
import Logout from 'pages/logout';
import MyProfile from 'pages/my-profile';
import Orders from 'pages/orders';
import PatientDetail from 'pages/patient-detail';
import PatientList from 'pages/patients';
import PrivacyPolicy from 'pages/privacy-policy';
import Receipt from 'pages/receipt';
import ResetPassword from 'pages/reset-password';
import TermsOfUse from 'pages/terms-of-use';
import TokenVerification from 'pages/token-verification';
import Transactions from 'pages/transactions';
import UserList from 'pages/users';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AppRoute from './AppRoute';

const RouterRoute = () => {
  return (
    <Router>
      <AppWrapper>
        <Switch>
          {/* Activity Logs */}
          <AppRoute
            exact
            path={uiRoutes.activityLog}
            layout={withAuth(MainLayout)}
            component={ActivityLog}
          />

          {/* Auth */}
          <AppRoute
            exact
            path={uiRoutes.login}
            layout={withoutAuth(AuthLayout)}
            component={Login}
          />
          <AppRoute
            exact
            path={uiRoutes.logout}
            layout={AuthLayout}
            component={Logout}
          />
          <AppRoute
            exact
            path={uiRoutes.forgotPassword}
            layout={AuthLayout}
            component={ForgetPassword}
          />
          <AppRoute
            exact
            path={uiRoutes.resetPassword}
            layout={AuthLayout}
            component={ResetPassword}
          />
          <AppRoute
            exact
            path={uiRoutes.setPassword}
            layout={AuthLayout}
            component={ResetPassword}
          />

          <AppRoute
            exact
            path={uiRoutes.myProfile}
            layout={withAuth(MainLayout)}
            component={MyProfile}
          />

          <AppRoute
            exact
            path={uiRoutes.patient.tab.survey}
            layout={withAuth(TabLayout)}
            component={PatientDetail}
          />
          <AppRoute
            exact
            path={uiRoutes.orders.patientDetailTabs.survey}
            layout={withAuth(TabLayout)}
            component={PatientDetail}
          />
          <AppRoute
            exact
            path={uiRoutes.patient.tab.message}
            layout={withAuth(TabLayout)}
            component={Messages}
          />
          <AppRoute
            exact
            path={uiRoutes.orders.patientDetailTabs.message}
            layout={withAuth(TabLayout)}
            component={Messages}
          />
          <AppRoute
            exact
            path={uiRoutes.patient.tab.document}
            layout={withAuth(TabLayout)}
            component={PatientDocument}
          />
          <AppRoute
            exact
            path={uiRoutes.orders.patientDetailTabs.document}
            layout={withAuth(TabLayout)}
            component={PatientDocument}
          />

          <AppRoute
            exact
            path={uiRoutes.orders.orders}
            layout={withAuth(MainLayout)}
            component={Orders}
          />
          {/* Patients */}
          <AppRoute
            exact
            path={uiRoutes.patient.patients}
            layout={withAuth(MainLayout)}
            component={PatientList}
          />
          <AppRoute
            exact
            path={uiRoutes.receipt}
            layout={ReceiptLayout}
            component={withAuth(Receipt)}
          />

          {/* Transactions */}
          <AppRoute
            exact
            path={uiRoutes.billings}
            layout={withAuth(MainLayout)}
            component={Transactions}
          />

          {/* Users */}
          <AppRoute
            exact
            path={uiRoutes.user.users}
            layout={withAuth(MainLayout)}
            component={UserList}
          />
          <AppRoute
            exact
            path={uiRoutes.user.verify}
            layout={AuthLayout}
            component={TokenVerification}
          />

          {/* Notifications */}
          <AppRoute
            exact
            path={uiRoutes.notifications}
            layout={withAuth(MainLayout)}
            component={NotificationContainer}
          />

          {/* Policies */}
          <AppRoute
            exact
            path={uiRoutes.privacyPolicy}
            layout={PoliciesLayout}
            component={PrivacyPolicy}
          />
          <AppRoute
            exact
            path={uiRoutes.termsOfUse}
            layout={PoliciesLayout}
            component={TermsOfUse}
          />

          <Route
            exact
            path={uiRoutes.main}
            component={withAuth(RouteRedirect)}
          />
        </Switch>
      </AppWrapper>
    </Router>
  );
};

export default RouterRoute;
