import Logo from 'assets/images/png/matrx-logo.png';
import { PERMISSIONS } from 'constants/permissions';
import {
  MDBIcon,
  MDBScrollbar,
  MDBSideNav,
  MDBSideNavItem,
  MDBSideNavLink,
} from 'mdb-react-ui-kit';
import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { store } from 'store';
import { AppContext } from 'types/appContext';
import { MenuType } from 'types/menu';
import { RoleType } from 'types/roles';
import FullPageSpinnner from '../full-page-spinner';
import './sidebar.scss';

interface Props {
  Header: React.ReactNode;
  Body: React.ReactNode;
}

const Sidebar = ({ Header, Body }: Props) => {
  const history = useHistory();
  const location = useLocation();

  const appContext = useContext(store);
  const { user, menus } = appContext as AppContext;

  if (!user) return <FullPageSpinnner />;

  const { role } = user;

  // const userPermissions = user?.permissions;

  // Different users have different roles and permissions
  // Some users may have access to a menu which others may not
  // The userPermissions comes in an array like ["User:W", "Patient:W"]
  // const accessibleMenus = menus?.filter((menu) =>
  //   userPermissions?.some((userPer) => userPer.includes(menu.name))
  // );

  const accessibleMenus = menus.filter((menu) =>
    PERMISSIONS[role as RoleType].includes(menu.name)
  );

  return (
    <>
      <MDBSideNav
        isOpen
        backdrop={false}
        mode="push"
        constant
        className="sidebar"
      >
        <img
          src={Logo}
          alt="logo"
          className="sidebar__brand-logo cursor-pointer"
          onClick={() => history.push('/')}
        />
        <MDBScrollbar
          sidenav
          tag="ul"
          suppressScrollX
          className="mt-3 h-auto px-0"
        >
          {accessibleMenus?.map((menu: MenuType) => (
            <MDBSideNavItem key={menu.name}>
              <Link to={menu.link}>
                <MDBSideNavLink
                  className={`sidebar__navlink ${
                    location.pathname?.includes(menu.link)
                      ? 'sidebar__navlink--active'
                      : ''
                  } `}
                >
                  <MDBIcon
                    far
                    icon={menu.icon}
                    className="fas me-3 sidebar__navlink-icon"
                  />
                  <span className="sidenav-non-slim">{menu.name}</span>
                </MDBSideNavLink>
              </Link>
            </MDBSideNavItem>
          ))}
        </MDBScrollbar>
      </MDBSideNav>
      <div className="d-flex">
        <div className="w-100">
          {Header}
          <main className="main-content pb-4">{Body}</main>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
