import Modal from 'components/common/modal/Modal';
import { MDBBtn, MDBIcon, MDBSpinner } from 'mdb-react-ui-kit';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  deleteUploadedDocument,
  fetchDocumentBufferData,
  shareDocumentToPatient,
} from 'services/document';
import { formatDate } from 'utils/date';
import downloadBinaryObject from 'utils/fileSaver';
import { parseHtml } from 'utils/parseHtml';
import './style.scss';

interface IProps {
  documentId: string;
  documentName: string;
  documentDate: string;
  setToggleFetchUploadedDocument: React.Dispatch<React.SetStateAction<boolean>>;
  isShared: boolean;
}

const UploadedDocumentList = ({
  documentName,
  documentDate,
  documentId,
  setToggleFetchUploadedDocument,
  isShared,
}: IProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] =
    useState<boolean>(false);
  const [openConfirmShareDialog, setOpenConfirmShareDialog] =
    useState<boolean>(false);
  const [
    openConfirmDownloadDocumentDialog,
    setOpenConfirmDownloadDocumentDialog,
  ] = useState<boolean>(false);
  const [bufferData, setBufferData] = useState<[]>([]);
  const [contentType, setContentType] = useState<string>('');
  const params: any = useParams();

  const toggleShowConfirmDeleteDialog = () => {
    setOpenConfirmDeleteDialog((prev) => !prev);
  };

  const toggleShowConfirmShareDialog = () => {
    setOpenConfirmShareDialog((prev) => !prev);
  };

  const toggleShowConfirmDownloadDocumentDialog = () => {
    setOpenConfirmDownloadDocumentDialog((prev) => !prev);
  };

  const deleteDocument = async () => {
    setIsSubmitting(true);
    try {
      const response: any = await deleteUploadedDocument(
        documentId,
        params?.userId
      );
      setToggleFetchUploadedDocument((prev) => !prev);
      toast.success(
        response?.message ||
          response?.response?.statusText ||
          'Document was deleted'
      );
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
      toggleShowConfirmDeleteDialog();
    }
  };
  const shareDocument = async () => {
    setIsSubmitting(true);
    const payload = {
      id: documentId,
      isShared: true,
    };
    try {
      if (!isShared) {
        const response: any = await shareDocumentToPatient(
          payload,
          params?.userId
        );
        setToggleFetchUploadedDocument((prev) => !prev);
        toast.success(
          response?.message ||
            response?.response?.statusText ||
            'Document was shared to Patient'
        );
      }
    } catch (err: any) {
      console.log(err);
      toast(err?.message || err?.response?.statusText || 'Network error');
    } finally {
      setIsSubmitting(false);
      toggleShowConfirmShareDialog();
    }
  };

  const downloadDocument = async () => {
    setIsSubmitting(true);
    try {
      const response: any = await fetchDocumentBufferData(
        documentId,
        params?.userId
      );
      setBufferData(response?.data?.bufferData);
      setContentType(response?.data?.contentType);

      downloadBinaryObject(bufferData, contentType, documentName);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
      toggleShowConfirmDownloadDocumentDialog();
    }
  };

  const ConfirmDeleteDialogBody = () => {
    return (
      <div className="p-4">
        <p>Are you sure you want to delete {documentName}?</p>
        <div className="d-flex justify-content-end">
          <MDBBtn color="white" onClick={toggleShowConfirmDeleteDialog}>
            Cancel
          </MDBBtn>
          <MDBBtn className="ml-2" onClick={deleteDocument}>
            {isSubmitting && <MDBSpinner size="sm" />}
            <span className={`d-inline-block ${isSubmitting ? 'ml-2' : ''}`}>
              Delete
            </span>
          </MDBBtn>
        </div>
      </div>
    );
  };

  const ConfirmShareDialogueBody = () => {
    return (
      <div className="p-4">
        <p>Are you sure you want to share {documentName}?</p>
        <div className="d-flex justify-content-end">
          <MDBBtn color="white" onClick={toggleShowConfirmShareDialog}>
            Cancel
          </MDBBtn>
          <MDBBtn className="ml-2" onClick={shareDocument}>
            {isSubmitting && <MDBSpinner size="sm" />}
            <span className={`d-inline-block ${isSubmitting ? 'ml-2' : ''}`}>
              Share
            </span>
          </MDBBtn>
        </div>
      </div>
    );
  };

  const ConfirmDownloadDocumentBody = () => {
    return (
      <div className="p-4">
        <p>Are you sure you want to download {documentName}?</p>
        <div className="d-flex justify-content-end">
          <MDBBtn
            color="white"
            onClick={toggleShowConfirmDownloadDocumentDialog}
          >
            Cancel
          </MDBBtn>
          <MDBBtn
            className="ml-2"
            onClick={() => {
              downloadDocument();
            }}
          >
            {isSubmitting && <MDBSpinner size="sm" />}
            <span className={`d-inline-block ${isSubmitting ? 'ml-2' : ''}`}>
              Download
            </span>
          </MDBBtn>
        </div>
      </div>
    );
  };

  return (
    <>
      <tr>
        <td>
          <div
            onClick={toggleShowConfirmDownloadDocumentDialog}
            className="cursor-pointer "
          >
            {parseHtml(documentName, 60)}
          </div>
        </td>
        <td>{formatDate(documentDate)}</td>
        <td>
          {!isShared ? (
            <span
              className="document-action-button"
              onClick={toggleShowConfirmShareDialog}
            >
              Share
            </span>
          ) : (
            'Shared'
          )}
        </td>
        <td>
          <span
            className="cursor-pointer"
            onClick={toggleShowConfirmDeleteDialog}
            style={{ color: 'firebrick' }}
          >
            <MDBIcon icon="trash-alt" far size="lg" />
          </span>
        </td>
      </tr>
      <Modal
        isOpen={openConfirmDeleteDialog}
        title="Delete Document"
        onClose={toggleShowConfirmDeleteDialog}
        children={<ConfirmDeleteDialogBody />}
      />
      <Modal
        isOpen={openConfirmShareDialog}
        title="Share Document"
        onClose={() => {
          toggleShowConfirmShareDialog();
        }}
        children={<ConfirmShareDialogueBody />}
      />
      <Modal
        isOpen={openConfirmDownloadDocumentDialog}
        title="Download Document"
        onClose={toggleShowConfirmDownloadDocumentDialog}
        children={<ConfirmDownloadDocumentBody />}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default UploadedDocumentList;
