import Modal from 'components/common/modal/Modal';
import {
  MDBBtn,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
} from 'mdb-react-ui-kit';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as userService from 'services/user';
import { createUser, updateUser } from 'services/user';
import { store } from 'store';
import { AppContext } from 'types/appContext';
import { UserFormType, UserType } from 'types/user';
import { formatDate, parseAndFormatDate } from 'utils/date';
import { sanitize } from 'utils/lodash';
import UserForm from './UserForm';

const defaultValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  phone: '',
  dob: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  role: '',
};

interface IProps {
  isOpen: boolean;
  onClose: VoidFunction;
  userId?: string;
  handleUserAddSuccess: (addedUser: UserType) => void;
  handleUserUpdateSuccess: (userId: string, updatedUser: UserType) => void;
}

const UserAddEditModal = ({
  isOpen,
  onClose,
  userId,
  handleUserAddSuccess,
  handleUserUpdateSuccess,
}: IProps) => {
  const [user, setUser] = useState<UserType | null>(null);

  const isEditMode = !!userId;

  const methods = useForm<UserFormType>({ defaultValues });
  const { reset, getValues } = methods;

  const appContext = useContext(store);
  const { userRoleOptions } = appContext as AppContext;

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Fetch user detail on edit mode
   */
  useEffect(() => {
    const fetchUserDetail = async (id: string) => {
      setIsLoading(true);
      const response: any = await userService.fetchUser(id);
      const data = response.data;
      setUser(data);
      setIsLoading(false);
    };
    if (userId) {
      fetchUserDetail(userId);
    }
  }, [userId, methods]);

  useEffect(() => {
    if (user) {
      reset({
        ...getValues(),
        firstName: user.firstName || '',
        middleName: user.middleName || '',
        lastName: user.lastName || '',
        email: user.email || '',
        phone: user.phone || '',
        dob: user.dob ? formatDate(user.dob) : '',
        addressLine1: user.addressLine1 || '',
        addressLine2: user.addressLine2 || '',
        city: user.city || '',
        state: user.state || '',
        zipCode: user.zipCode || '',
        role: user.roles?.[0]?.roleId || '',
      });
    }
  }, [user]);

  const onSubmit = async (data: UserFormType) => {
    const roleId = data.role;
    const roleName = userRoleOptions?.find(
      (role) => role.value === roleId
    )?.text;
    const dataToSubmit: any = {
      ...data,
      dob: data.dob
        ? parseAndFormatDate({
            date: data.dob,
            parseFormat: 'MM/dd/yyyy',
            outputformat: 'yyyy-MM-dd',
          })
        : '',
    };
    delete dataToSubmit.role;

    // Trim the form data and remove ones that are empty
    const cleanedDataToSubmit: any = sanitize(dataToSubmit);

    cleanedDataToSubmit.roles = [
      {
        roleId,
        name: roleName,
      },
    ];

    cleanedDataToSubmit.dob = cleanedDataToSubmit.dob || null;

    try {
      setIsSubmitting(true);

      if (isEditMode) {
        const response: any = await updateUser(userId!, cleanedDataToSubmit);
        toast.success(response.message);
        resetForm();
        handleUserUpdateSuccess(userId, response?.data?.updatedData);
      } else {
        const response: any = await createUser(cleanedDataToSubmit);
        toast.success(response.message);
        resetForm();
        handleUserAddSuccess(response?.data);
      }

      // Close modal after 1 sec
      // Flickering issue exists when the modal is closed and toast is displayed simultaneously
      setTimeout(() => {
        onClose();
      }, 1000);
    } catch (err: any) {
      toast.error(err?.response?.data?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    methods.reset(defaultValues);
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={isEditMode ? 'Edit User' : 'Add User'}
      icon={isEditMode ? 'user-edit' : 'user-plus'}
      size="lg"
    >
      {isLoading ? (
        <div className="d-flex h-200px justify-content-center align-items-center text-muted">
          <MDBSpinner color="primary">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        </div>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <MDBModalBody>
              <UserForm />
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="link"
                type="button"
                onClick={handleClose}
                disabled={isSubmitting}
              >
                Cancel
              </MDBBtn>
              <MDBBtn
                type="submit"
                color="secondary"
                className="text-dark"
                disabled={isSubmitting}
              >
                {isSubmitting && <MDBSpinner size="sm" />}
                <span
                  className={`d-inline-block ${isSubmitting ? 'ml-2' : ''}`}
                >
                  Save
                </span>
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </FormProvider>
      )}
    </Modal>
  );
};

UserAddEditModal.defaultProps = {
  userId: '',
};

export default UserAddEditModal;
