import config from 'config';
import http from 'utils/http';

export async function fetchApplicationIds() {
  const applicationsIds = await http({
    url: config.endpoints.application.getAllIds,
    method: 'GET',
  });
  return applicationsIds.data;
}

export async function fetchShippingMethods() {
  const applicationsIds = await http({
    url: config.endpoints.application.getShippingMethods,
    method: 'GET',
  });
  return applicationsIds.data;
}
