import { MDBInput } from 'mdb-react-ui-kit';
import { RefObject, useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import './googleAutocomplete.scss';

interface Props {}

let autoComplete: any;

const loadScript = (url: string, callback: () => void) => {
  let script: any = document.createElement('script');
  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

function handleScriptLoad(
  autoCompleteRef: RefObject<any>,
  callbackFunc: (addressObj: any) => void
) {
  const searchOptions = {
    types: ['geocode'],
    componentRestrictions: { country: 'us' },
    fields: ['address_components', 'formatted_address', 'geometry.location'],
  };
  autoComplete = new (window as any).google.maps.places.Autocomplete(
    autoCompleteRef.current,
    searchOptions
  );
  autoComplete.setFields(['address_components', 'formatted_address']);
  autoComplete.addListener('place_changed', () =>
    handlePlaceSelect(callbackFunc)
  );
}

async function handlePlaceSelect(callbackFunc: (addressObj: any) => void) {
  const addressObject = autoComplete.getPlace();
  callbackFunc(addressObject);
}

function GoogeAutocomplete(props: Props) {
  const { control, setValue } = useFormContext();

  const autoCompleteRef = useRef(null);

  useEffect(() => {
    const populateFields = (addressObj: any) => {
      const address = addressObj?.address_components;
      if (!address) return;
      const addr = {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
      };
      address.forEach((x: any) => {
        if (x.types[0] === 'street_number' || x.types[0] === 'route') {
          addr.addressLine1 += `${x.long_name} `;
        }
        if (x.types[0] === 'locality') {
          addr.city = `${x.long_name}`;
        }
        if (x.types[0] === 'administrative_area_level_1') {
          addr.state = `${x.short_name}`;
        }
        if (x.types[0] === 'postal_code') {
          addr.zipCode = `${x.long_name}`;
        }
      });
      setValue('addressLine1', addr.addressLine1?.trim());
      setValue('addressLine2', addr.addressLine2?.trim());
      setValue('city', addr.city);
      setValue('state', addr.state);
      setValue('zipCode', addr.zipCode);
    };

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyCo0hj8XUcjtwn3icKS041OZJy6d1_QfGc&libraries=places`,
      () => handleScriptLoad(autoCompleteRef, populateFields)
    );
  }, [setValue]);

  return (
    <div className="search-location-input">
      <Controller
        control={control}
        name="addressLine1"
        rules={{
          required: false,
        }}
        render={({ field }) => (
          <MDBInput
            {...field}
            inputRef={autoCompleteRef}
            label="Address Line 1"
            id="address-line-1"
            type="text"
          />
        )}
      />
    </div>
  );
}
export default GoogeAutocomplete;
