import { DATE } from 'constants/index';
import { ActivityLogType } from 'types/activityLog';
import { formatDate } from 'utils/date';

const { DATE_TIME_VIEW_FORMAT } = DATE;

interface Props {
  log: ActivityLogType;
}

const ActivityLogListItem = ({ log }: Props) => {
  return (
    <tr className="font-weight-normal" key={log._id}>
      <td>
        {log.createdAt ? formatDate(log.createdAt, DATE_TIME_VIEW_FORMAT) : '-'}
      </td>
      <td>{log.user.name}</td>
      <td>
        <span className="font-weight-bold d-block">
          {log.event?.type}: {log.log?.module}
        </span>
        <span className="d-block">{log.log?.note}</span>
      </td>
      <td>{log.client?.applicationName}</td>
    </tr>
  );
};

export default ActivityLogListItem;
