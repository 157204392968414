export enum OrderStatus {
  NEW = 'New',
  SUBMITTED = 'Submitted',
  E_PRESCRIBED = 'e-Prescribed',
  READY_FOR_SHIPPING = 'Ready for Shipping',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  FAILED = 'Failed',
}
