import config from 'config';
import { isValid, parse } from 'date-fns';
import { enUS } from 'date-fns/locale';

/**
 * Validate an email address
 * @param {String} email
 * @return {Boolean}
 */
export function validateEmail(email: string): boolean {
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

/**
 * Validate if the given string is a valid date or not
 *
 * @param {String} date
 * @param {String} dateFormat Use valid date-fns format
 * @returns {Boolean} true if valid
 */
export function validateDate(
  date: string,
  dateFormat: string = config.INPUT_DATE_FORMAT
): boolean {
  if (!date) return false;

  const parsedDate = parse(date, dateFormat, new Date(), { locale: enUS });
  return isValid(parsedDate);
}
