import { AddressType, GenericAddressType } from 'types/address';
import { MDBSelectEvent } from 'types/mdb';
import { PatientStatusType } from 'types/patientStatus';
import { RoleType, UserRoleType } from 'types/roles';
import { convertToTitleCase } from './string';

export const formatPhone = (phone: any) => {
  if (phone) {
    const x = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    return phone ? phone : 'N/A';
  } else {
    return 'N/A';
  }
};

export const getAddressDisplayName = (
  address: GenericAddressType | AddressType,
  singleLine = false
) => {
  if (
    !address ||
    (!address.addressLine1 &&
      !address.city &&
      !address.state &&
      !address.zipCode)
  )
    // && !address.addressLine2
    return 'N/A';
  if (singleLine)
    return `${address.addressLine1 ? address.addressLine1 + ', ' : ''}${
      address.addressLine2 ? address.addressLine2 + ', ' : ''
    }${address.city ? address.city + ', ' : ''}${
      address.state ? address.state + ' ' : ''
    }${address.zipCode}`;
  return (
    <span>
      {address.addressLine1 && (
        <span>
          {address.addressLine1}
          {address.city && <br />}
        </span>
      )}

      {address.city}
      {(address.state || address.zipCode) && (
        <span>
          ,{address.city && ' '}
          {!address.city && <br />}
        </span>
      )}
      {address.state}
      {address.state && ' '}
      {address.zipCode}
    </span>
  );
};

/**
 * Generates mdb select options for patient status.
 *
 * @param {PatientStatusType[]} array
 * @returns {MDBSelectEvent}
 */
export const generatePatientStatusOptions = (
  array: PatientStatusType[]
): MDBSelectEvent[] => {
  return array.map((item) => ({
    value: item.id,
    text: convertToTitleCase(item.name),
  }));
};

/**
 * Generates mdb select options for user roles.
 *
 * @param {UserRoleType[]} array
 * @returns {MDBSelectEvent}
 */
export const generateUserRolesOptions = (
  array: UserRoleType[]
): MDBSelectEvent[] => {
  return array?.map((item) => ({
    value: item._id,
    text: convertToTitleCase(item.name),
  }));
};

/**
 *
 * Display payment gateway info
 *
 * @example
 * inputs -> id: '357558141980', type: 'FirstData'
 * output -> F357558141980
 *
 * @param {string} gatewayReferenceId :: gatewayReferenceId
 * @param {string} gatewayType :: gatewayType
 * @returns
 */
export const displayGatewayinfo = (
  gatewayReferenceId: string
  // gatewayType: string
) => {
  return gatewayReferenceId;
  // ? `${gatewayType ? gatewayType.substring(0, 1) : ''}${gatewayReferenceId}`
  // : '';
};

export const formatCurrency = (
  value: string | number,
  elseNull = true,
  min = 2,
  max = 2
): any => {
  return value || value === 0
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: min,
        maximumFractionDigits: max,
      }).format(value as number)
    : elseNull
    ? null
    : formatCurrency(0);
};

/**
 * Get full name
 *
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} middleName
 */
export const getFullName = (
  firstName?: string,
  lastName?: string,
  middleName?: string
): string => {
  if (!firstName || !lastName) return '';
  return `${firstName}${middleName ? ` ${middleName} ` : ' '}${lastName}`;
};

/**
 * Check if the user to take an action or not
 *
 * @param {RoleType} role
 * @returns {boolean}
 */
export const hasActionAccess = (role: RoleType) => {
  // HardCoded
  if (role === 'Patient') return false;
  return true;
};

export const getFirstLastName = (name: string) => {
  const splitName = name.split(' ');

  return splitName?.length === 2
    ? { firstName: splitName[0], lastName: splitName[1] ?? '' }
    : { firstName: splitName[0], lastName: splitName[2] ?? '' };
};
