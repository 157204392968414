/**
 * Check if object is empy
 *
 * @param {any} obj :: Object to check if it's empty
 * @returns {boolean}
 */
export const isObjEmpty = (obj: any) => {
  return !(
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

/**
 * Get full name
 *
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} middleName
 */
export const getFullName = (
  firstName?: string,
  lastName?: string,
  middleName?: string
): string => {
  const fName = firstName || '';
  const mName = middleName || '';
  const lName = lastName || '';

  const fullName = `${fName}${mName ? ` ${mName} ` : ' '}${lName}`;

  return fullName.trim();
};
