import config from 'config';
import http from 'utils/http';

export async function fetchTransactions(queryParams: any) {
  const transactions = await http({
    url: config.endpoints.transactions.getAll,
    method: 'GET',
    params: queryParams,
  });
  return transactions.data;
}

export async function fetchTransaction(transactionId: string) {
  const transactions = await http({
    url: config.endpoints.transactions.getOne.replace(':id', transactionId),
    method: 'GET',
  });
  return transactions.data;
}

export async function refundTransaction(transactionId: string, data: unknown) {
  const transactions = await http({
    url: config.endpoints.transactions.refund.replace(
      ':id',
      transactionId.toString()
    ),
    method: 'PUT',
    data,
  });
  return transactions.data;
}
