import config from 'config';
import http from 'utils/http';

export async function fetchRoles(queryParams?: any) {
  const response = await http({
    url: config.endpoints.roles.getAll,
    method: 'GET',
    params: queryParams,
  });
  return response.data;
}
