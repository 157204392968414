import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { get } from 'utils/storage';

function withoutAuth(Component: any) {
  function AuthComponent(props: any) {
    const history = useHistory();

    const location = useLocation();

    useEffect(() => {
      const userToken = get('local', 'userToken');
      if (userToken) {
        // history.push(`/login?redirect=${location.pathname}`);
        history.push(`/patients`);
        return;
      }
    }, [history, location]);

    return <Component {...props} />;
  }

  return AuthComponent;
}

export default withoutAuth;
