import MainLayout from 'layouts/main-layout';
import React from 'react';
import PatientTabLayout from './patient-tab-layout';

const TabLayout = ({ children }: { children: any }) => {
  return (
    <MainLayout>
      <PatientTabLayout>{children}</PatientTabLayout>
    </MainLayout>
  );
};

export default TabLayout;
