import ModalWithRef from 'components/common/modal/ModalWithRef';
import {
  MDBAlert,
  MDBBtn,
  MDBModalBody,
  MDBModalContent,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import React, { RefObject } from 'react';

interface Props {}

const PrescriptionNotAvailableModal = React.forwardRef((props: Props, ref) => {
  const toggleModal = () => {
    (ref as RefObject<any>)?.current?.toggle?.();
  };

  return (
    <ModalWithRef ref={ref} title="Add Prescription" icon="prescription">
      <MDBModalContent>
        <MDBModalBody>
          <MDBAlert show className="w-100" color="warning">
            <h5 className="alert-heading">Prescription not available</h5>
            <p className="text-base">
              It seems like payment is either failed or not processed. e
              Prescription can only be created after successful payment.
            </p>
            <hr />
            <p className="mb-0 text-sm">
              Please contact administrator if you have any questions.
            </p>
          </MDBAlert>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="link" type="button" onClick={toggleModal}>
            Close
          </MDBBtn>
        </MDBModalFooter>
      </MDBModalContent>
    </ModalWithRef>
  );
});

export default PrescriptionNotAvailableModal;
