import { MDBSelect } from 'mdb-react-ui-kit';
import './pageLimitSelect.scss';

interface Props {
  limit: number;
  handleLimitChange: (limit: number) => void;
}

const PageLimitSelect = (props: Props) => {
  const { limit, handleLimitChange } = props;
  return (
    <div className="page-limit-select">
      <div className="page-limit-select__text-wrapper">
        <h6 className="mb-0">Show</h6>
      </div>
      <MDBSelect
        className="page-limit-select__field-wrapper"
        data={[
          { text: '10', value: 10, selected: limit === 10 },
          { text: '25', value: 25, selected: limit === 25 },
          { text: '50', value: 50, selected: limit === 50 },
          { text: '100', value: 100, selected: limit === 100 },
          { text: '250', value: 250, selected: limit === 250 },
          { text: '500', value: 500, selected: limit === 500 },
        ]}
        getValue={(e: { text: string; value: number }) =>
          handleLimitChange(e.value)
        }
      />
    </div>
  );
};

export default PageLimitSelect;
