/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'stores';
import { IMessage } from 'types/message';

interface MessageState {
  messages: IMessage[] | [];
}

const initialState: MessageState = {
  messages: [],
};

export const slice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    setMessages: (state, action: PayloadAction<IMessage[] | null>) => {
      state.messages = action.payload ?? [];
    },
    setMessage: (state, action: PayloadAction<IMessage>) => {
      if (!state.messages) return;
      state.messages.push(action.payload as never);
    },
    clearMessages: (state: any) => {
      state.messages = [];
    },
  },
});

// Actions
export const { setMessages, setMessage, clearMessages } = slice.actions;

// Selectors
export const selectMessages = (state: RootState) => state.messages.messages;

// Reducer
export default slice.reducer;
