import LoadingIndicator from 'components/common/LoadingIndicator';
import { PatientStatusType } from 'enums/patient';
import { MDBCard, MDBCardBody, MDBIcon } from 'mdb-react-ui-kit';
import colors from 'styles/constants/colors';
import { PatientDetailType } from 'types/patient';
import { formatDate } from 'utils/date';
import { getAddressDisplayName, getFullName } from 'utils/npssUtil';
import './patientInfo.scss';

interface Props {
  detail?: PatientDetailType | undefined;
  isLoading?: boolean;
}

const PatientInfo = (props: Props) => {
  const { detail } = props;
  const { UNVERIFIED, VERIFIED, IN_PROGRESS } = PatientStatusType;

  const ListItem = ({ icon, text }: { icon: string; text: string }) => (
    <div
      className="d-flex flex-row align-items-center"
      style={{
        maxWidth: '80vw',
      }}
    >
      <div>
        <MDBIcon
          far
          icon={icon}
          className="fas me-3"
          style={{ color: colors.neutral.default }}
        />
      </div>
      <div className="patientinfo-phone">{text}</div>
    </div>
  );

  return (
    <MDBCard
      shadow="1"
      background="white"
      className="rounded-1 mb-2"
      alignment="start"
    >
      {props.isLoading ? (
        <LoadingIndicator />
      ) : (
        <MDBCardBody
          style={{
            padding: 0,
          }}
        >
          <div className="d-flex flex-row p-4">
            <div className="d-flex flex-row flex-wrap">
              <div className="d-flex flex-row align-items-center me-5 mb-3">
                <img
                  src="https://thumbs.dreamstime.com/b/default-avatar-profile-icon-social-media-user-vector-image-icon-default-avatar-profile-icon-social-media-user-vector-image-209162840.jpg"
                  className="img-fluid flex-1"
                  style={{
                    borderRadius: 40,
                    height: 80,
                    width: 80,
                  }}
                  alt=""
                />
                <div className="ml-4" style={{ maxWidth: 200 }}>
                  <p className="patientinfo-fullname mb-2">
                    {getFullName(
                      detail?.firstName,
                      detail?.lastName,
                      detail?.middleName
                    )}
                  </p>
                  <div
                    className="d-inline-flex px-3 py-1"
                    style={{
                      color: colors.green.dark,
                      borderRadius: 50,
                      backgroundColor: colors.green.lime,
                      textAlign: 'center',
                    }}
                  >
                    {[UNVERIFIED, VERIFIED, IN_PROGRESS].includes(
                      detail?.status as PatientStatusType
                    )
                      ? 'Incomplete'
                      : detail?.status}
                  </div>
                </div>
              </div>
              <div>
                <ListItem icon="phone-alt" text={detail?.phone ?? ''} />
                <ListItem icon="envelope" text={detail?.email ?? ''} />
                <ListItem
                  icon="calendar"
                  text={formatDate(detail?.dob, 'MM/dd/yyyy')}
                />
              </div>
              <div style={{ paddingLeft: '20px' }}>
                {detail?.gender ? (
                  <ListItem icon="user" text={detail?.gender ?? ''} />
                ) : null}

                {detail?.formattedAddress?.billingAddress[0] ? (
                  <td className="d-flex">
                    <MDBIcon
                      far
                      icon="map-marker-alt"
                      className="fas me-3 mt-1"
                      style={{ color: colors.neutral.default }}
                    />
                    <div>
                      {detail?.formattedAddress.billingAddress[0]?.addressLine1
                        ? getAddressDisplayName(
                            detail?.formattedAddress.billingAddress[0]
                          )
                        : '-'}
                    </div>
                  </td>
                ) : null}
              </div>
            </div>
          </div>
        </MDBCardBody>
      )}
    </MDBCard>
  );
};

export default PatientInfo;
