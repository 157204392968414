// import {_debounce} from 'utils/lodash';
import { useMemo } from 'react';
import { _debounce } from 'utils/lodash';
import './search.scss';

const DEBOUNCE_WAIT = 300;

interface Props {
  isDisabled?: boolean;
  performGenericSearch: (keyword: string) => void;
  children?: React.ReactNode;
}

const Search = (props: Props) => {
  const { isDisabled = false, performGenericSearch } = props;

  const debouncedOnChangeHandler = useMemo(() => {
    const onChangeHandler = (e: React.FormEvent<HTMLInputElement>): void => {
      const keyword = (e.target as HTMLInputElement).value?.trim();
      performGenericSearch(keyword);
    };
    return _debounce(onChangeHandler, DEBOUNCE_WAIT);
  }, [performGenericSearch]);

  return (
    <div className="form-inline search-container">
      <input
        className="form-control search-container__input"
        type="text"
        placeholder="Search"
        aria-label="Search"
        onChange={debouncedOnChangeHandler}
        disabled={isDisabled}
      />
      {props.children}
    </div>
  );
};

export default Search;
