import config from 'config';
import { differenceInYears, format, isSameDay, parse, subDays } from 'date-fns';
import { enUS } from 'date-fns/locale';

export const getFullDateFormat = (date: string) => {
  return format(new Date(date), 'EEEE d MMMM Y');
};

export const formatDate = (date: any, dateFormat = 'MM/dd/yyyy') => {
  try {
    if (!date) return '-';
    return format(new Date(date), dateFormat);
  } catch (err) {
    return 'N/A';
  }
};

export const formatDateTime = (
  date: any,
  dateTimeFormat = 'MM/dd/yyyy h:mm a'
) => {
  if (!date) return '-';
  return format(new Date(date), dateTimeFormat);
};

/**
 * Parse date from string. Gives date-fns formatted date
 *
 * @param {String} date
 * @param {String} dateFormat Input date format; Use valid date-fns format
 * @param {String} displayDateFormat Display date format; Use valid date-fns format
 * @returns {Date}
 */
export function parseAndFormatDate({
  date,
  parseFormat = config.INPUT_DATE_FORMAT,
  outputformat = config.INPUT_DATE_FORMAT,
}: {
  date: string;
  parseFormat?: string;
  outputformat?: string;
}) {
  const parsedDate = parse(date, parseFormat, new Date(), { locale: enUS });
  return format(parsedDate, outputformat);
}

/**
 * Calculates age based on dob input
 *
 * @param {String} dob
 * @return {number}
 */
export function calculateAge(
  dob: string,
  dateFormat: string = config.INPUT_DATE_FORMAT
): number {
  const date = parse(dob, dateFormat, new Date(), { locale: enUS });
  return differenceInYears(new Date(), date);
}

export const compareDayFromDate = (dateLeft: Date, dateRight: Date) => {
  return isSameDay(dateLeft, dateRight);
};

export const isSameDate = (date: string, dateFormat = 'yyyy-MM-dd') => {
  return (
    date?.slice(0, 10) === formatDate(new Date(), dateFormat)?.slice(0, 10)
  );
};

export const isDayBefore = (date: string, dateFormat = 'yyyy-MM-dd') => {
  return format(subDays(new Date(), 1), dateFormat).slice(0, 10) === date;
};
