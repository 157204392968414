import { POLICIES_IFRAME_SOURCE } from 'constants/index';

interface Props {}

const PrivacyPolicy = (props: Props) => {
  return (
    <iframe
      title="MATRx | Privacy Policy"
      src={POLICIES_IFRAME_SOURCE.privacyPolicy}
      width="100%"
      height="100%"
    />
  );
};

export default PrivacyPolicy;
