import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IComposeError, IResponse } from 'types/http';

// TODO: Using any for now
function useAPIService<S>({ api }: { api: any }) {
  const initialMount = useRef(true);

  const [data, setData] = useState<IResponse<S> | null>(null);
  const [error, setError] = useState<IComposeError | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await api();
      setData(res);
    } catch (err: any) {
      setError(err as IComposeError);
      toast(err?.message || err?.response?.statusText || 'Network error');
    } finally {
      setLoading(false);
    }
  }, [api, toast]);

  useEffect(() => {
    if (initialMount.current) {
      fetchData();
    }
    return () => {
      initialMount.current = false;
    };
  }, [fetchData]);

  return { data, error, loading };
}

export default useAPIService;
