import ActivityLogListItem from 'components/activity-logs/ActivityLogListItem';
import Chip from 'components/common/chip';
import FullPageSpinnner from 'components/common/full-page-spinner';
import PageLimitSelect from 'components/common/page-limit-select';
import Pagination from 'components/common/pagination';
import Search from 'components/common/search';
import TableSkeletonLoader from 'components/common/skeleton-loader/TableSkeletonLoader';
import { MENUS } from 'constants/menus';
import {
  MDBBtn,
  MDBIcon,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdb-react-ui-kit';
import { useContext, useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { FormProvider, useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { fetchActivityLogs } from 'services/activityLog';
import { fetchApplicationIds } from 'services/application';
import { store } from 'store';
import { ActivityLogFilterType, ActivityLogType } from 'types/activityLog';
import { AppContext } from 'types/appContext';
import { ApplicationType } from 'types/application';
import { MDBSelectEvent } from 'types/mdb';
import { RoleType } from 'types/roles';
import { formatDate } from 'utils/date';
import { convertCamelCaseToTitleCase } from 'utils/string';
import './style.scss';

const timeStampColStyles = {
  width: '250px',
};

const userColStyles = {
  width: '400px',
};

const actionColStyles = {
  width: '700px',
};

const defaultValues = {
  userName: '',
  module: '',
  eventType: '',
  applicationId: '',
};

interface Props {}

const ActivityLog = (props: Props) => {
  const appContext = useContext(store);
  const { user } = appContext as AppContext;

  const methods = useForm<ActivityLogFilterType>({ defaultValues });

  /** Reference to the advanced search popover toggler button.
   *  Used to close the advanced search popover when the `Apply` button is clicked.
   */
  const advancedSearchRef = useRef<HTMLButtonElement>();

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  /** Handles loading state for the component */
  const [isLoading, setIsLoading] = useState<boolean>(true);

  /** List of patients fetched */
  const [logs, setLogs] = useState<ActivityLogType[]>([]);

  /** Stores the search keyword (for generic search) */
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  /** Pagination */
  const [pagination, setPagination] = useState<any>({
    currentPage: 1,
    pageNeighbours: 2,
    limit: 10,
    total: 0,
    offset: 0,
  });

  /** Stores the filters (for advanced search) */
  const [filters, setFilters] = useState<ActivityLogFilterType>(defaultValues);

  /** Toggle variable for refresh. The fetch is performed when the value is toggled */
  const [refreshFlagToggle, setRefreshFlagToggle] = useState<boolean>(false);

  /** Stores a list of application id and name. Used for activity log filter  */
  const [applicationOptions, setApplicationOptions] = useState<
    MDBSelectEvent[]
  >([]);

  // for showing 11-20 of 40 (11 -> firstCount, 20-> -> lastCount)
  const firstCount = (pagination.currentPage - 1) * pagination.limit + 1;
  const lastCount =
    (pagination.currentPage - 1) * pagination.limit + logs?.length;

  /**
   * Side effect responsible for fetching activity logs with required query params
   */
  useEffect(() => {
    const chooseFilterParams = () => {
      let filterParams: any = {};
      if (filters.userName) filterParams.userName = filters.userName;
      if (filters.module) filterParams.module = filters.module;
      if (filters.eventType) filterParams.eventType = filters.eventType;
      if (filters.applicationId)
        filterParams.applicationId = filters.applicationId;
      /**
       *  Responsible for fetching activity logs with required startdate and enddate query params
       */
      if (startDate) filterParams.startDate = formatDate(startDate);
      if (endDate) filterParams.endDate = formatDate(endDate);
      return filterParams;
    };

    const getPatients = async () => {
      setIsLoading(true);
      let queryParams: any = {
        offset: pagination.offset,
        limit: pagination.limit,
      };
      if (searchKeyword) queryParams.keyword = searchKeyword;
      const filterParams = chooseFilterParams();
      queryParams = { ...queryParams, ...filterParams };
      const response: any = await fetchActivityLogs(queryParams);
      setLogs(response?.data?.activities);
      setPagination((prevState: any) => ({
        ...prevState,
        total: response.data.count,
      }));
      setIsLoading(false);
    };
    getPatients();
    // setDateRange([null, null]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.currentPage,
    pagination.offset,
    pagination.limit,
    searchKeyword,
    filters,
    refreshFlagToggle,
    endDate,
  ]);

  /**
   * Fetch application ids
   */
  useEffect(() => {
    const getApplicationIds = async () => {
      const response: any = await fetchApplicationIds();
      setApplicationOptions(
        response?.data?.map((item: ApplicationType) => ({
          text: item.applicationName,
          value: item.applicationId,
          selected: false,
        }))
      );
    };

    getApplicationIds();
  }, []);

  /**
   * Page change handler. Updates the offset according to new page.
   *
   * @param {number} currentPage
   */
  const handlePageChange = (currentPage: number) => {
    const pageOffset = (currentPage - 1) * pagination.limit;
    setPagination((prevState: any) => ({
      ...prevState,
      offset: pageOffset,
      currentPage: currentPage,
    }));
  };

  /**
   * Limit change handler. Updates the limit, offset and currentPage according to the new limit
   *
   * @param {number} newLimit
   */
  const handleLimitChange = (newLimit: number) => {
    if (pagination.limit === newLimit) return;
    setPagination((prevState: any) => ({
      ...prevState,
      offset: 0,
      currentPage: 1,
      limit: newLimit,
    }));
  };

  /**
   * Handles search by keyword
   *
   * @param {string} keyword
   */
  const performGenericSearch = (keyword: string) => {
    setSearchKeyword(keyword);
    handlePageChange(1); // need to start from page 1 when there is a search
  };

  /**
   * Handles refresh. Toggles the `refreshFlagToggle` variable.
   */
  const handleRefresh = () => {
    setRefreshFlagToggle((prevState) => !prevState);
  };

  /**
   * Closes the popover used for advanced search
   * Achieves the action by triggering the click event of popover toggler btn with ref
   */
  const closeAdvancedSearchPopover = (): void => {
    typeof advancedSearchRef.current?.click === 'function' &&
      advancedSearchRef.current.click();
  };

  /**
   * Resets the filters. Also, the RHF form values are reset.
   */
  // const handleResetFilters = () => {
  //   methods.reset(defaultValues);
  //   setFilters(defaultValues);
  // };

  /**
   * Form submit handler for Advanced search form.
   * Sets the filters with new filters and the side effect fetches the new patient list
   *
   * @param {ActivityLogFilterType} data
   */
  const applyFilters = (data: ActivityLogFilterType) => {
    setFilters(data);
    handlePageChange(1); // need to start from page 1 when there is a search
    closeAdvancedSearchPopover();
  };

  /**
   * Handles removal of applied filter
   * RHF form value of the filter is also reset (set to '')
   *
   * @param {keyof ActivityLogFilterType} filterKey
   */
  const removeFilter = (filterKey: keyof ActivityLogFilterType) => {
    methods.setValue(filterKey, '');
    setFilters((prevState) => ({ ...prevState, [filterKey]: '' }));
  };

  /**
   * Variable to check if any advanced filters is applied or not.
   * The applied filters section is shown based on this variable.
   *
   */
  const isFilterApplied = Object.keys(filters)?.some(
    (filterKey) => filters[filterKey as keyof ActivityLogFilterType] !== ''
  );

  if (!user) {
    return <FullPageSpinnner />;
  }

  const isAccessible = MENUS.find(
    (item: any) => item.name === 'Activities'
  )?.access.includes(user.role as RoleType);

  if (!isAccessible) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="patient-list">
      {/* Search */}
      <div className="mb-3 w-100 d-flex justify-content-between align-items-center">
        <div className="d-flex">
          <Search performGenericSearch={performGenericSearch}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(applyFilters)}>
                {/* <AdvancedSearch
                togglerRef={
                  advancedSearchRef as React.MutableRefObject<HTMLButtonElement>
                }
                applicationOptions={applicationOptions}
                handleResetFilters={handleResetFilters}
              /> */}
              </form>
            </FormProvider>
          </Search>
          <div className="react-date-picker-container">
            <ReactDatePicker
              className="datepicker_inputfield"
              placeholderText="Select Date Range"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update: any) => {
                setDateRange(update);
              }}
            />
            <div className="datepicker-calendar-icon">
              <MDBIcon icon="calendar" far />
            </div>
            <div className="ml-1">
              <MDBBtn
                disabled={!!startDate || !!endDate ? false : true}
                color="link"
                className="refresh-button"
                onClick={() => setDateRange([null, null])}
              >
                Reset
              </MDBBtn>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <MDBBtn
            color="link"
            className="refresh-button"
            onClick={handleRefresh}
          >
            <MDBIcon icon="redo-alt" className="text-muted fs-5" />
          </MDBBtn>
          {logs?.length > 0 && (
            <PageLimitSelect
              limit={pagination.limit}
              handleLimitChange={handleLimitChange}
            ></PageLimitSelect>
          )}
        </div>
      </div>

      {/* Applied Filters */}
      {isFilterApplied && (
        <div className="mb-3 d-flex align-items-center">
          <p className="mb-0 text-muted">Filters: </p>
          {Object.keys(filters).map((filterKey: string) => {
            const filterValue =
              filters[filterKey as keyof ActivityLogFilterType];
            // Text to show in chip
            let text = '';

            if (filterKey === 'applicationId') {
              text =
                `Application: ${
                  applicationOptions?.find(
                    (item: MDBSelectEvent) => item.value === filterValue
                  )?.text
                }` || '';
            } else {
              text = `${convertCamelCaseToTitleCase(
                filterKey
              )}: ${filterValue}`;
            }

            if (filterValue) {
              return (
                <div className="ml-2">
                  <Chip
                    text={text}
                    onCloseHandler={() =>
                      removeFilter(filterKey as keyof ActivityLogFilterType)
                    }
                  />
                </div>
              );
            }
            return null;
          })}
        </div>
      )}

      {/* Result Count */}
      {logs.length > 0 && (
        <small className="text-muted d-inline-block mb-3">
          Showing {firstCount} - {lastCount} of {pagination.total}
        </small>
      )}

      {/* Table */}
      {!isLoading && (
        <MDBTable>
          <MDBTableHead>
            <tr className="table-header">
              <th
                scope="col"
                className="font-weight-bold"
                style={timeStampColStyles}
              >
                Timestamp
              </th>
              <th
                scope="col"
                className="font-weight-bold"
                style={userColStyles}
              >
                User
              </th>
              <th
                scope="col"
                className="font-weight-bold"
                style={actionColStyles}
              >
                Action
              </th>
              <th scope="col" className="font-weight-bold">
                Application
              </th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {!isLoading && logs?.length === 0 && (
              <p className="text-muted mt-3">No activity logs found.</p>
            )}
            {logs?.map((log: ActivityLogType) => (
              <ActivityLogListItem key={log._id} log={log} />
            ))}
          </MDBTableBody>
        </MDBTable>
      )}

      {/* Loader */}
      {isLoading && <TableSkeletonLoader rows={3} cols={4} />}

      {/* Pagination */}
      {!isLoading && logs.length > 1 && (
        <div className="d-flex justify-content-center mt-4">
          <Pagination
            pageLimit={pagination.limit}
            totalCount={pagination.total}
            currentPage={pagination.currentPage}
            pageNeighbours={pagination.pageNeighbours}
            onPageChangedHandler={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

export default ActivityLog;
