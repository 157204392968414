import config from 'config';
import { UserStatus } from 'enums/user';
import http from 'utils/http';

export async function fetchUsers(queryParams: any) {
  const users = await http({
    url: config.endpoints.users.getAll,
    method: 'GET',
    params: queryParams,
  });
  return users.data;
}

export async function fetchUser(id: string) {
  const user = await http({
    url: config.endpoints.users.getOne.replace(':id', id),
    method: 'GET',
  });
  return user.data;
}

export async function createUser(data: any) {
  const user = await http({
    url: config.endpoints.users.create,
    method: 'POST',
    data: data,
  });
  return user.data;
}

export async function updateUser(userId: string, data: any) {
  const user = await http({
    url: config.endpoints.users.update.replace(':id', userId),
    method: 'PUT',
    data: data,
  });
  return user.data;
}

export async function changeUserStatus(
  userId: string,
  queryParams: { status: UserStatus }
) {
  const response = await http({
    url: config.endpoints.users.changeStatus.replace(':id', userId.toString()),
    method: 'PATCH',
    params: queryParams,
  });
  return response.data;
}

export async function inviteUser(userId: string) {
  const response = await http({
    url: config.endpoints.users.invite.replace(':id', userId.toString()),
    method: 'GET',
  });
  return response.data;
}

export async function deleteUser(userId: string) {
  const response = await http({
    url: config.endpoints.users.delete.replace(':id', userId.toString()),
    method: 'DELETE',
  });
  return response.data;
}
