import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { store } from 'store';
import { AppContext } from 'types/appContext';

type Props = {};

const RouteRedirect = (props: Props) => {
  const appContext = useContext(store);
  const { user } = appContext as AppContext;

  if (!user) return <p>...</p>;
  const { role } = user;

  // Hardcoded
  if (role === 'Patient') return <Redirect to="/orders" />;
  return <Redirect to="/patients" />;
};

export default RouteRedirect;
