import { MDBIcon } from 'mdb-react-ui-kit';
import React from 'react';
import './chip.scss';

interface Props {
  text: string;
  onCloseHandler: () => void;
}

const Chip = ({ text, onCloseHandler }: Props) => {
  return (
    <div className="chip hover-shadow">
      <span>{text}</span>
      <MDBIcon className="ml-2" icon="times-circle" onClick={onCloseHandler} />
    </div>
  );
};

export default Chip;
