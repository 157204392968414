/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'stores';
import { getFullName } from 'utils/npssUtil';

interface PatientDetailState {
  patientDetail: { fullName: string; patientId: string };
}

const initialState: PatientDetailState = {
  patientDetail: { fullName: '', patientId: '' },
};

export const slice = createSlice({
  name: 'patientDetail',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    setPatientDetail: (state, action: any) => {
      state.patientDetail.fullName = getFullName(
        action?.payload?.firstName,
        action?.payload?.lastName,
        action?.payload?.middleName
      );
    },
    setPatientId: (state, action: any) => {
      state.patientDetail.patientId = action.payload;
    },
  },
});

// Actions
export const { setPatientDetail, setPatientId } = slice.actions;

// Selectors
export const getPatientDetail = (state: RootState) =>
  state.patient.patientDetail;
export const getPatientId = (state: RootState) =>
  state.patient.patientDetail.patientId;

// Reducer
export default slice.reducer;
