import Header from 'components/common/header';
import Sidebar from 'components/common/sidebar';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

const MainLayout = ({ children }: Props) => {
  return <Sidebar Header={<Header />} Body={children} />;
};

export default MainLayout;
