import { REGEX, VALIDATION_MESSAGE } from 'constants/index';
import { MDBAlert, MDBBtn, MDBInput, MDBSpinner } from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { resetPassword } from 'services/auth';
import { PasswordResetType } from 'types/auth';
import { clear } from 'utils/storage';

const { PASSWORD } = REGEX;
const { REQUIRED } = VALIDATION_MESSAGE;

interface Props {}

type ErrorObj = {
  response: {
    data: {
      message: string;
    };
  };
};

/**
 * The same component is used for creating a new password (on activating the account)
 * and reseting a password (on forgot password)
 *
 */
const ResetPassword = (props: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useForm<PasswordResetType>({
    defaultValues: {
      newPassword: '',
      confirmNewPassword: '',
      resetToken: '',
    },
  });
  const location = useLocation();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [error, setError] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Watch values for inputs (new password and confirm password)
  // These are used to clear the error message when one of the input fields are cleared.
  const newPasswordWatch = watch('newPassword');
  const confirmNewPasswordWatch = watch('confirmNewPassword');

  /**
   * Get token from the query params.
   * If there is no token, redirect to '/'.
   *
   */
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    setEmail(queryParams.get('email') || '');

    // Replacing the ' ' with '+' is necessary because the token contains '+' which is encoded to ' ' in query string
    // '+' sign in the query string is URL-decoded to a space. '%2B' in the query string is URL-decoded to a '+' sign.
    // So, in order to preserve the '+' in the token, we are converting ' ' into '+' and encoding it
    // Visit: https://stackoverflow.com/questions/6855624/plus-sign-in-query-string/6855723 for more details
    const actualToken = token?.includes(' ')
      ? token.toString().replaceAll(' ', '+')
      : token;

    if (!actualToken) {
      history.push('/');
      return;
    }

    setValue('resetToken', actualToken);
  }, [setValue, history, location.search, location.pathname]);

  /**
   * Whenever any one of the inputs are cleared, clear the error message.
   */
  useEffect(() => {
    if (newPasswordWatch === '' || confirmNewPasswordWatch === '') {
      setError('');
    }
  }, [newPasswordWatch, confirmNewPasswordWatch]);

  /**
   * Check if the entered password and confirm password are the same
   */
  const checkInputValidation = (): boolean => {
    return getValues('newPassword') === getValues('confirmNewPassword');
  };

  const onSubmit = async (data: PasswordResetType) => {
    const isInputValidated = checkInputValidation();
    if (!isInputValidated) {
      setError('Password and Confirm Password do not match');
      return;
    }

    try {
      setIsSubmitting(true);
      await resetPassword({ ...data });
      toast.success(
        `Password created successfully. You are now able to login with the new password.`
      );
      clear('local');
      history.push('/login');
    } catch (err) {
      setError((err as ErrorObj).response?.data?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const emailToShow = email.toString().replaceAll(' ', '+');

  return (
    <div>
      <h3>Create a new password</h3>

      {emailToShow && (
        <p className="text-muted text-sm mb-4pt5">
          Enter a new password for <strong>{emailToShow}</strong>
        </p>
      )}

      <MDBAlert
        show={!!error}
        className="w-100 p-2 rounded-1 mb-4"
        color="danger"
      >
        {error}
      </MDBAlert>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="newPassword"
          control={control}
          rules={{
            required: REQUIRED,
            pattern: PASSWORD,
          }}
          render={({ field }) => (
            <MDBInput
              {...field}
              label="Password"
              id="password"
              type="password"
              size="lg"
              wrapperClass={
                errors.newPassword?.message === REQUIRED ? 'mb-4' : 'mb-1'
              }
              className={`bg-white ${errors.newPassword ? 'is-invalid' : ''}`}
              validation={errors.newPassword ? errors.newPassword?.message : ''}
              invalid={!!errors.newPassword}
              aria-describedby="new-password"
            />
          )}
        />
        {errors.newPassword?.message !== REQUIRED && (
          <div id="new-password" className="text-xs mb-3">
            Password must have at least 1 upper case, 1 lower case, 1 digit & 1
            special character.
          </div>
        )}

        <Controller
          name="confirmNewPassword"
          control={control}
          rules={{
            required: REQUIRED,
          }}
          render={({ field }) => (
            <MDBInput
              {...field}
              label="Confirm Password"
              id="password"
              type="password"
              size="lg"
              wrapperClass="mb-4"
              className={`bg-white ${
                errors.confirmNewPassword ? 'is-invalid' : ''
              }`}
              validation={
                errors.confirmNewPassword
                  ? errors.confirmNewPassword?.message
                  : ''
              }
              invalid={!!errors.confirmNewPassword}
            />
          )}
        />

        <MDBBtn
          color="secondary"
          size="lg"
          className="shadow-none w-100 text-dark"
          disabled={isSubmitting}
        >
          {isSubmitting && (
            <MDBSpinner size="sm" role="status" tag="span" className="me-2" />
          )}
          Save Password
        </MDBBtn>
      </form>

      <div className="d-flex flex-column align-items-center text-muted mt-4">
        <small className="mb-1">
          Copyright &copy; {new Date().getFullYear()}. MAT Rx.
        </small>
        <small>
          <a href="/terms-of-use" target="_blank" className="muted-link">
            Terms of Use
          </a>{' '}
          |{' '}
          <a href="/privacy-policy" target="_blank" className="muted-link">
            Privacy Policy
          </a>
        </small>
      </div>
    </div>
  );
};

export default ResetPassword;
