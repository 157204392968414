import config from 'config';
import http from 'utils/http';

export const getDocument = async (isUploaded: any, userId: any) => {
  const response = await http({
    method: 'GET',
    url: config.endpoints.patients.document.getAll.replace(
      ':patientUserId',
      userId
    ),
    params: {
      isUploaded,
    },
  });
  return response?.data;
};

export const uploadDocumentToPatient = async (data: any, userId: any) => {
  const response = await http({
    method: 'POST',
    url: config.endpoints.patients.document.uploadDocumentToPatient.replace(
      ':patientUserId',
      userId
    ),
    data,
  });
  return response?.data;
};

export const shareDocumentToPatient = async (data: any, userId: string) => {
  const response = await http({
    method: 'PATCH',
    url: config.endpoints.patients.document.shareDocumentToPatient.replace(
      ':patientUserId',
      userId
    ),
    data,
  });
  return response?.data;
};

export const deleteUploadedDocument = async (
  documentId: string,
  userId: any
) => {
  const response = await http({
    method: 'DELETE',
    url: config.endpoints.patients.document.deleteDocument
      .replace(':patientUserId', userId)
      .replace(':id', documentId),
  });
  return response?.data;
};

export const fetchDocumentBufferData = async (
  documentId: string,
  userId: string
) => {
  const response = await http({
    method: 'GET',
    url: config.endpoints.patients.document.downloadDocument
      .replace(':patientUserId', userId)
      .replace(':id', documentId),
  });
  return response?.data;
};
