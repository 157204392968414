import config from 'config';
import http from 'utils/http';

export async function fetchActivityLogs(queryParams: any) {
  const activityLogs = await http({
    url: config.endpoints.activityLogs.getAll,
    method: 'GET',
    params: queryParams,
  });
  return activityLogs.data;
}

export async function saveActivityLogs(data: any, headers: { source: string }) {
  const activityLogs = await http({
    url: config.endpoints.activityLogs.save,
    method: 'POST',
    data,
    headers: {
      source: headers.source,
    },
  });
  return activityLogs.data;
}
