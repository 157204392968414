import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from 'mdb-react-ui-kit';

interface Props {
  totalCount: number;
  pageLimit: number;
  pageNeighbours: number;
  currentPage: number;
  onPageChangedHandler: (newCurrentPage: number) => void;
}

const Pagination = (props: Props) => {
  const { totalCount, pageLimit, pageNeighbours, currentPage } = props;
  const totalPages = Math.ceil(totalCount / pageLimit);
  const LEFT_PAGE = 'LEFT';
  const RIGHT_PAGE = 'RIGHT';
  const FIRST_PAGE = 'First';
  const LAST_PAGE = 'Last';
  const PREVIOUS_PAGE = 'Previous';
  const NEXT_PAGE = 'Next';
  /**
   * Helper method for creating a range of numbers
   * range(1, 5) => [1, 2, 3, 4, 5]
   */
  const range = (from: number, to: number, step = 1) => {
    let i = from;
    const range = [];
    while (i <= to) {
      range.push(i);
      i += step;
    }
    return range;
  };
  const fetchPageNumbers = () => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;
    if (totalPages > totalBlocks) {
      const startPage = Math.max(1, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages, currentPage + pageNeighbours);
      let pages: any = range(startPage, endPage);
      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);
      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }
        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }
        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }
      return [
        // FIRST_PAGE,
        PREVIOUS_PAGE,
        ...pages,
        NEXT_PAGE,
        // LAST_PAGE
      ];
    } else {
      let pages = range(1, totalPages);
      return [
        // FIRST_PAGE,
        PREVIOUS_PAGE,
        ...pages,
        NEXT_PAGE,
        // LAST_PAGE
      ];
    }
  };
  const pages = fetchPageNumbers();
  const paginationBody = pages.map((page, index) => {
    if (page === LEFT_PAGE) {
      return (
        <MDBPaginationItem key={index} className="cursor-pointer">
          <MDBPaginationLink
            className="page-link"
            aria-label="Left"
            onClick={() => handleMoveLeft()}
          >
            <span aria-hidden="true">&laquo;</span>
          </MDBPaginationLink>
        </MDBPaginationItem>
      );
    } else if (page === RIGHT_PAGE) {
      return (
        <MDBPaginationItem key={index} className="cursor-pointer">
          <MDBPaginationLink
            className="page-link"
            aria-label="Right"
            onClick={() => handleMoveRight()}
          >
            <span aria-hidden="true">&raquo;</span>
          </MDBPaginationLink>
        </MDBPaginationItem>
      );
    } else if (page === FIRST_PAGE) {
      return (
        <MDBPaginationItem
          key={index}
          className={`cursor-pointer ${currentPage === 1 ? 'disabled' : ''}`}
        >
          <MDBPaginationLink
            className="page-link"
            aria-label="First"
            onClick={() => handleClickFirstPage()}
          >
            {page}
          </MDBPaginationLink>
        </MDBPaginationItem>
      );
    } else if (page === LAST_PAGE) {
      return (
        <MDBPaginationItem
          key={index}
          className={`cursor-pointer ${
            currentPage === totalPages ? 'disabled' : ''
          }`}
        >
          <MDBPaginationLink
            className="page-link"
            aria-label="Last"
            onClick={() => handleClickLastPage()}
          >
            {page}
          </MDBPaginationLink>
        </MDBPaginationItem>
      );
    } else if (page === PREVIOUS_PAGE) {
      return (
        <MDBPaginationItem
          key={index}
          className={`cursor-pointer ${currentPage === 1 ? 'disabled' : ''}`}
        >
          <MDBPaginationLink
            className="page-link"
            aria-label="Last"
            onClick={() => handleClickPreviousPage()}
          >
            {page}
          </MDBPaginationLink>
        </MDBPaginationItem>
      );
    } else if (page === NEXT_PAGE) {
      return (
        <MDBPaginationItem
          key={index}
          className={`cursor-pointer ${
            currentPage === totalPages ? 'disabled' : ''
          }`}
        >
          <MDBPaginationLink
            className="page-link"
            aria-label="Last"
            onClick={() => handleClickNextPage()}
          >
            {page}
          </MDBPaginationLink>
        </MDBPaginationItem>
      );
    } else {
      return (
        <MDBPaginationItem
          key={index}
          className={`cursor-pointer ${currentPage === page ? 'active' : ''}`}
        >
          <MDBPaginationLink
            className="page-link"
            onClick={() => handleClick(page)}
          >
            {page}
          </MDBPaginationLink>
        </MDBPaginationItem>
      );
    }
  });
  const handleClick = (page: number) => {
    gotoPage(page);
  };
  const handleMoveLeft = () => {
    gotoPage(currentPage - pageNeighbours * 2 - 1);
  };
  const handleMoveRight = () => {
    gotoPage(currentPage + pageNeighbours * 2 + 1);
  };
  const handleClickFirstPage = () => {
    gotoPage(1);
  };
  const handleClickLastPage = () => {
    gotoPage(totalPages);
  };
  const handleClickPreviousPage = () => {
    gotoPage(currentPage - 1);
  };
  const handleClickNextPage = () => {
    gotoPage(currentPage + 1);
  };
  const gotoPage = (page: number) => {
    const newCurrentPage = Math.max(0, Math.min(page, totalPages));
    props.onPageChangedHandler(newCurrentPage);
  };
  return (
    <MDBPagination color="amber" className="mb-0 ">
      {!totalCount || totalPages === 1 ? null : paginationBody}
    </MDBPagination>
  );
};

export default Pagination;
